import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { motion } from "framer-motion";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useUserDataContext } from "../../../../../user_data_context";
import {
  replacePlaceholderWithStudentName,
  entityStyleFn,
  getInlineStyles,
  replaceSpacesWithNbsp,
} from "../../../text_editor/text_editor_functions";
import { LESSON_COLORS_THEME } from "../../../../utils/enums";
import UserImage from "../../../user_image";
import { TextEditorViewComponent } from "../../../text_editor/editor_styled_components";
import { DIALOGUE_ANSWERS_TYPE_DATA } from "../../../../../pages/admin/admin_page_create_lesson/create_lesson_settings_block/settings_block_renderers/dialogue_settings";

const DialogueComponentBranchContainer = styled.div``;

const DialogueBotMessageWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
`;

const DialogueBotMessageContainer = styled(motion.div)`
  transform-origin: bottom left;
  width: 86%;
`;

const DialogueBotMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding: 12px 14px 12px 16px;
  min-height: 65px;
  background: ${({ botMessageBackground }) => botMessageBackground};
  border-radius: 15px;
  color: ${({ color }) => color};
  font-size: 16px;
  line-height: 24px;
  word-break: normal;
  overflow-wrap: break-word;
`;

const DialogueBotMessageName = styled.div`
  color: #828282;
  font-size: 10px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.1px;
  height: 12px;
`;

const DialogueUserAnswersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5px;
  margin-top: 20px;
`;

const DialogueUserAnswers = styled(motion.div)`
  width: 84%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 12px;
  transform-origin: bottom right;
`;

const commonAnimationProps = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

const getAnimationProps = (withAnimation) => {
  if (withAnimation) {
    return commonAnimationProps;
  } else {
    return {
      initial: {},
      animate: {},
    };
  }
};

const DialogueComponentBranch = ({
  branch,
  componentData,
  dialogueBotData,
  inComponent,
  branchIndex,
  withAnimation,
  colorTheme,
  courseMainGradient,
  courseMainColor,
  setLessonData,
  setUserAnswersData,
  setTextEditorComponentHtml,
}) => {
  const branchRef = useRef(null);
  const { userData } = useUserDataContext();

  const [html, setHtml] = useState("");
  const [showUserAnswers, setShowUserAnswers] = useState(
    withAnimation ? false : true
  );

  const sanitizedHtml = DOMPurify.sanitize(html);

  const branchData = componentData.data?.[branch];
  const currentUserName = userData.user_name || "Пользователь";
  const animationProps = getAnimationProps(withAnimation);

  const botMessageBackground =
    LESSON_COLORS_THEME[colorTheme]?.GRADIENTS?.["WHITE"];

  useEffect(() => {
    if (withAnimation) {
      const timer = setTimeout(() => {
        setShowUserAnswers(true);
      }, 800);

      return () => clearTimeout(timer);
    } else {
      setShowUserAnswers(true);
    }
  }, [withAnimation]);

  useEffect(() => {
    if (branchRef.current && branchIndex > 0 && withAnimation) {
      branchRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [branchRef.current, branchIndex, withAnimation]);

  useEffect(() => {
    if (branchData?.text?.blocks?.length) {
      try {
        let contentState = convertFromRaw(branchData.text);

        let htmlContent = stateToHTML(contentState, {
          entityStyleFn,
          inlineStyles: getInlineStyles(colorTheme),
        });

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );

        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [branchData?.text?.blocks, currentUserName, colorTheme]);

  useEffect(() => {
    setTextEditorComponentHtml({});
  }, [html]);

  return (
    <DialogueComponentBranchContainer ref={branchRef}>
      <DialogueBotMessageWrapper>
        <UserImage userData={dialogueBotData} />

        <DialogueBotMessageContainer {...animationProps}>
          <DialogueBotMessage
            botMessageBackground={botMessageBackground}
            color={colorTheme === "LIGHT" ? "#1e1e1e" : "#fff"}
          >
            <DialogueBotMessageName>
              {dialogueBotData?.user_name ? (
                <>
                  {dialogueBotData?.user_name}{" "}
                  {dialogueBotData?.user_second_name}
                </>
              ) : (
                ""
              )}
            </DialogueBotMessageName>
            <TextEditorViewComponent
              dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
              courseMainColor={courseMainColor}
              courseMainGradient={courseMainGradient}
            />
          </DialogueBotMessage>
        </DialogueBotMessageContainer>
      </DialogueBotMessageWrapper>

      {!!branchData?.answers?.answers_data?.length && showUserAnswers && (
        <DialogueUserAnswersContainer>
          <DialogueUserAnswers {...animationProps}>
            {branchData?.answers.answers_data.map((answer) => {
              const AnswerComponent =
                DIALOGUE_ANSWERS_TYPE_DATA[answer.type].answerPreviewComponent;

              return (
                <AnswerComponent
                  key={answer.branch}
                  answer={answer}
                  componentData={componentData}
                  inComponent={inComponent}
                  branchIndex={branchIndex}
                  withAnimation={withAnimation}
                  colorTheme={colorTheme}
                  setLessonData={setLessonData}
                  setUserAnswersData={setUserAnswersData}
                />
              );
            })}
          </DialogueUserAnswers>

          <UserImage userData={userData} />
        </DialogueUserAnswersContainer>
      )}
    </DialogueComponentBranchContainer>
  );
};

export default memo(DialogueComponentBranch);
