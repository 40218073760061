import React from "react";

const GoogleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M24.0001 12.2673C24.0001 11.2806 23.9184 10.5606 23.7416 9.81396H12.245V14.2673H18.9932C18.8572 15.374 18.1225 17.0408 16.4899 18.1607L16.467 18.3098L20.102 21.0695L20.3538 21.0942C22.6667 19.0008 24.0001 15.9207 24.0001 12.2673Z"
      fill="#4285F4"
    />
    <path
      d="M12.2448 23.9999C15.5509 23.9999 18.3264 22.9331 20.3537 21.0932L16.4897 18.1597C15.4557 18.8664 14.0679 19.3597 12.2448 19.3597C9.00676 19.3597 6.25848 17.2664 5.2788 14.373L5.1352 14.385L1.35546 17.2517L1.30603 17.3864C3.31961 21.3064 7.45568 23.9999 12.2448 23.9999Z"
      fill="#34A853"
    />
    <path
      d="M5.27886 14.3737C5.02036 13.6271 4.87076 12.827 4.87076 12.0004C4.87076 11.1736 5.02036 10.3736 5.26526 9.62697L5.25841 9.46795L1.43131 6.55518L1.30609 6.61355C0.476196 8.24026 0 10.067 0 12.0004C0 13.9337 0.476196 15.7604 1.30609 17.3871L5.27886 14.3737Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2448 4.64005C14.5441 4.64005 16.0951 5.6134 16.9795 6.4268L20.4353 3.12006C18.3129 1.18669 15.5509 0 12.2448 0C7.45568 0 3.31961 2.69336 1.30603 6.6134L5.2652 9.62683C6.25848 6.73345 9.00676 4.64005 12.2448 4.64005Z"
      fill="#EB4335"
    />
  </svg>
);

export default GoogleIcon;
