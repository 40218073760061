import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { useUserDataContext } from "../../../../user_data_context";
import { BASE_API_URL } from "../../../../common/endpoints";
import { formatDate } from "../../../../common/utils/functions/time_functions";

const CourseInfoModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const CourseInfoModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
  width: 390px;
  height: 330px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;

  & > h4 {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.24px;
    user-select: none;
  }
`;

const ModalWindowItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 21px;
  width: 100%;
`;

const ModalWindowItemContainer = styled.div`
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;

  color: #828282;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const DeleteCourseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;

  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.24px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.08);
    transition: 0.2s;
  }
`;

const CourseInfoModalWindow = ({
  courseInfoModalData,
  setCourseInfoModalData,
  setShowDeleteModal,
}) => {
  const { userData } = useUserDataContext();
  const [createdBy, setCreatedBy] = useState({});
  const [modifiedBy, setModifiedBy] = useState({});

  const showDeleteButton =
    userData?.user_role === "superadmin" ||
    userData?._id === courseInfoModalData.course_creator?._id;

  const createdAt = formatDate(courseInfoModalData.course_created_at);
  const modifiedAt =
    courseInfoModalData.course_modified_at &&
    formatDate(courseInfoModalData.course_modified_at, true);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setCourseInfoModalData({});
  }, []);

  const fetchCourseCreatedBy = async () => {
    axios
      .get(BASE_API_URL + "/users/" + courseInfoModalData.course_creator?._id)
      .then((response) => {
        setCreatedBy(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  };

  useEffect(() => {
    if (courseInfoModalData.course_creator?._id) {
      fetchCourseCreatedBy();
    }
  }, [courseInfoModalData.course_creator?._id]);

  const fetchCourseModifiedBy = async () => {
    axios
      .get(
        BASE_API_URL + "/users/" + courseInfoModalData.course_modified_by?._id
      )
      .then((response) => {
        setModifiedBy(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  };

  useEffect(() => {
    if (courseInfoModalData.course_modified_by?._id) {
      fetchCourseModifiedBy();
    }
  }, [courseInfoModalData.course_modified_by?._id]);

  const onDeleteButton = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  return (
    <CourseInfoModalWindowWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <CourseInfoModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <h4>Инфо</h4>
        <ModalWindowItems>
          <ModalWindowItemContainer>
            <p>Дата создания: {createdAt}</p>
            <p>
              ({createdBy.user_name} {createdBy.user_second_name})
            </p>
          </ModalWindowItemContainer>
          {courseInfoModalData.course_modified_at && modifiedBy?._id && (
            <ModalWindowItemContainer>
              <p>Дата изменения: {modifiedAt}</p>
              <p>
                ({modifiedBy.user_name} {createdBy.user_second_name})
              </p>
            </ModalWindowItemContainer>
          )}
        </ModalWindowItems>
        {showDeleteButton && (
          <DeleteCourseButton onClick={onDeleteButton}>
            Удалить курс
          </DeleteCourseButton>
        )}
      </CourseInfoModalWindowContainer>
    </CourseInfoModalWindowWrapper>
  );
};

export default memo(CourseInfoModalWindow);
