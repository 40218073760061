import React, { useCallback } from "react";
import styled from "styled-components";
import { AskQuestionButton } from "./question_modal_window";

const FinalModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 10001;
`;

const FinalModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 21px;
  width: 420px;
  min-height: 170px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
  cursor: default;
  z-index: 10001;

  @media (max-width: 440px) {
    width: 94%;
    margin: 0 auto;
  }
`;

const FinalModalWindowTitle = styled.div`
  margin: 0 auto;
  text-align: center;
  color: #1e1e1e;

  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
`;

const FinalModalWindow = ({ isOpen, onClose }) => {
  const handleCloseFinalModalWindow = useCallback((event) => {
    event.stopPropagation();
    onClose(event);
  }, []);

  return (
    <FinalModalWindowWrapper
      isOpen={isOpen}
      onClick={(event) => handleCloseFinalModalWindow(event)}
    >
      <FinalModalWindowContainer>
        <FinalModalWindowTitle>
          <h4>Поздравляем!</h4>
          <h5>Ваш вопрос успешно отправлен!</h5>
          <p>
            OnBible уведомит вас когда будет готов ответ. Следите за сообщениями
            на платформе!
          </p>
        </FinalModalWindowTitle>
        <AskQuestionButton onClick={handleCloseFinalModalWindow}>
          Спасибо
        </AskQuestionButton>
      </FinalModalWindowContainer>
    </FinalModalWindowWrapper>
  );
};

export default FinalModalWindow;
