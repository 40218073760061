import React from "react";

const DailyVerseMobileArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="127"
      height="52"
      viewBox="0 0 127 52"
      fill="none"
    >
      <path
        d="M1.2874 31.7659C0.576819 32.2546 0.428491 33.1306 0.956093 33.7227C1.4837 34.3147 2.48744 34.3985 3.19801 33.9098L1.2874 31.7659ZM109.386 51.0163C109.672 51.7289 110.583 52.0752 111.421 51.7898L125.066 47.138C125.904 46.8525 126.35 46.0434 126.064 45.3308C125.777 44.6182 124.866 44.2719 124.029 44.5574L111.899 48.6923L107.749 38.37C107.463 37.6573 106.552 37.3111 105.714 37.5965C104.877 37.882 104.43 38.6911 104.717 39.4037L109.386 51.0163ZM3.19801 33.9098C15.819 25.2303 31.2522 17.6545 46.705 12.2621C62.1729 6.86455 77.5496 3.69331 90.0671 3.73501C102.629 3.77685 111.885 7.03153 115.903 14.0446C119.964 21.1322 119.028 32.6228 109.463 49.9526L112.341 51.0464C122.026 33.5001 123.514 20.9561 118.788 12.7068C114.019 4.38302 103.277 0.998508 90.2439 0.955093C77.1659 0.91153 61.35 4.20897 45.6423 9.69023C29.9195 15.1768 14.1939 22.89 1.2874 31.7659L3.19801 33.9098Z"
        fill="url(#paint0_linear_7917_7095)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7917_7095"
          x1="100.801"
          y1="-15.7413"
          x2="50.0934"
          y2="69.3512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DailyVerseMobileArrow;
