import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import ShareIcon from "../../../common/assets/icons/share_icon";
import LeftArrowIcon from "../../../common/assets/icons/left_arrow_icon";
import LeftArrowMobileIcon from "../../../common/assets/icons/left_arrow_mobile_icon";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import CourseInfoAuthor from "../study_page_courses/course_card/course_author";
import { useUserDataContext } from "../../../user_data_context";
import { Slide, toast } from "react-toastify";

const CoursePageHeaderContainer = styled.div`
  max-width: 1100px;
  height: 240px;
  margin: 0 auto;
  border-radius: 0px 0px 25px 25px;
  background-image: url(${({ headerBackground }) => headerBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 542px) {
    width: 100%;
    border-radius: 0px;
  }

  @media (max-width: 412px) {
    max-width: 412px;
    width: 100%;
    height: 287px;
  }
`;

const CoursePageHeaderShadowWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 25px;
  border-radius: 0px 0px 25px 25px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.67) 0%,
    rgba(0, 0, 0, 0.16) 75.44%,
    rgba(0, 0, 0, 0) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 590px) {
    padding-top: 50px;
  }

  @media (max-width: 542px) {
    border-radius: 0px;
  }

  @media (max-width: 412px) {
    padding: 100px 0 0 30px;
  }
`;

const CoursePageHeaderContent = styled.div`
  position: relative;
  width: 510px;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 514px) {
    width: 100%;
  }
`;

const CourseAuthorsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const ShareCourseButton = styled.div`
  position: absolute;
  top: 7px;
  right: 0px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 590px) {
    top: -34px;
    right: 18px;
  }

  @media (max-width: 530px) {
    right: 28px;
  }

  @media (max-width: 412px) {
    top: -37px;
  }

  svg {
    width: 24px;
    height: 22px;
  }
`;

const ArrowsContainer = styled.div`
  position: absolute;
  top: 16px;
  left: -60px;
  cursor: pointer;
  padding: 6px 5px;
  transition: 0.2s;

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: 665px) {
    left: -45px;
  }

  @media (max-width: 620px) {
    left: -25px;
  }

  @media (max-width: 590px) {
    top: -35px;
    left: 13px;
    padding: 3px;
  }

  @media (max-width: 514px) {
    left: 17px;
  }

  @media (max-width: 412px) {
    top: -39px;
    left: -3px;
  }
`;

const LeftArrowStyles = styled(LeftArrowIcon)`
  @media (max-width: 590px) {
    display: none;
  }
`;

const LeftArrowMobileStyles = styled(LeftArrowMobileIcon)`
  display: none;

  @media (max-width: 590px) {
    display: block;
  }
`;

const CoursePageHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 92%;
  height: 100%;
  padding-left: 20px;
  color: #ffffff;

  @media (max-width: 570px) {
    width: 97%;
  }

  @media (max-width: 412px) {
    padding-left: 0;
  }
`;

const CourseTopic = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;

  @media (max-width: 590px) {
    font-size: 22px;
    line-height: 120%;
  }

  @media (max-width: 412px) {
    font-size: 20px;
  }
`;

const CourseDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;

  @media (max-width: 590px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.1px;
  }

  @media (max-width: 412px) {
    font-size: 12px;
  }
`;

const StartButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: max-content;
  padding: 0 22px;
  background: #f8d254;
  border-radius: 8px;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: "Raleway";

  @media (max-width: 590px) {
    min-width: 120px;
    height: 33px;
    line-height: 140%;
  }
`;

const CoursePageHeader = ({
  courseData,
  inComponent,
  userCourse,
  authError,
  handleClickOnLesson,
}) => {
  const navigate = useNavigate();
  const { userData } = useUserDataContext();

  const onArrowUrl =
    inComponent === "admin_page"
      ? APPLICATION_ROUTES.ADMIN_COURSES + "?courseId=" + courseData._id
      : APPLICATION_ROUTES.STUDY_COURSES;

  const onClickStartButton = useCallback(
    (event) => {
      if (inComponent === "admin_page") return;

      if (authError) {
        navigate(APPLICATION_ROUTES.LOGIN);
        return;
      }

      if (!userCourse?.course_status) {
        const firstLessonId = courseData.course_lessons?.[0]?._id;

        handleClickOnLesson(event, firstLessonId, "next", 1);
        return;
      }

      if (userCourse?.course_status === "in_process") {
        const lessonInProcessIndex = userCourse.course_lessons?.findIndex(
          (lesson) => lesson.lesson_status === "in_process"
        );

        if (lessonInProcessIndex >= 0) {
          const lessonId =
            userCourse.course_lessons?.[lessonInProcessIndex]?._id;
          const lessonNum = lessonInProcessIndex + 1;

          navigate(
            `${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`
          );
        } else {
          if (
            userCourse.course_lessons.length !==
            courseData.course_lessons.length
          ) {
            const lessonId =
              courseData.course_lessons?.[userCourse.course_lessons.length]
                ?._id;

            const lessonNum =
              courseData.course_lessons?.findIndex(
                (lesson) => lesson._id === lessonId
              ) + 1;

            handleClickOnLesson(event, lessonId, "next", lessonNum);
          } else {
            const lessonId =
              userCourse?.course_lessons?.[courseData.course_lessons.length - 1]
                ?._id;

            const lessonNum =
              courseData.course_lessons?.findIndex(
                (lesson) => lesson._id === lessonId
              ) + 1;

            navigate(
              `${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`
            );
          }
        }
      }
    },
    [
      inComponent,
      authError,
      userCourse,
      courseData,
      userData,
      handleClickOnLesson,
    ]
  );

  const showNotification = () => {
    toast("Ссылка на курс скопирована!", {
      position: "top-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = async (text) => {
    if (!navigator.clipboard) {
      alert("Копирование не поддерживается вашим браузером.");
      return;
    }

    await navigator.clipboard.writeText(text).then(
      () => showNotification(),
      (error) => {
        console.error("Ошибка копирования: ", error);
      }
    );
  };

  const handleCopyCourseLink = useCallback(
    (event) => {
      event.stopPropagation();
      const courseLink = `${process.env.REACT_APP_BASE_URL}${APPLICATION_ROUTES.STUDY_COURSES}/${courseData._id}`;
      copyToClipboard(courseLink);
    },
    [courseData]
  );

  return (
    <CoursePageHeaderContainer
      headerBackground={courseData.course_header_image}
    >
      <CoursePageHeaderShadowWrapper>
        <CoursePageHeaderContent>
          <Link to={onArrowUrl}>
            <ArrowsContainer>
              <LeftArrowStyles />
              <LeftArrowMobileStyles />
            </ArrowsContainer>
          </Link>

          <ShareCourseButton onClick={handleCopyCourseLink}>
            <ShareIcon />
          </ShareCourseButton>

          <CoursePageHeaderInfo>
            <CourseTopic>{courseData.course_name}</CourseTopic>
            <CourseDescription>
              {courseData.course_description}
            </CourseDescription>

            <CourseAuthorsContainer>
              {courseData?.course_authors?.map((author, index) => {
                return (
                  <CourseInfoAuthor
                    key={"course_author-" + index}
                    authorId={author._id}
                  />
                );
              })}
            </CourseAuthorsContainer>

            {userCourse?.course_status !== "finished" && (
              <>
                {(courseData.course_status === "open" ||
                  authError ||
                  inComponent === "admin_page") && (
                  <StartButton onClick={onClickStartButton}>
                    {userCourse?.course_status !== "in_process"
                      ? "Начать"
                      : "Продолжить"}
                  </StartButton>
                )}

                {courseData.course_status === "announcement" && (
                  <StartButton>Уведомить</StartButton>
                )}
              </>
            )}
          </CoursePageHeaderInfo>
        </CoursePageHeaderContent>
      </CoursePageHeaderShadowWrapper>
    </CoursePageHeaderContainer>
  );
};

export default memo(CoursePageHeader);
