import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import LeftArrowIcon from "../../../../assets/icons/left_arrow_icon";
import VerticalLine from "../../../../assets/icons/vertical_line";
import { BASE_API_URL } from "../../../../endpoints";
import { bibleVersesWithoutTags } from "../../../../utils/functions/bible_page_functions";
import SpinnerLoader from "../../../loaders/spinner_loader";
import { compareObjects } from "../../../../utils/functions/compare_objects";

const QuestionComponentBibleContainer = styled.div`
  position: relative;
  border-radius: 15px;
  margin-bottom: ${({ openBible }) => (openBible ? 0 : "10px")};
`;

const QuestionBibleChapterWrapper = styled.div`
  height: 240px;
  border-radius: 15px;
  ${({ color }) => `border: 2px solid ${color};`}
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const QuestionBibleChapterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 12px 0 0 12px;
`;

const QuestionMainBibleAddress = styled.div`
  position: absolute;
  left: 50%;
  top: -28px;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
  height: 38px;
  min-width: 76px;
  width: max-content;
  border-radius: 15px;
  ${({ bibleRefBorderColor }) => `border: 2px solid ${bibleRefBorderColor};`}
  ${({ backgroundIsWhite }) =>
    backgroundIsWhite &&
    `box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;`}
  background:${({ colorTheme }) =>
    colorTheme === "DARK" ? "rgb(68 68 68)" : "#fff"};
  color: ${({ colorTheme }) => (colorTheme === "DARK" ? "#fff" : "#1e1e1e")};
  font-size: 16px;
  letter-spacing: -0.16px;
  user-select: none;
  cursor: pointer;
  z-index: 2;

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.2s;
  }

  @media (max-width: 420px) {
    font-size: 14px;
    height: 34px;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  padding-right: ${({ openBible }) => (openBible ? "1px" : "2px")};
  transform: rotate(${({ openBible }) => (openBible ? "90deg" : "-90deg")});
`;

const ArrowStyled = styled(LeftArrowIcon)`
  width: 17px;
  height: 17px;
`;

const BibleChapterAddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 10px;
  width: 215px;
  height: 24px;
  min-height: 24px;
  background: ${({ background }) => background};
  border-radius: 15px;
`;

const BibleTranslationVersion = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  user-select: none;
`;

const BibleChapterAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 140%;
  color: ${({ color }) => color};

  letter-spacing: -0.1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const QuestionBibleChapter = styled.div`
  height: 194px;
  padding-bottom: 10px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const QuestionBibleChapterVerse = styled.div`
  margin-right: 9px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  padding: 0 12px;
  background: ${({ currentVerse }) => currentVerse && "rgb(248 210 84 / 50%)"};
  border-radius: 5px;
  ${({ colorTheme }) => colorTheme === "DARK" && "color: #fff;"}

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 100%;
`;

const QuestionComponentBible = ({
  previewInfo,
  bibleRefBorderColor,
  colorTheme,
  backgroundIsWhite,
}) => {
  const targetVerseRef = useRef(null);
  const chapterRef = useRef(null);
  const [openBibleChapter, setOpenBibleChapter] = useState(false);
  const [bibleParams, setBibleParams] = useState({});
  const [chapterData, setChapterData] = useState([]);
  const [selectedVerses, setSelectedVerses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const bibleVerse = previewInfo.data?.bible_verse;

  const handleOpenBibleChapter = useCallback((event) => {
    event.stopPropagation();
    setOpenBibleChapter((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (
      targetVerseRef.current &&
      chapterRef.current &&
      openBibleChapter &&
      !isLoading
    ) {
      const parentContainer = chapterRef.current;
      const targetElement = targetVerseRef.current;

      if (parentContainer && targetElement) {
        const parentRect = parentContainer.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        const offsetTop =
          targetRect.top - parentRect.top + parentContainer.scrollTop;

        parentContainer.scrollTo({
          top:
            offsetTop -
            parentContainer.clientHeight / 2 +
            targetElement.clientHeight / 2,
        });
      }
    }
  }, [
    openBibleChapter,
    targetVerseRef.current,
    isLoading,
    chapterRef.current,
    selectedVerses,
  ]);

  const chapterFetch = useCallback(async ({ bibleId, bookId, chapterId }) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_API_URL}/bible/verses?bible=${bibleId}&book=${bookId}&chapter=${chapterId}`
      );
      const parsedChapter = response.data.verses.map((verse) => ({
        ...verse,
        verse_text: bibleVersesWithoutTags(verse.verse_text),
      }));

      setChapterData(parsedChapter);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (bibleVerse?.versesData && bibleParams.bibleId) {
      chapterFetch(bibleParams);
    }
  }, [bibleParams]);

  useEffect(() => {
    const selectedNums = bibleVerse?.versesData?.map(
      (verse) => verse.verse_num
    );

    setSelectedVerses(selectedNums);

    if (!compareObjects(bibleVerse.versesParams, bibleParams)) {
      setBibleParams(bibleVerse.versesParams);
    }
  }, [bibleVerse?.versesParams, bibleParams]);

  return (
    <QuestionComponentBibleContainer openBible={openBibleChapter}>
      <QuestionMainBibleAddress
        bibleRefBorderColor={bibleRefBorderColor}
        backgroundIsWhite={backgroundIsWhite}
        colorTheme={colorTheme}
        onClick={handleOpenBibleChapter}
      >
        <p>{bibleVerse?.verseAddress}</p>
        <ArrowContainer openBible={openBibleChapter}>
          <ArrowStyled color={colorTheme === "LIGHT" ? "#383838" : "#fff"} />
        </ArrowContainer>
      </QuestionMainBibleAddress>

      {openBibleChapter && (
        <QuestionBibleChapterWrapper color={bibleRefBorderColor}>
          <QuestionBibleChapterContainer>
            <BibleChapterAddressContainer
              background={
                colorTheme === "LIGHT" ? "#fff" : "rgba(255, 255, 255, 0.15)"
              }
            >
              <BibleTranslationVersion>
                {bibleVerse?.versesParams?.bibleId?.toUpperCase()}
              </BibleTranslationVersion>
              <VerticalLineStyled />
              <BibleChapterAddress
                color={colorTheme === "LIGHT" ? "##1e1e1e" : "#fff"}
              >
                {bibleVerse?.verseAddress}
              </BibleChapterAddress>
            </BibleChapterAddressContainer>

            <QuestionBibleChapter ref={chapterRef}>
              {!isLoading ? (
                <>
                  {chapterData?.map((verse) => {
                    const currentVerse = selectedVerses.includes(
                      verse.verse_num
                    );
                    const currentVerseRef =
                      verse.verse_num === selectedVerses[0];

                    return (
                      <QuestionBibleChapterVerse
                        key={verse._id}
                        ref={currentVerseRef ? targetVerseRef : null}
                        currentVerse={currentVerse}
                        colorTheme={colorTheme}
                      >
                        {verse.verse_num}. {verse.verse_text}
                      </QuestionBibleChapterVerse>
                    );
                  })}
                </>
              ) : (
                <LoaderContainer>
                  <SpinnerLoader />
                </LoaderContainer>
              )}
            </QuestionBibleChapter>
          </QuestionBibleChapterContainer>
        </QuestionBibleChapterWrapper>
      )}
    </QuestionComponentBibleContainer>
  );
};

export default memo(QuestionComponentBible);
