import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import axios from "../../../../axios_config";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import RatingAndFeedbackSection from "./rating_and_feedback_section";
import { BASE_API_URL } from "../../../../common/endpoints";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";

const AfterLessonModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const AfterLessonModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 475px;
  padding: 36px 40px 40px;
  border-radius: 25px;
  background: #fff;
  user-select: none;
  text-align: center;

  & > h5 {
    width: 101%;
    color: #000;
    font-size: 28px;
    line-height: 130%;
    font-weight: 600;
  }

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 0;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
  }
`;

const RestButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
  width: 40%;
`;

const ToNextLessonButton = styled(ButtonStyles)`
  width: 59%;
  color: ${({ isHoverRestButton }) =>
    isHoverRestButton ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const FinishCourseButton = styled(ButtonStyles)`
  width: 100%;
  color: #5172ea;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
  margin-top: 4px;
`;

const getNextLessonId = (currentLessonId, lessons) => {
  const currentIndex = lessons.findIndex(
    (lesson) => lesson._id === currentLessonId
  );

  if (currentIndex !== -1 && currentIndex < lessons.length - 1) {
    return lessons[currentIndex + 1]._id;
  }

  return null;
};

const AfterFinishLessonModal = ({
  userData,
  lessonData,
  courseData,
  lessonNumber,
  isLastLesson,
  setAfterFinishLessonModal,
  setCourseFinishModalWindow,
  setCourseFeedbackModal,
  fetchUserLessonData,
}) => {
  const navigate = useNavigate();

  const [isHoverRestButton, setIsHoverOnRestButton] = useState(false);
  const [lessonRating, setLessonRating] = useState(0);
  const [feedbackValue, setFeedbackValue] = useState("");
  const [nextLessonIsFinished, setNextLessonIsFinished] = useState(false);
  const [createdRatingId, setCreatedRatingId] = useState(null);
  const [debouncedFeedbackValue] = useDebounce(feedbackValue, 1000);

  const nextLessonId = getNextLessonId(
    lessonData._id,
    courseData.course_lessons
  );

  const userCourseActivity = userData.user_activity?.find(
    (item) => item.type === "course" && item.course_id === courseData._id
  );

  useEffect(() => {
    if (userCourseActivity.course_id && nextLessonId) {
      const nextLessonStatus = userCourseActivity.course_lessons.find(
        (lesson) => lesson._id === nextLessonId
      )?.lesson_status;

      const nextLessonIsFinished = nextLessonStatus === "finished";

      setNextLessonIsFinished(nextLessonIsFinished);
    }
  }, [userCourseActivity, nextLessonId]);

  const createUserLessonRating = async () => {
    try {
      const newRating = {
        course: lessonData.lesson_course,
        lesson: { _id: lessonData._id },
        user: {
          _id: userData._id,
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
        },
        rating_value: lessonRating,
        rating_comment: feedbackValue,
      };

      const response = await axios.post(`${BASE_API_URL}/ratings`, newRating);

      setCreatedRatingId(response.data.rating._id);
    } catch (error) {
      console.error("Ошибка при создании рейтинга:", error);
    }
  };

  const updateUserLessonRating = async () => {
    try {
      const updatedRating = {
        rating_value: lessonRating,
        rating_comment: feedbackValue,
      };

      const response = await axios.patch(
        `${BASE_API_URL}/ratings/${createdRatingId}`,
        updatedRating
      );
    } catch (error) {
      console.error("Ошибка при обновлении рейтинга:", error);
    }
  };

  useEffect(() => {
    if ((lessonRating > 0 || feedbackValue) && !createdRatingId) {
      createUserLessonRating();
    } else if (lessonRating > 0 && createdRatingId) {
      updateUserLessonRating();
    }
  }, [lessonRating, feedbackValue]);

  useEffect(() => {
    if (createdRatingId && debouncedFeedbackValue) {
      updateUserLessonRating();
    }
  }, [debouncedFeedbackValue, createdRatingId]);

  const toggleHoverOnRestButton = useCallback(() => {
    setIsHoverOnRestButton((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setAfterFinishLessonModal(false);
  }, []);

  const onRestButton = useCallback(
    async (e) => {
      e.stopPropagation();
      navigate(APPLICATION_ROUTES.STUDY_COURSES + `/${courseData._id}`);
    },
    [courseData]
  );

  const onClickFinishCourse = useCallback(
    async (e) => {
      e.stopPropagation();

      try {
        const updatedUserData = {
          ...userData,
          user_activity: userData.user_activity.map((item) =>
            item.type === "course" && item.course_id === courseData._id
              ? { ...item, course_status: "finished" }
              : item
          ),
        };

        await axios.patch(
          `${BASE_API_URL}/users/${userData._id}`,
          updatedUserData
        );

        setAfterFinishLessonModal(false);

        if (courseData.course_certificate?.certificate_is_enabled) {
          setCourseFinishModalWindow(true);
        } else {
          setCourseFeedbackModal(true);
        }
      } catch (error) {
        console.error("Ошибка обновлении статуса курса у пользователя:", error);
      }
    },
    [userData, courseData]
  );

  const onClickToNextLesson = useCallback(
    async (e) => {
      e.stopPropagation();
      const nextLessonNumber = +lessonNumber + 1;

      try {
        if (!nextLessonIsFinished) {
          const createNewUserLesson = {
            study_lesson: { _id: nextLessonId },
            study_user: { _id: userData._id },
            study_answers: [],
            study_course: {
              _id: courseData._id,
              course_name: courseData.course_name,
            },
          };

          await axios.post(`${BASE_API_URL}/userstudy`, createNewUserLesson);
        }

        setAfterFinishLessonModal(false);
        navigate(
          `${APPLICATION_ROUTES.USER_LESSON}/${nextLessonId}?n=${nextLessonNumber}`
        );
      } catch (error) {
        console.error("Ошибка создании урока пользователя:", error);
      }
    },
    [
      nextLessonId,
      lessonNumber,
      userData,
      fetchUserLessonData,
      courseData,
      nextLessonIsFinished,
      navigate,
    ]
  );

  return (
    <AfterLessonModalWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <AfterLessonModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        {isLastLesson ? (
          <h5>Поздравляем, вы завершили последний урок!</h5>
        ) : (
          <h5>
            Поздравляем, <br /> вы завершили урок!
          </h5>
        )}

        <RatingAndFeedbackSection
          lessonRating={lessonRating}
          setLessonRating={setLessonRating}
          feedbackValue={feedbackValue}
          setFeedbackValue={setFeedbackValue}
        />

        <ModalButtonsContainer>
          {!isLastLesson ? (
            <>
              <RestButton
                onMouseEnter={toggleHoverOnRestButton}
                onMouseLeave={toggleHoverOnRestButton}
                onClick={onRestButton}
              >
                Отдохнуть
              </RestButton>
              <VerticalLineStyled color={"#5172EA"} />
              <ToNextLessonButton
                isHoverRestButton={isHoverRestButton}
                onClick={onClickToNextLesson}
              >
                К следующему уроку
              </ToNextLessonButton>
            </>
          ) : (
            <FinishCourseButton onClick={onClickFinishCourse}>
              Завершить курс
            </FinishCourseButton>
          )}
        </ModalButtonsContainer>
      </AfterLessonModalContainer>
    </AfterLessonModalWrapper>
  );
};

export default memo(AfterFinishLessonModal);
