import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { LESSON_BG_COLOR } from "../../../study/course_page/index";
import { convertMinutesToHoursAndMinutes } from "../../../../common/utils/functions/time_functions";
import { BASE_API_URL } from "../../../../common/endpoints";
import CloseMessageIcon from "../../../../common/assets/icons/study_page_icons/close_message_icon";
import { useUserDataContext } from "../../../../user_data_context";

const CourseLessonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 78px;
  padding: 0 15px 0 25px;
  color: #fff;
  border-radius: 25px;
  background: ${({ background }) => background};
  cursor: pointer;

  &:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    transition: 0.2s;
  }

  @media (max-width: 412px) {
    height: 78px;
    padding-left: 22px;
  }
`;

const CourseLessonNum = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.445px;
  margin-right: 16px;
  user-select: none;

  @media (max-width: 412px) {
    font-size: 26px;
    line-height: 130%;
    letter-spacing: -0.39px;
    margin-right: 14px;
  }
`;

const CourseLessonTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.48px;

  @media (max-width: 412px) {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.42px;
  }
`;

const CourseLessonDuration = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.114px;
  user-select: none;

  @media (max-width: 412px) {
    font-size: 10px;
    line-height: 140%;
  }
`;

const CourseLessonMessage = styled.div`
  position: absolute;
  top: -15px;
  right: -45px;
  width: 187px;
  height: 48px;
  padding: 6px 10px;
  background: #5172ea;
  border-radius: 12px 12px 12px 0px;
  z-index: 9;

  @media (max-width: 630px) {
    right: -5px;
  }

  & > p {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
`;

const CloseMessageIconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CourseLesson = ({
  lessonId,
  index,
  isNewCourse,
  userCourseLessons,
  showLessonDuration,
  inComponent,
  showMessageId,
  courseStatus,
  authError,
  setShowMessageId,
  handleClickOnLesson,
}) => {
  const { userData } = useUserDataContext();

  const [lessonData, setLessonData] = useState({});
  const [lessonStatus, setLessonStatus] = useState("closed"); // finished, in_process, next, closed

  const lessonNum = index + 1;

  const lessonBackgroundColor =
    lessonStatus === "closed"
      ? LESSON_BG_COLOR.CLOSED
      : lessonStatus === "in_process" || lessonStatus === "next"
      ? LESSON_BG_COLOR.CURRENT
      : LESSON_BG_COLOR.FINISHED.LARGE;

  useEffect(() => {
    if (inComponent === "admin_page") {
      setLessonStatus("finished");
    } else if (
      courseStatus === "closed" ||
      courseStatus === "announcement" ||
      authError
    ) {
      setLessonStatus("closed");
    } else {
      const userLesson = userCourseLessons?.find(
        (lesson) => lesson._id === lessonId
      );

      if (isNewCourse && index === 0) {
        setLessonStatus("next");
      } else if (userLesson) {
        if (userLesson.lesson_status === "in_process") {
          setLessonStatus("in_process");
        } else if (userLesson.lesson_status === "finished") {
          setLessonStatus("finished");
        }
      } else {
        const lastFinishedIndex = userCourseLessons
          ?.map((lesson) => lesson.lesson_status)
          ?.lastIndexOf("finished");

        const nextIndex = lastFinishedIndex + 1;

        if (
          index === nextIndex &&
          !userCourseLessons?.some(
            (lesson) => lesson.lesson_status === "in_process"
          )
        ) {
          setLessonStatus("next");
        } else {
          setLessonStatus("closed");
        }
      }
    }
  }, [
    inComponent,
    isNewCourse,
    index,
    courseStatus,
    userCourseLessons,
    lessonId,
    authError,
  ]);

  const duration = convertMinutesToHoursAndMinutes(
    lessonData?.lesson_duration_min
  );

  const fetchLessonData = useCallback(async () => {
    axios
      .get(BASE_API_URL + "/lessons/" + lessonId)
      .then((response) => {
        setLessonData(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  }, [lessonId]);

  useEffect(() => {
    if (lessonId) {
      fetchLessonData();
    }
  }, [lessonId]);

  const handleClick = useCallback(
    (e) => {
      if (e.target.closest(".course-lesson") !== e.currentTarget) return;

      handleClickOnLesson(e, lessonId, lessonStatus, lessonNum);
    },
    [lessonId, lessonStatus, lessonNum, userData]
  );

  const handleMessageClick = (e) => {
    e.stopPropagation();
    setShowMessageId(null);
  };

  useEffect(() => {
    const handleClick = () => {
      setShowMessageId(null);
    };

    if (showMessageId === lessonId) {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [showMessageId, lessonId]);

  return (
    <CourseLessonContainer
      className="course-lesson"
      background={lessonBackgroundColor}
      onClick={handleClick}
    >
      <CourseLessonNum>{lessonNum}</CourseLessonNum>
      <div>
        <CourseLessonTitle>{lessonData.lesson_name}</CourseLessonTitle>

        {showLessonDuration && (
          <CourseLessonDuration>
            {!!duration.hours && (
              <span>
                {duration.hours} {duration.hours === 1 ? "час" : "часа"}{" "}
              </span>
            )}
            {!!duration.minutes && <span>{duration.minutes} мин</span>}
            {!duration.hours && !duration.minutes && <span> - </span>}
          </CourseLessonDuration>
        )}
      </div>

      {showMessageId === lessonId && (
        <CourseLessonMessage onClick={handleMessageClick}>
          <p>
            Чтобы получить доступ к этому уроку необходимо завершить предыдущий
            🙂️
          </p>
          <CloseMessageIconContainer>
            <CloseMessageIcon />
          </CloseMessageIconContainer>
        </CourseLessonMessage>
      )}
    </CourseLessonContainer>
  );
};

export default memo(CourseLesson);
