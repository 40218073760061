import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import DailyVerseDesktopArrow from "../../../common/assets/icons/home_page_icons/daily_verse_desktop_arrow";
import FramedBibleVerse from "../../../common/components/framed_bible_verse";
import { BASE_API_URL } from "../../../common/endpoints";
import { Link } from "react-router-dom";
import DailyVerseTabletArrow from "../../../common/assets/icons/home_page_icons/daily_verse_mobile_arrow";

const HomePageDailyVerseWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 35px 0 0 40px;
  margin-right: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding: 35px 0 0;
    margin-right: 0;
    width: 100%;
  }

  @media (max-width: 744px) {
    padding: 0;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  gap: 35px;

  @media (max-width: 1024px) {
    gap: 5px;
  }
`;

const HomePageDailyVerseTitle = styled.div`
  font-family: Caveat;
  font-size: 50px;
  font-weight: 600;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  padding: 0 5px 4px 0;
  height: max-content;

  @media (max-width: 1024px) {
    font-size: 44px;
    line-height: 110%;
  }

  @media (max-width: 744px) {
    font-size: 40px;
    line-height: 110%;
  }
`;

const DailyVerseArrowContainer = styled.div`
  margin-top: 30px;

  @media (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const DailyVerseWrapper = styled(Link)`
  position: absolute;
  bottom: 0;
  left: 0px;
  top: 67%;
  bottom: auto;
  transform: translateY(-50%);
  max-width: 390px;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    position: static;
    top: auto;
    left: auto;
    transform: none;
  }

  ${({ auth }) =>
    auth &&
    `
    top: 65%;
  `}
`;

const DailyVerseContainer = styled.div`
  padding: 15px 15px 5px;
  border-radius: 22px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: rgb(244 244 244);

    div {
      background: rgb(244 244 244);
    }
  }

  div {
    transition: 0.2s;
    background: #eaeaea;
  }

  @media (max-width: 744px) {
    padding: 0;

    div {
      background: #fff;
    }
  }
`;

const HomePageDailyVerse = ({ isAuth }) => {
  const [homePageDailyVerse, setHomePageDailyVerse] = useState({});
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);

  const bibleId = homePageDailyVerse?.versesParams?.bibleId;
  const bookId = homePageDailyVerse?.versesParams?.bookId;
  const chapterId = homePageDailyVerse?.versesParams?.chapterId;
  const selectBible = homePageDailyVerse?.versesParams?.bibleId;
  const selectVerses = homePageDailyVerse?.versesData
    ?.map((verse) => verse.verse_num)
    .join("");

  useEffect(() => {
    const handleResize = () => setIsTablet(window.innerWidth <= 1024);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const versePathInBiblePage = `/bible/?bibleId=${bibleId}&bookId=${bookId}&chapterId=${chapterId}&selectVerses=${selectVerses}&selectBible=${selectBible}`;

  const fetchArticlesData = async () => {
    try {
      const response = await axios.get(
        BASE_API_URL + "/settings/daily_bible_verse"
      );
      setHomePageDailyVerse(response.data.scope_props.bible_verse);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    fetchArticlesData();
  }, []);

  return (
    <HomePageDailyVerseWrapper>
      <TopWrapper>
        <HomePageDailyVerseTitle>Прочти главу</HomePageDailyVerseTitle>
        <DailyVerseArrowContainer>
          {isTablet ? <DailyVerseTabletArrow /> : <DailyVerseDesktopArrow />}
        </DailyVerseArrowContainer>
      </TopWrapper>

      <DailyVerseWrapper to={versePathInBiblePage} auth={isAuth}>
        <DailyVerseContainer>
          {!!Object.values(homePageDailyVerse).length && (
            <FramedBibleVerse
              verse={homePageDailyVerse}
              fontSize={"18px"}
              lineHeight={"26px"}
              inComponent={"home_page"}
            />
          )}
        </DailyVerseContainer>
      </DailyVerseWrapper>
    </HomePageDailyVerseWrapper>
  );
};

export default HomePageDailyVerse;
