import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import MinusIcon from "../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/minus_icon";
import MentorChangeIcon from "../../../common/assets/icons/admin_page_icons/mentor_change_icon";
import UnbindMentorIcon from "../../../common/assets/icons/admin_page_icons/unbind_mentor_icon";
import { BASE_API_URL } from "../../../common/endpoints";
import UserImage from "../../../common/components/user_image";

const AdminPagePersonPreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  padding: 0 9px;
  border-radius: 8px;
  ${({ withoutBorder }) => !withoutBorder && "border: 1px solid #5172ea;"}
  background: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const PersonPreviewName = styled.div`
  flex: 1;
  margin: 0 7px;
  overflow: hidden;
  color: #383838;
  font-size: 14px;
  line-height: 18px;
  user-select: text;
`;

const PersonButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
`;

const PersonButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 30px;

  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`;

const MinusIconStyled = styled(MinusIcon)`
  width: 20px;
`;

const AdminPagePersonPreview = ({
  setSelectedUserInfo,
  unbindUserMentor,
  handleChangeUserMentor,
  onRemoveButton,
  personId,
  component,
  withoutBorder,
  modalWindow,
}) => {
  const [userInfo, setUserInfo] = useState({});

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users/${personId}`);
      setUserInfo(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, [personId]);

  useEffect(() => {
    fetchUserInfo();
  }, [personId]);

  const openPersonInfo = useCallback(() => {
    if (modalWindow === "admin_users_info") {
      setSelectedUserInfo({ data: userInfo });
    } else {
      setSelectedUserInfo(userInfo);
    }
  }, [userInfo, modalWindow]);

  const onClickRemoveButton = useCallback(
    (event) => {
      onRemoveButton(event, userInfo?._id);
    },
    [userInfo?._id]
  );

  return (
    <AdminPagePersonPreviewContainer
      onClick={openPersonInfo}
      withoutBorder={withoutBorder}
    >
      <UserImage userData={userInfo} size={"22px"} fontSize={"8px"} />

      <PersonPreviewName>
        {userInfo?.user_name} {userInfo?.user_second_name}
      </PersonPreviewName>

      {component !== "admin_users_info_modal" && component !== "question" && (
        <>
          {component === "user_mentor" ? (
            <PersonButtonsContainer>
              <PersonButtonStyle onClick={handleChangeUserMentor}>
                <MentorChangeIcon />
              </PersonButtonStyle>
              <PersonButtonStyle onClick={unbindUserMentor}>
                <UnbindMentorIcon />
              </PersonButtonStyle>
            </PersonButtonsContainer>
          ) : (
            <PersonButtonStyle onClick={onClickRemoveButton}>
              <MinusIconStyled />
            </PersonButtonStyle>
          )}
        </>
      )}
    </AdminPagePersonPreviewContainer>
  );
};

export default memo(AdminPagePersonPreview);
