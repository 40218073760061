import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import PlayIcon from "../../../../common/assets/icons/play_icon";
import ImageDropzone from "../../../../common/components/image_dropzone";
import { createPortal } from "react-dom";
import CourseCardImageModalWindow from "../courses_modal_window/course_card_image_modal_window";

const CourseCardImageContainer = styled.div``;

const CourseCardImageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;

  & > h4 {
    width: max-content;
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    user-select: none;
  }
`;

const ViewCardButton = styled.div`
  height: 16px;
  width: max-content;
  cursor: pointer;

  &:hover {
    transform: scale(1.14);
    transition: all 0.2s;
  }
`;

const PlayIconStyles = styled(PlayIcon)`
  height: 100%;
  width: max-content;
`;

const CourseCardImage = ({
  courseSettingsData,
  courseSettingsMemoData,
  updateImageSettings,
}) => {
  const [openCardModalWindow, setOpenCardModalWindow] = useState(false);

  const isCurrentPreviewImage =
    courseSettingsData.course_preview_image ===
    courseSettingsMemoData.course_preview_image;

  const onClickOpenCardModalWindow = useCallback(() => {
    setOpenCardModalWindow(true);
  }, []);

  return (
    <>
      <CourseCardImageContainer>
        <CourseCardImageTitle>
          <h4>Обложка</h4>
          <ViewCardButton onClick={onClickOpenCardModalWindow}>
            <PlayIconStyles color={"#F8D254"} />
          </ViewCardButton>
        </CourseCardImageTitle>
        <ImageDropzone
          imageUrl={courseSettingsData.course_preview_image}
          handleImageSettings={(image) =>
            updateImageSettings(image, "course_preview_image")
          }
          isCurrentImage={isCurrentPreviewImage}
        />
      </CourseCardImageContainer>

      {openCardModalWindow &&
        createPortal(
          <CourseCardImageModalWindow
            courseSettingsData={courseSettingsData}
            setOpenCardModalWindow={setOpenCardModalWindow}
          />,
          document.body
        )}
    </>
  );
};

export default memo(CourseCardImage);
