import React, { useCallback, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";
import { APPLICATION_ROUTES } from "../../../utils/routes";
import HeaderLanguageSelector from "./header_language_selector";
import { HeaderMoreIcon as DefaultMoreIcon } from "../../../assets/icons";
import { UserIcon, UserIconContainer } from "./header_styled_components";
import { useUserDataContext } from "../../../../user_data_context";
import UserImage from "../../user_image";

export const MoreIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;

  @media (max-width: 1024px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  @media (max-width: 470px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

const MoreIcon = styled(DefaultMoreIcon)`
  fill: #1e1e1e;
`;

const MoreMenuWrapper = styled.div`
  position: relative;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const MoreMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  background: #fff;
  padding: 24px 20px;
  bottom: calc(100% + 35px);
  left: -6px;
  border-radius: 15px;

  @media (max-width: 1024px) {
    font-size: 24px;
    border-radius: 25px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }
`;

const MoreMenuItem = styled.div`
  border-bottom: 1px solid rgb(202 202 202 / 52%);
  padding-bottom: 20px;

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }
`;

const HeaderMoreMenu = () => {
  const moreMenuRef = useRef();
  const { userData } = useUserDataContext();

  const [isMoreMenuOpened, setIsMoreMenuOpened] = useState(false);
  const moreButtonClickHandler = useCallback(
    () => setIsMoreMenuOpened(!isMoreMenuOpened),
    [isMoreMenuOpened]
  );
  useOnclickOutside(() => setIsMoreMenuOpened(false), { refs: [moreMenuRef] });

  return (
    <MoreMenuWrapper>
      <MoreIconContainer onClick={moreButtonClickHandler}>
        <MoreIcon />
        Больше
      </MoreIconContainer>
      {isMoreMenuOpened && (
        <MoreMenuContainer ref={moreMenuRef}>
          <MoreMenuItem>
            {!userData._id ? (
              <UserIconContainer to={APPLICATION_ROUTES.AUTH}>
                <UserIcon />
              </UserIconContainer>
            ) : (
              <UserIconContainer to={APPLICATION_ROUTES.PROFILE}>
                <UserImage userData={userData} size={"28px"} />
              </UserIconContainer>
            )}
          </MoreMenuItem>
          <MoreMenuItem>
            <HeaderLanguageSelector />
          </MoreMenuItem>
        </MoreMenuContainer>
      )}
    </MoreMenuWrapper>
  );
};
export default HeaderMoreMenu;
