import React, { memo, useCallback } from "react";
import styled from "styled-components";
import TextEditor from "../../../../../common/components/text_editor";

const ArticleTitleInputContainer = styled.div`
  height: fit-content;
  border-radius: 8px;
  ${({ isEmpty }) => isEmpty && "border: 1px solid red;"}
`;

const ArticleTitleInput = ({
  articleSettingsData,
  setArticleSettingsData,
  emptyInput,
}) => {
  const isEmpty =
    emptyInput && !articleSettingsData?.article_title?.blocks?.[0]?.text;

  console.log(articleSettingsData.article_title);

  const onEditorChange = useCallback((updatedInputText) => {
    setArticleSettingsData((prevState) => ({
      ...prevState,
      article_title: updatedInputText,
    }));
  }, []);

  return (
    <ArticleTitleInputContainer isEmpty={isEmpty}>
      <TextEditor
        contentStateData={articleSettingsData.article_title}
        onEditorChange={onEditorChange}
        placeholder="Заголовок"
        withBackground={true}
        withoutToolbar={true}
        inComponent={"article_title"}
        key={"article_title" + articleSettingsData._id}
      />
    </ArticleTitleInputContainer>
  );
};

export default memo(ArticleTitleInput);
