import React from "react";

const AdminPageAnalyticsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_5502_6541)">
        <path
          d="M30.283 8.08203L18.1441 20.2209L11.7552 13.832L2.17188 23.4154"
          stroke="url(#paint0_linear_5502_6541)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.6172 8.08203H30.2839V15.7487"
          stroke="url(#paint1_linear_5502_6541)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5502_6541"
          x1="15.548"
          y1="5.2634"
          x2="15.548"
          y2="24.7683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5502_6541"
          x1="26.2652"
          y1="6.67272"
          x2="26.2652"
          y2="16.4252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_5502_6541">
          <rect
            width="30.6667"
            height="30.6667"
            fill="white"
            transform="translate(0.894531 0.415039)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdminPageAnalyticsIcon;
