import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useUserDataContext } from "../../../../../user_data_context";
import { LESSON_COLORS_THEME } from "../../../../utils/enums";
import { insertUserNameInText } from "../../../../utils/functions/admin_page_functions";
import QuestionComponentBible from "./question_component_bible";

const bounceAnimation = {
  initial: { scale: 1 },
  animate: {
    scale: [1, 1.2, 0.9, 1.1, 1],
    transition: {
      duration: 0.7,
      times: [0, 0.3, 0.5, 0.7, 1],
      ease: "easeOut",
    },
  },
};

const noBounceAnimation = {
  initial: { scale: 1 },
  animate: { scale: 1 },
};

const QuestionPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const MainQuestionContainer = styled.div`
  display: flex;
  min-height: 60px;
  padding: 16px 14px;
  padding-bottom: ${({ withBible }) => (withBible ? "26px" : "16px")};
  border-radius: 15px;
  background: ${({ background }) => background};
  width: 100%;

  p {
    color: ${({ backgroundIsWhite }) =>
      !backgroundIsWhite ? "#fff" : "#1e1e1e"};
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    width: 100%;
    text-align: center;

    @media (max-width: 420px) {
      font-size: 16px;
    }
  }
`;

const AnswerInput = styled.textarea`
  resize: none;
  max-height: 200px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 15px;
  background: ${({ backgroundWhite }) => backgroundWhite};
  color: ${({ textColor }) => textColor};
  font-size: 16px;
  ${({ cursorPointer }) =>
    cursorPointer &&
    `cursor: pointer;
     pointer-events: none;
  `}
`;

const AnswerOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;
const AnswerOptionWrapper = styled(motion.div)``;

const AnswerOption = styled.div`
  padding: 13px 17px;
  min-height: 54px;
  min-width: 54px;
  max-width: 100%;
  border-radius: 15px;
  background: ${({ backgroundWhite }) => backgroundWhite};
  color: ${({ color }) => color};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  cursor: ${({ hasSelected }) => (hasSelected ? "default" : "pointer")};
  user-select: none;

  ${({ hasSelected }) =>
    !hasSelected &&
    `
    &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    transition: 0.2s;
  }
  `}

  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 24px;
    min-height: 50px;
  }
`;

const QuestionLessonComponent = ({
  componentData,
  setLessonData = () => {},
  setUserAnswersData = () => {},
  inComponent = "",
  colorTheme = "LIGHT",
  lessonIsFinished,
}) => {
  const { userData } = useUserDataContext();
  const [clickAnimation, setClickAnimation] = useState(false);

  const currentUserName = userData.user_name || "Пользователь";

  const answerInputRef = useRef(null);
  const answerInputValue = componentData.answer_input || "";
  const backgroundIsWhite = componentData.background === "WHITE";
  const questionBackground =
    LESSON_COLORS_THEME[colorTheme]?.GRADIENTS?.[componentData.background];
  const bibleRefBorderColor =
    LESSON_COLORS_THEME[colorTheme]?.COLORS?.[componentData.background];
  const backgroundWhite = LESSON_COLORS_THEME[colorTheme]?.GRADIENTS?.["WHITE"];

  const updateLessonData = (updatedComponentData) => {
    setLessonData((prevState) => {
      const newLessonData = prevState.lesson_data.map((component) =>
        component.id === updatedComponentData.id
          ? updatedComponentData
          : component
      );

      return {
        ...prevState,
        lesson_data: newLessonData,
      };
    });
  };

  const handleAnswerInputValue = useCallback(
    (event) => {
      event.stopPropagation();

      if (lessonIsFinished) return;

      if (inComponent === "preview_page" || inComponent === "user_lesson") {
        const updatedComponentData = {
          ...componentData,
          answer_input: event.target.value,
        };

        updateLessonData(updatedComponentData);

        const { id, answer_type, answer_input } = updatedComponentData;

        setUserAnswersData((prevState) => {
          const updatedAnswer = { id, answer_type, answer_input };
          const hasElement = prevState?.some((element) => element.id === id);

          return hasElement
            ? prevState.map((elem) => (elem.id !== id ? elem : updatedAnswer))
            : [...prevState, updatedAnswer];
        });
      }
    },
    [inComponent, componentData, lessonIsFinished]
  );

  const onClickAnswerOption = useCallback(
    (event, option) => {
      event.stopPropagation();

      if (lessonIsFinished) return;

      if (
        (inComponent === "preview_page" || inComponent === "user_lesson") &&
        !componentData.selected_answer
      ) {
        const updatedComponentData = {
          ...componentData,
          selected_answer: option,
        };

        updateLessonData(updatedComponentData);

        const { id, answer_type } = updatedComponentData;

        setUserAnswersData((prevState) => {
          const updatedAnswer = { id, answer_type, selected_answer: option };
          const hasElement = prevState?.some((element) => element.id === id);

          return hasElement
            ? prevState.map((elem) => (elem.id !== id ? elem : updatedAnswer))
            : [...prevState, updatedAnswer];
        });

        setClickAnimation(true);
        setTimeout(() => setClickAnimation(false), 700);
      }
    },
    [inComponent, componentData, lessonIsFinished]
  );

  useEffect(() => {
    if (answerInputRef.current) {
      answerInputRef.current.style.height = "auto";
      answerInputRef.current.style.height =
        answerInputRef.current.scrollHeight + "px";
    }
  }, [answerInputValue, answerInputRef]);

  return (
    <QuestionPreviewContainer>
      <MainQuestionContainer
        withBible={componentData.with_bible}
        background={questionBackground}
        backgroundIsWhite={backgroundIsWhite}
      >
        <p>{insertUserNameInText(componentData.question, currentUserName)}</p>
      </MainQuestionContainer>

      {componentData.with_bible && (
        <QuestionComponentBible
          previewInfo={componentData}
          bibleRefBorderColor={bibleRefBorderColor}
          colorTheme={colorTheme}
          backgroundIsWhite={backgroundIsWhite}
          backgroundWhite={backgroundWhite}
        />
      )}

      {componentData.answer_type === "input" && (
        <AnswerInput
          placeholder="Ваш ответ..."
          ref={answerInputRef}
          type="text"
          rows={3}
          value={answerInputValue}
          onChange={handleAnswerInputValue}
          backgroundWhite={backgroundWhite}
          textColor={colorTheme === "LIGHT" ? "#1e1e1e" : "#fff"}
          cursorPointer={inComponent === "create_lesson"}
          disabled={lessonIsFinished}
        />
      )}

      {componentData.answer_type === "options" && (
        <AnswerOptionsContainer>
          {componentData.data?.answer_options?.map((option, index) => {
            const showOption = componentData.selected_answer
              ? componentData.selected_answer === option
                ? true
                : false
              : true;

            return (
              showOption && (
                <AnswerOptionWrapper
                  key={index + 1 + "-answer_option"}
                  animate={clickAnimation ? "animate" : "initial"}
                  variants={
                    inComponent === "create_lesson"
                      ? noBounceAnimation
                      : bounceAnimation
                  }
                >
                  <AnswerOption
                    backgroundWhite={backgroundWhite}
                    color={colorTheme === "LIGHT" ? "#1e1e1e" : "#fff"}
                    hasSelected={
                      componentData.selected_answer || lessonIsFinished
                    }
                    onClick={(e) => onClickAnswerOption(e, option)}
                  >
                    {option}
                  </AnswerOption>
                </AnswerOptionWrapper>
              )
            );
          })}
        </AnswerOptionsContainer>
      )}
    </QuestionPreviewContainer>
  );
};

export default memo(QuestionLessonComponent);
