import React, { memo } from "react";
import styled from "styled-components";
import QuestionsFilters from "./questions_filters";
import UsersQuestionsList from "./users_questions_list";

const AdminPageQuestionsListContainer = styled.div`
  flex: 1;
  padding-top: 30px;
  height: max-content;
  max-width: 635px;
  margin: 0 auto;
  padding-bottom: 120px;
`;

const AdminPageQuestionsList = ({
  questionsList,
  questionSettingsData,
  questionSettingsMemoData,
  questionsFilterSettings,
  setQuestionsFilterSettings,
  setQuestionSettingsData,
  setQuestionSettingsMemoData,
  setOpenEditQuestionModal,
  isLoading,
}) => {
  return (
    <AdminPageQuestionsListContainer>
      <QuestionsFilters
        questionsFilterSettings={questionsFilterSettings}
        setQuestionsFilterSettings={setQuestionsFilterSettings}
      />
      <UsersQuestionsList
        questionsList={questionsList}
        questionsFilterSettings={questionsFilterSettings}
        questionSettingsData={questionSettingsData}
        questionSettingsMemoData={questionSettingsMemoData}
        setQuestionSettingsData={setQuestionSettingsData}
        setQuestionSettingsMemoData={setQuestionSettingsMemoData}
        setOpenEditQuestionModal={setOpenEditQuestionModal}
        isLoading={isLoading}
      />
    </AdminPageQuestionsListContainer>
  );
};

export default memo(AdminPageQuestionsList);
