import React, { useCallback, useState } from "react";
import styled from "styled-components";
import BibleModalWindow from "../../../../../../common/components/modal_windows/bible_modal_window";
import AddBibleVerse from "../../../../../../common/components/added_bible_verse";
import { AskQuestionLabel } from ".";

const QuestionBibleVersesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 5px;
  max-height: 42vh;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }
`;

const QuestionBibleVerses = ({ userQuestionData, setUserQuestionData }) => {
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [clickedButton, setClickedButton] = useState({});
  const [editedVersesData, setEditedVersesData] = useState({});

  const handleRemoveVerse = (verseInfo) => {
    setUserQuestionData((prevState) => {
      if (prevState?.bibleVerses?.length !== 1) {
        const updatedBibleVerses = prevState.bibleVerses.filter(
          (verse) => verse.verseId !== verseInfo.verseId
        );

        return { ...prevState, bibleVerses: updatedBibleVerses };
      } else return { ...prevState, bibleVerses: [1] };
    });
  };

  const handleOpenBibleModalWindow = useCallback(() => {
    setOpenBibleModalWindow(true);
  }, []);

  const handleAddBibleVerses = useCallback(
    (addedVerse) => {
      if (clickedButton.button === "plus_button") {
        setUserQuestionData((prevState) => {
          const notIsDublicate = !prevState.bibleVerses.some(
            (verse) => verse.verseId === addedVerse.verseId
          );

          if (notIsDublicate) {
            return prevState.bibleVerses[0] !== 1
              ? {
                  ...prevState,
                  bibleVerses: [...prevState.bibleVerses, addedVerse],
                }
              : { ...prevState, bibleVerses: [addedVerse] };
          } else return prevState;
        });
      }

      if (clickedButton.button === "address_button") {
        setUserQuestionData((prevState) => {
          if (prevState.bibleVerses[0] !== 1) {
            const updatedVerses = prevState.bibleVerses.map((verse) =>
              verse.verseId === clickedButton.verseId ? addedVerse : verse
            );
            return { ...prevState, bibleVerses: updatedVerses };
          } else return { ...prevState, bibleVerses: [addedVerse] };
        });
      }
      setClickedButton({});
    },
    [clickedButton]
  );

  return (
    <div>
      <AskQuestionLabel>
        Выберите библейские тексты (по желанию)
      </AskQuestionLabel>

      <QuestionBibleVersesContainer>
        {userQuestionData?.bibleVerses?.map((verse, index, array) => {
          return (
            <AddBibleVerse
              key={index * 4444}
              verseInfo={verse}
              isLastElement={index === array.length - 1}
              handleRemoveVerse={handleRemoveVerse}
              setEditedVersesData={setEditedVersesData}
              handleOpenBibleModalWindow={handleOpenBibleModalWindow}
              setClickedButton={setClickedButton}
              inComponent={"home_page"}
            />
          );
        })}
      </QuestionBibleVersesContainer>

      {openBibleModalWindow && (
        <BibleModalWindow
          editedVersesData={editedVersesData}
          setEditedVersesData={setEditedVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          handleAddBibleVerses={handleAddBibleVerses}
          inComponent={"home_page"}
        />
      )}
    </div>
  );
};

export default QuestionBibleVerses;
