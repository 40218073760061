import React, { memo } from "react";
import ImageDropzone from "../../../../common/components/image_dropzone";
import styled from "styled-components";

const CourseHeaderImageContainer = styled.div`
  & > h4 {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    user-select: none;
    margin-bottom: 10px;
  }
`;

const CourseHeaderImage = ({
  courseSettingsData,
  courseSettingsMemoData,
  updateImageSettings,
}) => {
  const isCurrentHeaderImage =
    courseSettingsData.course_header_image ===
    courseSettingsMemoData.course_header_image;

  return (
    <CourseHeaderImageContainer>
      <h4>Шапка</h4>
      <ImageDropzone
        imageUrl={courseSettingsData.course_header_image}
        handleImageSettings={(image) =>
          updateImageSettings(image, "course_header_image")
        }
        isCurrentImage={isCurrentHeaderImage}
      />
    </CourseHeaderImageContainer>
  );
};

export default memo(CourseHeaderImage);
