import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { useUserDataContext } from "../../../user_data_context";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  getInlineStyles,
  replacePlaceholderWithStudentName,
  replaceSpacesWithNbsp,
  entityStyleFn,
} from "../text_editor/text_editor_functions";
import { TextEditorViewComponent } from "../text_editor/editor_styled_components";

const ArticleTitleContainer = styled(TextEditorViewComponent)`
  flex: 1;
  color: #fff;
  font-weight: 600;
  font-size: ${({ inComponent }) =>
    inComponent === "admin_page" ? "18px" : "31px"};
  line-height: 129%;
  letter-spacing: -0.479px;
  max-height: ${({ inComponent }) =>
    inComponent === "admin_page" ? "300px" : "540px"};
  min-height: ${({ inComponent }) =>
    inComponent === "admin_page" ? "51px" : "80px"};
  ${({ inComponent }) => inComponent === "admin_page" && "padding-top: 4px"};
  word-break: normal
  overflow-wrap: break-word;

  @media (max-width: 1024px) {
    font-size: 26px;
    min-height: 67px;
    max-width: 55%;
  }

  @media (max-width: 744px) {
    font-size: 21px;
    min-height: 55px;
  }
`;

const ArticleTitle = ({ article, inComponent, appColorTheme }) => {
  const { userData } = useUserDataContext();

  const htmlContentRef = useRef(null);

  const [html, setHtml] = useState("");

  const sanitizedHtml = DOMPurify.sanitize(html);

  const currentUserName = userData.user_name || "Пользователь";
  const articleBackground = article.article_background_color;

  useEffect(() => {
    const { blocks, entityMap } = article.article_title || {};

    if (blocks?.length) {
      try {
        const contentState = convertFromRaw({
          blocks,
          entityMap: entityMap || {},
        });

        let htmlContent = stateToHTML(contentState, {
          entityStyleFn,
          inlineStyles: getInlineStyles(appColorTheme, articleBackground),
        });

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );
        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [article.article_title, currentUserName]);

  return (
    <ArticleTitleContainer
      inComponent={inComponent}
      ref={htmlContentRef}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      withBackground={true}
    />
  );
};

export default memo(ArticleTitle);
