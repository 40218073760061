import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CreateLessonSaveButton from "../../../create_lesson_components/create_lesson_save_button";
import { SettingsBlockFixedWrapper } from "../../../create_lesson_components/create_lesson_styled_components";
import BibleModalWindow from "../../../../../../common/components/modal_windows/bible_modal_window";
import QuestionSettingsBible from "./question_settings_bible";
import QuestionSettingsAnswer from "./question_settings_answer";
import { LESSON_COLORS_THEME } from "../../../../../../common/utils/enums";
import ColorSelect from "../../../../../../common/components/color_select";
import { createComponentId } from "../../../../../../common/components/lesson/lesson_utils";

const QuestionSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 219px;
  gap: 21px;
  padding: 26px 24px;
  max-height: 83vh;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const QuestionBackground = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > h5 {
    color: #1e1e1e;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }
`;

const QuestionInputContainer = styled.div`
  height: fit-content;
`;

const QuestionInput = styled.textarea`
  resize: none;
  max-height: 100px;
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const DEFAULT_SETTINGS_DATA_QUESTION = {
  type: "QUESTION",
  data: {
    bible_verse: {},
    answer_options: ["", ""],
  },
  question: "",
  with_bible: false,
  answer_type: "input",
  answer_input: "",
  selected_answer: "",
  background: "",
  id: "",
};

const QuestionSettings = ({
  settingsBlockData,
  editBlockIndex,
  setSettingsBlockData,
  setSettingsBlockMemoData,
  onClickSaveButton,
}) => {
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [editedVersesData, setEditedVersesData] = useState({});
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const questionRef = useRef(null);

  useEffect(() => {
    if (editBlockIndex === null) {
      const lastColor = localStorage.getItem("lastColor");

      const defaultQuestionData = {
        ...DEFAULT_SETTINGS_DATA_QUESTION,
        background:
          lastColor && lastColor !== "TRANSPARENT" ? lastColor : "BLUE",
        id: createComponentId(),
      };

      setSettingsBlockData(defaultQuestionData);
      setSettingsBlockMemoData(defaultQuestionData);
    }
  }, [editBlockIndex]);

  const enableSaveButton = () => {
    const haveQuestion = settingsBlockData.question;
    const haveBibleVerse = settingsBlockData?.data?.bible_verse?.verseAddress;
    const haveTwoAnswerOptions =
      !!settingsBlockData?.data?.answer_options?.[0] &&
      !!settingsBlockData?.data?.answer_options?.[1];

    if (
      haveQuestion &&
      settingsBlockData.answer_type === "options" &&
      haveTwoAnswerOptions &&
      (settingsBlockData.with_bible ? haveBibleVerse : true)
    ) {
      setDisabledSaveButton(false);
    } else if (
      haveQuestion &&
      settingsBlockData.answer_type === "input" &&
      (settingsBlockData.with_bible ? haveBibleVerse : true)
    ) {
      setDisabledSaveButton(false);
    } else {
      setDisabledSaveButton(true);
    }
  };

  const hadleQuestionInputValue = useCallback((event) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      question: event.target.value,
    }));
  }, []);

  const handleAddBibleVerses = useCallback(
    (addedVerse) => {
      setSettingsBlockData((prevState) => {
        const newData = {
          bible_verse: addedVerse,
          answer_options: prevState.data?.answer_options || ["", ""],
          answer_input: "",
        };

        return { ...prevState, data: newData, with_bible: true };
      });
    },
    [setSettingsBlockData]
  );

  const handleSelectColor = useCallback((event, background) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      background,
    }));
  }, []);

  useEffect(() => {
    if (settingsBlockData?.data) {
      enableSaveButton();
    }
  }, [settingsBlockData]);

  useEffect(() => {
    if (
      settingsBlockData.with_bible &&
      !settingsBlockData?.data?.bible_verse?.verseId &&
      !openBibleModalWindow
    ) {
      setSettingsBlockData((prevState) => ({
        ...prevState,
        with_bible: false,
      }));
    }
  }, [settingsBlockData, openBibleModalWindow]);

  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.style.height = "auto";
      questionRef.current.style.height =
        questionRef.current.scrollHeight + "px";
    }
  }, [settingsBlockData.question, questionRef]);

  return (
    <div>
      <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
        <QuestionSettingsContainer>
          <QuestionBackground>
            <h5>Фон вопроса</h5>
            <ColorSelect
              selectedColor={settingsBlockData.background}
              handleSelectColor={handleSelectColor}
              colors={LESSON_COLORS_THEME["LIGHT"].GRADIENTS}
            />
          </QuestionBackground>

          <QuestionInputContainer>
            <QuestionInput
              ref={questionRef}
              placeholder="Напишите вопрос..."
              type="text"
              rows={1}
              value={settingsBlockData.question}
              onChange={hadleQuestionInputValue}
            />
          </QuestionInputContainer>

          <QuestionSettingsBible
            settingsBlockData={settingsBlockData}
            setSettingsBlockData={setSettingsBlockData}
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            setEditedVersesData={setEditedVersesData}
          />

          <QuestionSettingsAnswer
            settingsBlockData={settingsBlockData}
            setSettingsBlockData={setSettingsBlockData}
          />

          <CreateLessonSaveButton
            settingsBlockData={settingsBlockData}
            onClickSaveButton={onClickSaveButton}
            disabled={disabledSaveButton}
          />
        </QuestionSettingsContainer>
      </SettingsBlockFixedWrapper>

      {openBibleModalWindow && (
        <BibleModalWindow
          editedVersesData={editedVersesData}
          setEditedVersesData={setEditedVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          handleAddBibleVerses={handleAddBibleVerses}
          inComponent={"create_lesson-question_settings"}
        />
      )}
    </div>
  );
};

export default memo(QuestionSettings);
