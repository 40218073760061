import React from "react";

const DarkAnimatedModeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99255 2.84826C9.0087 2.27748 7.86722 2.04385 6.73999 2.18254C5.61277 2.32124 4.56083 2.82475 3.74259 3.61726C2.92435 4.40978 2.3841 5.44838 2.2032 6.57667C2.0223 7.70497 2.21054 8.86187 2.73957 9.87317C3.2686 10.8845 4.10979 11.6954 5.13646 12.1839C6.16313 12.6723 7.3197 12.8119 8.432 12.5815C9.54431 12.3511 10.5521 11.7633 11.3037 10.9065C11.5114 10.6698 11.8704 10.6473 12.1056 10.8563C12.3409 11.0653 12.3632 11.4266 12.1555 11.6634C11.241 12.7059 10.0146 13.4213 8.66105 13.7016C7.30752 13.982 5.90013 13.8121 4.65081 13.2177C3.40149 12.6233 2.37788 11.6365 1.73412 10.4059C1.09035 9.17529 0.861291 7.76749 1.08142 6.39451C1.30156 5.02152 1.95897 3.75768 2.95466 2.7933C3.95035 1.82892 5.23042 1.21621 6.6021 1.04743C7.97378 0.878659 9.36281 1.16296 10.56 1.85752C11.7573 2.55208 12.6978 3.61931 13.2402 4.89848C13.3633 5.18893 13.2292 5.52485 12.9406 5.64878C12.652 5.77271 12.3182 5.63772 12.195 5.34726C11.7494 4.29606 10.9764 3.41904 9.99255 2.84826Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7266 3.48435C14.9948 3.64824 15.0803 3.99991 14.9174 4.26984L13.4759 6.65929C13.2769 6.9892 12.8498 7.09427 12.522 6.89396L10.3636 5.5751C10.0953 5.41121 10.0099 5.05953 10.1728 4.7896C10.3356 4.51967 10.6851 4.43371 10.9533 4.5976L12.7339 5.68566L13.9461 3.67635C14.109 3.40642 14.4584 3.32046 14.7266 3.48435Z"
        fill="white"
      />
    </svg>
  );
};

export default DarkAnimatedModeIcon;
