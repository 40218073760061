import React, { memo, useCallback } from "react";
import styled from "styled-components";
import LeftArrowIcon from "../../common/assets/icons/left_arrow_icon";
import { useNavigate } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../common/utils/routes";

const LessonHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: ${({ background }) => background};
  position: fixed;
  z-index: 9999;
`;

const LessonHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 auto;
  max-width: 710px;
  height: 100%;

  @media (max-width: 1024px) {
    max-width: 80%;
  }

  @media (max-width: 830px) {
    max-width: 94%;
    flex-wrap: wrap;
  }

  @media (max-width: 720px) {
    max-width: 100%;
    margin: 0 25px;
  }
`;

const LessonHeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  height: 100%;

  @media (max-width: 1024px) {
    gap: 35px;
  }

  @media (max-width: 830px) {
    gap: 20px;
  }
`;

const LeftArrowIconContainer = styled.div`
  cursor: pointer;
  padding: 6px 15px;

  &:hover {
    transform: scale(1.2);
    transition: 0.2s;
  }
`;

const LessonHeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  color: #fff;

  padding: 2px 0;
  margin-right: 10px;

  @media (max-width: 730px) {
    font-size: 18px;
  }

  & > span {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

const UserLessonPageHeader = ({ appColorTheme, lessonData, lessonNumber }) => {
  const navigate = useNavigate();

  const headerBackground =
    appColorTheme === "DARK"
      ? "linear-gradient(180deg, rgba(81, 114, 234, 0.60) -18.38%, rgba(122, 90, 238, 0.60) 108.82%), #232323"
      : "linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)";

  const goToCoursePage = useCallback(
    (event) => {
      event.stopPropagation();

      navigate(
        APPLICATION_ROUTES.STUDY_COURSES + `/${lessonData.lesson_course?._id}`
      );
    },
    [lessonData]
  );

  return (
    <LessonHeaderWrapper background={headerBackground}>
      <LessonHeaderContainer>
        <LessonHeaderContentWrapper>
          <LeftArrowIconContainer onClick={goToCoursePage}>
            <LeftArrowIcon />
          </LeftArrowIconContainer>

          <LessonHeaderTitle>
            Урок {lessonNumber}. <span>{lessonData.lesson_name}</span>
          </LessonHeaderTitle>
        </LessonHeaderContentWrapper>
      </LessonHeaderContainer>
    </LessonHeaderWrapper>
  );
};

export default memo(UserLessonPageHeader);
