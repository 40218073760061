import React, { memo } from "react";
import { EditorState, RichUtils } from "draft-js";
import TextLinkModalWindow from "../text_link_modal_window";
import TextLinkIcon from "../../../../common/assets/icons/text_editor_icons/text_link_icon";
import {
  TextSettingsButtonsHeaderContainer,
  TextSettingsButtonsHeaderWrapper,
} from "./text_settings_buttons_header";
import styled from "styled-components";

const TextLinkWrapper = styled(TextSettingsButtonsHeaderWrapper)`
  ${({ isActive }) => (isActive ? "rgb(128 148 255)" : "transparent")};
  width: 42px;
`;

const TextLinkContainer = styled(TextSettingsButtonsHeaderContainer)`
  align-items: center;
`;

const TextLinkButton = ({
  textLinkModal,
  editorState,
  currentLink,
  setEditorState,
  setCurrentLink,
  setTextLinkModal,
}) => {
  const getCurrentLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      if (linkKey) {
        const entity = contentState.getEntity(linkKey);
        if (entity.getType() === "LINK") {
          return { key: linkKey, url: entity.getData().url };
        }
      }
    }
    return null;
  };

  const handleLink = (e) => {
    e.preventDefault();
    const currentLink = getCurrentLink();
    setCurrentLink(currentLink);
    setTextLinkModal(true);
  };

  const handleConfirmLink = (url) => {
    const selection = editorState.getSelection();
    let contentState = editorState.getCurrentContent();

    if (currentLink) {
      if (url === "") {
        setEditorState(RichUtils.toggleLink(editorState, selection, null));
      } else {
        contentState = contentState.replaceEntityData(currentLink.key, { url });
        setEditorState(
          EditorState.push(editorState, contentState, "apply-entity")
        );
      }
    } else {
      if (url === "") {
        setEditorState(RichUtils.toggleLink(editorState, selection, null));
      } else {
        contentState = contentState.createEntity("LINK", "MUTABLE", { url });
        const entityKey = contentState.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
          currentContent: contentState,
        });
        setEditorState(
          RichUtils.toggleLink(newEditorState, selection, entityKey)
        );
      }
    }
    setTextLinkModal(false);
  };

  const handleDeleteLink = () => {
    const selection = editorState.getSelection();
    setEditorState(RichUtils.toggleLink(editorState, selection, null));
    setTextLinkModal(false);
  };

  return (
    <div>
      <TextLinkWrapper onClick={handleLink} isActive={getCurrentLink()}>
        <TextLinkContainer>
          <TextLinkIcon />
        </TextLinkContainer>
      </TextLinkWrapper>

      {textLinkModal && (
        <TextLinkModalWindow
          setTextLinkModal={setTextLinkModal}
          onConfirm={handleConfirmLink}
          onDelete={handleDeleteLink}
          initialUrl={currentLink ? currentLink.url : ""}
        />
      )}
    </div>
  );
};

export default memo(TextLinkButton);
