import React, { memo } from "react";
import { LESSON_COMPONENTS_MAPPING } from "../../../../common/components/lesson/lesson_utils";
import { CreateLessonSkeletWrapper } from "../create_lesson_components/create_lesson_styled_components";

const CreateLessonSkeletComponent = ({
  firstElement,
  lastElement,
  settingsBlockData,
  courseMainColor,
  courseMainGradient,
}) => {
  const CurrentSkeletComponent =
    LESSON_COMPONENTS_MAPPING?.[settingsBlockData?.type]?.component;

  return (
    <CreateLessonSkeletWrapper
      firstElement={firstElement}
      lastElement={lastElement}
    >
      <CurrentSkeletComponent
        componentData={settingsBlockData}
        inComponent={"create_lesson"}
        courseMainColor={courseMainColor}
        courseMainGradient={courseMainGradient}
      />
    </CreateLessonSkeletWrapper>
  );
};

export default memo(CreateLessonSkeletComponent);
