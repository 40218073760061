import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../../checkbox";

const TestAnswerOptionContainer = styled.div`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${({ borderColor }) => borderColor};
`;

const TestAnswerOption = ({
  option,
  inComponent,
  componentData,
  lessonIsFinished = false,
  multipleCorrectAnswers,
  setLessonData,
  textColor,
  setUserAnswersData = () => {},
}) => {
  const [show, setShow] = useState(false);

  const borderColor = option.right_answer
    ? "#099d68"
    : "rgba(243, 49, 49, 0.70)";

  useEffect(() => {
    const shouldShow =
      inComponent === "create_lesson" ||
      (lessonIsFinished && option.user_answer);

    setShow(shouldShow);
  }, [inComponent, lessonIsFinished, option.user_answer]);

  const updateComponentData = (
    componentData,
    option,
    multipleCorrectAnswers
  ) => {
    const updatedOptions = componentData.data.map((item) => {
      if (multipleCorrectAnswers) {
        return item.option_text === option.option_text
          ? { ...item, user_answer: !item.user_answer }
          : item;
      } else {
        return item.option_text === option.option_text
          ? { ...item, user_answer: true }
          : { ...item, user_answer: false };
      }
    });

    return {
      ...componentData,
      data: updatedOptions,
    };
  };

  const onSelectAnswer = useCallback(
    (event) => {
      event.stopPropagation();

      if (!lessonIsFinished) {
        const updatedComponentData = updateComponentData(
          componentData,
          option,
          multipleCorrectAnswers
        );

        const { id, data } = updatedComponentData;

        setUserAnswersData((prevState) => {
          const updatedAnswer = { id, data };
          const hasElement = prevState.some((element) => element.id === id);

          return hasElement
            ? prevState.map((elem) => (elem.id !== id ? elem : updatedAnswer))
            : [...prevState, updatedAnswer];
        });

        setLessonData((prevState) => {
          return {
            ...prevState,
            lesson_data: prevState.lesson_data.map((lessonEl) => {
              if (lessonEl.id !== componentData.id) return lessonEl;

              return updatedComponentData;
            }),
          };
        });
      }
    },
    [
      option,
      componentData,
      setLessonData,
      multipleCorrectAnswers,
      lessonIsFinished,
    ]
  );

  return (
    <TestAnswerOptionContainer borderColor={show ? borderColor : "transparent"}>
      <Checkbox
        fontSize={"14px"}
        checked={option.user_answer}
        onClickCheckbox={onSelectAnswer}
        disabled={lessonIsFinished}
        color={textColor}
      >
        {option.option_text}
      </Checkbox>
    </TestAnswerOptionContainer>
  );
};

export default memo(TestAnswerOption);
