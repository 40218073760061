import React from "react";
import { Link } from "react-router-dom";
import { OnBibleLogo, HeaderDonateIcon } from "../../assets/icons";
import { APPLICATION_ROUTES } from "../../utils/routes";
import {
  HomeIcon,
  ChatIcon,
  BibleIcon,
  MainNavContainer,
  HeaderButtonsContainer,
  StickyHeaderContainer,
  StudyIcon,
  UserIcon,
  DonateIconContainer,
  HeaderLogoContainer,
  UserIconContainer,
  AdminButtonContainer,
} from "./header_components/header_styled_components";
import HeaderMenuItem from "./header_components/header_menu_item";
import HeaderLanguageSelector from "./header_components/header_language_selector";
import HeaderMoreMenu from "./header_components/header_more_menu";
import { useAppContext } from "../../../app_context";
import HeaderAdminIcon from "../../assets/icons/header_icons/header_admin_icon";
import { useUserDataContext } from "../../../user_data_context";
import UserImage from "../user_image";

const NAV_LINKS = [
  {
    path: APPLICATION_ROUTES.HOME,
    icon: HomeIcon,
    text: "Главная",
  },
  {
    path: APPLICATION_ROUTES.STUDY,
    icon: StudyIcon,
    text: "Учиться",
  },
  {
    path: APPLICATION_ROUTES.BIBLE,
    icon: BibleIcon,
    text: "Библия",
  },
  {
    path: APPLICATION_ROUTES.CHATS,
    icon: ChatIcon,
    text: "Чаты",
  },
];

const Header = () => {
  const { showAppHeader } = useAppContext();
  const { userData } = useUserDataContext();

  const showAdminButton =
    userData?.user_role === "superadmin" ||
    userData?.user_access?.is_admin_of_platform ||
    userData?.user_access?.is_admin_of_courses ||
    userData?.user_access?.is_admin_of_groups ||
    userData?.user_access?.is_author;

  const appNavItems = NAV_LINKS.filter((link) => {
    if (link.path !== APPLICATION_ROUTES.CHATS) {
      return true;
    }
    return showAdminButton;
  });

  return (
    <>
      {showAppHeader && (
        <StickyHeaderContainer>
          <HeaderLogoContainer>
            <Link to={APPLICATION_ROUTES.HOME}>
              <OnBibleLogo />
            </Link>
          </HeaderLogoContainer>

          <MainNavContainer>
            {appNavItems.map((menuItem) => (
              <HeaderMenuItem key={menuItem.path} menuItem={menuItem} />
            ))}
            <HeaderMoreMenu />
          </MainNavContainer>

          <HeaderButtonsContainer>
            {/* <DonateIconContainer to={APPLICATION_ROUTES.DONATE}>
              <HeaderDonateIcon />
            </DonateIconContainer> */}

            {!userData._id ? (
              <UserIconContainer to={APPLICATION_ROUTES.LOGIN}>
                <UserIcon />
              </UserIconContainer>
            ) : (
              <Link to={APPLICATION_ROUTES.PROFILE}>
                <UserImage
                  userData={userData}
                  size={"26px"}
                  fontSize={"10px"}
                  color={"#fff"}
                  fontWeight={400}
                  background={
                    "linear-gradient(88deg, #05baa4 -19%, #2046e9 129.9%)"
                  }
                  isButton={true}
                />
              </Link>
            )}

            {/* <HeaderLanguageSelector /> */}

            {showAdminButton && (
              <UserIconContainer to={APPLICATION_ROUTES.ADMIN}>
                <AdminButtonContainer>
                  <HeaderAdminIcon />
                </AdminButtonContainer>
              </UserIconContainer>
            )}
          </HeaderButtonsContainer>
        </StickyHeaderContainer>
      )}
    </>
  );
};

export default Header;
