import React from "react";

const BibleCopyLeftArrowIcon = ({ height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height={height || "13"}
      viewBox="0 0 8 13"
      fill="none"
    >
      <path
        d="M6.25 2L1.75 6.5L6.25 11"
        stroke="url(#paint0_linear_8293_9538)"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8293_9538"
          x1="7.07721"
          y1="6.28248"
          x2="1.35294"
          y2="6.28248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BibleCopyLeftArrowIcon;
