import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import CoursesListHeader from "./courses_list_header";
import CoursePreview from "./course_preview";
import CourseInfoModalWindow from "../courses_modal_window/course_info_modal_window";
import DeleteQuestionModalWindow from "../../../../common/components/modal_windows/delete_modal_window";
import { BASE_API_URL } from "../../../../common/endpoints";
import SpinnerLoader from "../../../../common/components/loaders/spinner_loader";

const CoursesListWrapper = styled.div`
  position: relative;
  flex: 1;
  padding-top: 42px;
  height: max-content;
  max-width: 630px;
  margin: 0 auto;
  padding-bottom: 120px;
`;

const CoursesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 28px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 100%;
`;

const AdminPageCoursesList = ({
  coursesList,
  setCoursesList,
  courseSettingsData,
  allAuthorsList,
  courseSettingsMemoData,
  isLoadingCourses,
  setActivePreviewsBlock,
  setCourseSettingsData,
  setCourseSettingsMemoData,
  setOpenEditCourseModal,
  closeCourseSettings,
  onCloseCourseSettings,
  fetchCourses,
}) => {
  const [filteredCoursesBySearch, setFilteredCoursesBySearch] = useState([]);
  const [searchWords, setSearchWords] = useState("");
  const [courseInfoModalData, setCourseInfoModalData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const filteredCourses = coursesList.filter((course) =>
      course.course_name.toLowerCase().includes(searchWords.toLowerCase())
    );

    setFilteredCoursesBySearch(filteredCourses);
  }, [coursesList, searchWords]);

  const onClickCourseDeleteButton = useCallback(async () => {
    try {
      await axios.delete(`${BASE_API_URL}/courses/${courseInfoModalData._id}`);
    } catch (error) {
      console.error("Ошибка при удалении курса:", error);
    } finally {
      closeCourseSettings();
      setCourseInfoModalData({});
      setShowDeleteModal(false);
    }
  }, [courseInfoModalData, closeCourseSettings]);

  return (
    <CoursesListWrapper>
      <CoursesListHeader
        courseSettingsData={courseSettingsData}
        setSearchWords={setSearchWords}
        setCourseSettingsData={setCourseSettingsData}
        setCourseSettingsMemoData={setCourseSettingsMemoData}
        setCoursesList={setCoursesList}
        onCloseCourseSettings={onCloseCourseSettings}
      />

      {!isLoadingCourses ? (
        <CoursesListContainer>
          {filteredCoursesBySearch.map((course) => {
            return (
              <CoursePreview
                key={course._id}
                course={course}
                courseSettingsData={courseSettingsData}
                allAuthorsList={allAuthorsList}
                courseSettingsMemoData={courseSettingsMemoData}
                setActivePreviewsBlock={setActivePreviewsBlock}
                setCourseSettingsData={setCourseSettingsData}
                setCourseSettingsMemoData={setCourseSettingsMemoData}
                setOpenEditCourseModal={setOpenEditCourseModal}
                setCourseInfoModalData={setCourseInfoModalData}
                fetchCourses={fetchCourses}
              />
            );
          })}
        </CoursesListContainer>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      {courseInfoModalData._id && (
        <CourseInfoModalWindow
          courseInfoModalData={courseInfoModalData}
          setCourseInfoModalData={setCourseInfoModalData}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}

      {showDeleteModal && (
        <DeleteQuestionModalWindow
          deletionObject="курс"
          setOpen={setShowDeleteModal}
          handleDelete={onClickCourseDeleteButton}
        />
      )}
    </CoursesListWrapper>
  );
};

export default memo(AdminPageCoursesList);
