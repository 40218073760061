import React from "react";

const ColoredTextIcon = ({ color = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.7789 5.01704L10.9439 14.224C10.9259 14.278 10.9349 14.332 10.9709 14.386C11.0069 14.422 11.0519 14.44 11.1059 14.44H16.5059C16.5599 14.44 16.6049 14.422 16.6409 14.386C16.6769 14.332 16.6859 14.278 16.6679 14.224L13.8329 5.01704C13.8329 4.99904 13.8239 4.99004 13.8059 4.99004C13.7879 4.99004 13.7789 4.99904 13.7789 5.01704ZM7.02888 22C6.65088 22 6.34488 21.847 6.11088 21.541C5.89488 21.235 5.84988 20.902 5.97588 20.542L11.7539 3.74804C11.8979 3.31604 12.1589 2.96504 12.5369 2.69504C12.9149 2.42504 13.3289 2.29004 13.7789 2.29004H13.9409C14.4089 2.29004 14.8229 2.42504 15.1829 2.69504C15.5609 2.96504 15.8219 3.31604 15.9659 3.74804L21.7439 20.542C21.8699 20.902 21.8159 21.235 21.5819 21.541C21.3659 21.847 21.0689 22 20.6909 22H20.5829C20.1329 22 19.7189 21.865 19.3409 21.595C18.9809 21.325 18.7289 20.974 18.5849 20.542L17.5049 16.978C17.4869 16.834 17.3879 16.762 17.2079 16.762H10.4039C10.2419 16.762 10.1429 16.834 10.1069 16.978L9.02688 20.542C8.90088 20.974 8.64888 21.325 8.27088 21.595C7.91088 21.865 7.49688 22 7.02888 22Z"
        fill={color}
      />
    </svg>
  );
};

export default ColoredTextIcon;
