import React, { useState, useMemo, useEffect, useCallback } from "react";
import styled from "styled-components";
import axios from "../../axios_config";
import AuthPasswordInput from "./auth_components/auth_password_input";
import AuthTextInput from "./auth_components/auth_text_input";
import OAuthGoogleButton from "./auth_components/oauth_google_button";
import LoginButton from "./auth_components/auth_login_button";
import { BASE_API_URL } from "../../common/endpoints";
import { useUserDataContext } from "../../user_data_context";
import { useNavigate } from "react-router-dom";
import { useAuthPageContext } from "./auth_page_context";

const AuthInputsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const AuthBottomButtonsContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuthErrorMessage = styled.div`
  position: absolute;
  top: -23px;
  left: 0;
  z-index: 5;
  width: 100%;
  text-align: center;
  color: #ef6f6f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  user-select: none;

  @media (max-width: 440px) {
    font-size: 10px;
  }
`;

const PasswordRecoveryButton = styled.div`
  margin-top: 3px;
  display: flex;
  justify-content: end;
  user-select: none;

  p {
    width: max-content;
    font-size: 16px;
    font-weight: 400;
    line-height: 110%;
    background: linear-gradient(
      180deg,
      rgba(81, 114, 234) -18.38%,
      rgba(122, 90, 238) 108.82%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.2s;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

const validateForm = ({ email, password }) => {
  const errors = {};
  if (!email.trim()) errors.email = "Это поле обязательно для заполнения";
  if (!password.trim()) errors.password = "Это поле обязательно для заполнения";
  return errors;
};

const LoginPage = () => {
  const navigate = useNavigate();
  const { fetchUserData } = useUserDataContext();
  const { redirectPath } = useAuthPageContext();

  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [validationMessages, setValidationMessages] = useState({});
  const [authError, setAuthError] = useState("");

  const userFormData = useMemo(
    () => ({
      user_email: formValues.email,
      user_password: formValues.password,
    }),
    [formValues]
  );

  useEffect(() => {
    setAuthError("");
  }, [formValues]);

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));

    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      [field]: "",
    }));
  };

  const onClickAuthButton = useCallback(async () => {
    const errors = validateForm(formValues);
    setValidationMessages(errors);

    if (Object.keys(errors).length > 0) return;

    try {
      const response = await axios.post(
        `${BASE_API_URL}/auth/bycredentials`,
        userFormData
      );

      const { accessToken } = response.data;

      if (accessToken) {
        localStorage.setItem("access_token", accessToken);
        fetchUserData();
        navigate(redirectPath, { replace: true });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          setAuthError(
            "Неверный логин или пароль. Пожалуйста, попробуйте снова."
          );
        } else {
          setAuthError("Ошибка авторизации. Повторите попытку позже.");
        }
      } else {
        console.error("Unexpected error:", error);
        setAuthError("Что-то пошло не так. Попробуйте позже.");
      }
    }
  }, [formValues, userFormData, fetchUserData, navigate, redirectPath]);

  return (
    <div>
      <AuthInputsContainer>
        {authError && <AuthErrorMessage>{authError}</AuthErrorMessage>}

        <AuthTextInput
          placeholder={"Почта"}
          validationMessage={validationMessages.email}
          value={formValues.email}
          onChange={(e) => handleInputChange("email", e.target.value)}
        />
        <AuthPasswordInput
          placeholder={"Пароль"}
          validationMessage={validationMessages.password}
          value={formValues.password}
          leftHandMessage={true}
          onChange={(e) => handleInputChange("password", e.target.value)}
        />
      </AuthInputsContainer>

      <PasswordRecoveryButton>
        <p>Забыли пароль?</p>
      </PasswordRecoveryButton>

      <AuthBottomButtonsContainer>
        <LoginButton onClick={onClickAuthButton}>Войти</LoginButton>
        <OAuthGoogleButton
          buttonText={"Войти с Google"}
          redirectPath={redirectPath}
        />
      </AuthBottomButtonsContainer>
    </div>
  );
};

export default LoginPage;
