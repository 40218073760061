import React from "react";

const RatingSelectedStarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M15.5489 0.927052C15.8483 0.00574183 17.1517 0.00573993 17.4511 0.927051L20.6535 10.7832C20.7874 11.1952 21.1713 11.4742 21.6046 11.4742H31.9679C32.9366 11.4742 33.3394 12.7138 32.5557 13.2832L24.1716 19.3746C23.8211 19.6293 23.6744 20.0806 23.8083 20.4926L27.0108 30.3488C27.3101 31.2701 26.2556 32.0362 25.4719 31.4668L17.0878 25.3754C16.7373 25.1207 16.2627 25.1207 15.9122 25.3754L7.52809 31.4668C6.74437 32.0362 5.68989 31.2701 5.98924 30.3488L9.1917 20.4926C9.32557 20.0806 9.17891 19.6293 8.82842 19.3746L0.444297 13.2832C-0.339417 12.7138 0.0633578 11.4742 1.03208 11.4742H11.3954C11.8287 11.4742 12.2126 11.1952 12.3465 10.7832L15.5489 0.927052Z"
        fill="url(#paint0_linear_3159_16409)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3159_16409"
          x1="-16.0196"
          y1="46.75"
          x2="49.7166"
          y2="44.4225"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RatingSelectedStarIcon;
