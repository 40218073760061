import React from "react";

const LightAnimatedModeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9926 7.84826C13.0087 7.27748 11.8672 7.04385 10.74 7.18254C9.61277 7.32124 8.56083 7.82475 7.74259 8.61726C6.92435 9.40978 6.3841 10.4484 6.2032 11.5767C6.0223 12.705 6.21054 13.8619 6.73957 14.8732C7.2686 15.8845 8.10979 16.6954 9.13646 17.1839C10.1631 17.6723 11.3197 17.8119 12.432 17.5815C13.5443 17.3511 14.5521 16.7633 15.3037 15.9065C15.5114 15.6698 15.8704 15.6473 16.1056 15.8563C16.3409 16.0653 16.3632 16.4266 16.1555 16.6634C15.241 17.7059 14.0146 18.4213 12.6611 18.7016C11.3075 18.982 9.90013 18.8121 8.65081 18.2177C7.40149 17.6233 6.37788 16.6365 5.73412 15.4059C5.09035 14.1753 4.86129 12.7675 5.08142 11.3945C5.30156 10.0215 5.95897 8.75768 6.95466 7.7933C7.95035 6.82892 9.23042 6.21621 10.6021 6.04743C11.9738 5.87866 13.3628 6.16296 14.56 6.85752C15.7573 7.55208 16.6978 8.61931 17.2402 9.89848C17.3633 10.1889 17.2292 10.5249 16.9406 10.6488C16.652 10.7727 16.3182 10.6377 16.195 10.3473C15.7494 9.29606 14.9764 8.41904 13.9926 7.84826Z"
        fill="url(#paint0_linear_8640_8336)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7266 8.48435C18.9948 8.64824 19.0803 8.99991 18.9174 9.26984L17.4759 11.6593C17.2769 11.9892 16.8498 12.0943 16.522 11.894L14.3636 10.5751C14.0953 10.4112 14.0099 10.0595 14.1728 9.7896C14.3356 9.51967 14.6851 9.43371 14.9533 9.5976L16.7339 10.6857L17.9461 8.67635C18.109 8.40642 18.4584 8.32046 18.7266 8.48435Z"
        fill="url(#paint1_linear_8640_8336)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8640_8336"
          x1="11.6616"
          y1="3.64093"
          x2="11.6616"
          y2="19.9657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8640_8336"
          x1="11.6616"
          y1="3.64093"
          x2="11.6616"
          y2="19.9657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LightAnimatedModeIcon;
