import React from "react";

const LightNotAnimatedModeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="4" fill="white" />
      <path
        d="M7 8H17"
        stroke="#725FED"
        strokeWidth="1.39288"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M7 11L17 11"
        stroke="#725FED"
        strokeWidth="1.39288"
        strokeLinecap="round"
      />
      <path
        opacity="0.4"
        d="M7 14H17"
        stroke="#725FED"
        strokeWidth="1.39288"
        strokeLinecap="round"
      />
      <path
        opacity="0.2"
        d="M7 17H17"
        stroke="#725FED"
        strokeWidth="1.39288"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LightNotAnimatedModeIcon;
