import React, { memo } from "react";
import styled from "styled-components";
import AdminPagePersonPreview from "../../admin_page_components/admin_page_person_preview";

const QuestionSettingsPerson = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #828282;
  font-size: 10px;
  line-height: 110%;
`;

const QuestionSettingsContacts = ({
  questionSettingsData,
  setSelectedUserInfo,
}) => {
  const userMentorId = questionSettingsData.question_from_author_mentor?._id;
  const recipientId = questionSettingsData.question_to_author?._id;

  return (
    <>
      <QuestionSettingsPerson>
        Ученик
        <AdminPagePersonPreview
          personId={questionSettingsData.question_from_author?._id}
          setSelectedUserInfo={setSelectedUserInfo}
          withoutBorder={true}
          component={"question"}
        />
      </QuestionSettingsPerson>

      {userMentorId && (
        <QuestionSettingsPerson>
          Наставник
          <AdminPagePersonPreview
            personId={userMentorId}
            setSelectedUserInfo={setSelectedUserInfo}
            withoutBorder={true}
            component={"question"}
          />
        </QuestionSettingsPerson>
      )}

      {recipientId && (
        <QuestionSettingsPerson>
          Адресат
          <AdminPagePersonPreview
            personId={recipientId}
            setSelectedUserInfo={setSelectedUserInfo}
            withoutBorder={true}
            component={"question"}
          />
        </QuestionSettingsPerson>
      )}
    </>
  );
};

export default memo(QuestionSettingsContacts);
