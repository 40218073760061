import React, { useState, useCallback } from "react";
import styled from "styled-components";
import axios from "../../axios_config";
import AuthPasswordInput from "./auth_components/auth_password_input";
import AuthTextInput, { TextInput } from "./auth_components/auth_text_input";
import OAuthGoogleButton from "./auth_components/oauth_google_button";
import LoginButton from "./auth_components/auth_login_button";
import { BASE_API_URL } from "../../common/endpoints";
import { useAuthPageContext } from "./auth_page_context";
import { Dot, Loader } from "./auth_page";

const AuthInputsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const AuthBottomButtonsContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuthInputsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  color: #f12b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const passwordRegex = /^(?=.*\d)[A-Za-z\d@$!%*#?&-_+=/\\.,():;~]{8,}$/;
const emailRegex = /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/;

const getValidationMessages = ({ name, email, password, confirmPassword }) => {
  const validationMsgObject = {};
  if (!name) validationMsgObject.name = "Обязательно для заполнения";
  if (!email) validationMsgObject.email = "Обязательно для заполнения";
  if (email && !emailRegex.test(email))
    validationMsgObject.email = "Некорректный адрес электронной почты";
  if (!password) validationMsgObject.password = "Обязательно для заполнения";
  if (password && !passwordRegex.test(password))
    validationMsgObject.password =
      "Минимум 8 символов (латинские буквы и цифры)";
  if (!confirmPassword)
    validationMsgObject.confirmPassword = "Обязательно для заполнения";
  if (confirmPassword && password && confirmPassword !== password)
    validationMsgObject.confirmPassword = "Пароли не совпадают";

  return validationMsgObject;
};

const RegistrationPage = () => {
  const {
    name,
    setName,
    secondName,
    setSecondName,
    email,
    setEmail,
    password,
    setPassword,
    country,
    setCountry,
    city,
    setCity,
    confirmPassword,
    setConfirmPassword,
    setShowAuthCodePage,
    redirectPath,
  } = useAuthPageContext();

  const [validationMessages, setValidationMessages] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = useCallback((field, value) => {
    if (field === "email") setEmail(value);
    if (field === "name") setName(value);
    if (field === "secondName") setSecondName(value);
    if (field === "password") setPassword(value);
    if (field === "confirmPassword") setConfirmPassword(value);
    if (field === "country") setCountry(value);
    if (field === "city") setCity(value);

    setValidationMessages((prev) => ({
      ...prev,
      [field]: "",
    }));
  }, []);

  const onSendCodeToMail = async () => {
    if (loading) return;

    setLoading(true);
    setValidationMessages({});

    const newValidationMessages = getValidationMessages({
      name,
      email,
      password,
      confirmPassword,
    });

    setValidationMessages(newValidationMessages);

    if (Object.keys(newValidationMessages).length === 0) {
      try {
        const response = await axios.post(`${BASE_API_URL}/auth/sendcode`, {
          user_email: email,
          user_lang: "ru",
          user_name: name,
        });

        setShowAuthCodePage(true);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
          setValidationMessages((prev) => ({
            ...prev,
            email: "Пользователь с такой почтой уже зарегистрирован",
          }));
        } else {
          console.error("Registration failed:", error.response.data.message);
          setValidationMessages((prev) => ({
            ...prev,
            server: "Ошибка регистрации. Попробуйте позже",
          }));
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <AuthInputsContainer>
        {validationMessages.server && (
          <ErrorMessage>{validationMessages.server}</ErrorMessage>
        )}

        <AuthTextInput
          placeholder={"Почта"}
          value={email}
          onChange={(e) => handleInputChange("email", e.target.value)}
          type={"email"}
          validationMessage={validationMessages.email}
        />

        <AuthInputsSection>
          <AuthTextInput
            placeholder={"Имя"}
            value={name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            type={"name"}
            validationMessage={validationMessages.name}
          />
          <AuthTextInput
            placeholder={"Фамилия"}
            value={secondName}
            onChange={(e) => handleInputChange("secondName", e.target.value)}
            type={"text"}
            require={false}
          />
        </AuthInputsSection>

        <AuthPasswordInput
          placeholder={"Пароль"}
          value={password}
          onChange={(e) => handleInputChange("password", e.target.value)}
          validationMessage={validationMessages.password}
        />
        <AuthPasswordInput
          placeholder={"Повторите пароль"}
          value={confirmPassword}
          onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
          validationMessage={validationMessages.confirmPassword}
        />

        <AuthInputsSection>
          <TextInput
            placeholder={"Город"}
            value={city}
            onChange={(e) => handleInputChange("city", e.target.value)}
            name="user_city"
          />
          <TextInput
            placeholder={"Страна"}
            value={country}
            onChange={(e) => handleInputChange("country", e.target.value)}
            name="user_country"
          />
        </AuthInputsSection>
      </AuthInputsContainer>

      <AuthBottomButtonsContainer>
        <LoginButton disabled={loading} onClick={onSendCodeToMail}>
          {!loading ? (
            "Зарегистрироваться"
          ) : (
            <Loader>
              Отправка кода на почту
              <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </Loader>
          )}
        </LoginButton>
        <OAuthGoogleButton
          buttonText={"Продолжить с Google"}
          redirectPath={redirectPath}
        />
      </AuthBottomButtonsContainer>
    </div>
  );
};

export default RegistrationPage;
