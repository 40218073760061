import React from "react";

const CertificateDownloadIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.625 9.9751V12.4751C13.625 12.8066 13.4933 13.1246 13.2589 13.359C13.0245 13.5934 12.7065 13.7251 12.375 13.7251H3.625C3.29348 13.7251 2.97554 13.5934 2.74112 13.359C2.5067 13.1246 2.375 12.8066 2.375 12.4751V9.9751"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.875 6.8501L8 9.9751L11.125 6.8501"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.9751V2.4751"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CertificateDownloadIcon;
