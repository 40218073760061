import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  AdminPageDiplomaIcon,
  AdminPageAddGroupIcon,
  AdminPageHomeIcon,
  AdminPageQuestionsIcon,
  AdminPageUsersIcon,
  AdminPageAnalyticsIcon,
} from "../../common/assets/icons/admin_page_icons/admin_page_sidebar_icons";
import { APPLICATION_ROUTES } from "../../common/utils/routes";
import { useUserDataContext } from "../../user_data_context";

const sidebarItems = [
  {
    icon: AdminPageHomeIcon,
    path: APPLICATION_ROUTES.ADMIN_HOME,
    requiredAccess: ["isSuperadmin", "isAdminOfPlatformHomePage"],
  },
  {
    icon: AdminPageDiplomaIcon,
    path: APPLICATION_ROUTES.ADMIN_COURSES,
    requiredAccess: [
      "isSuperadmin",
      "isAdminOfCourses",
      "isAdminOfPlatformCourses",
      "isAuthor",
    ],
  },
  {
    icon: AdminPageAddGroupIcon,
    path: APPLICATION_ROUTES.ADMIN_GROUPS,
    requiredAccess: [
      "isSuperadmin",
      "isAdminOfGroups",
      "isAdminOfPlatformGroups",
      "isAuthor",
    ],
  },
  {
    icon: AdminPageQuestionsIcon,
    path: APPLICATION_ROUTES.ADMIN_QUESTIONS,
    requiredAccess: ["isSuperadmin", "isAdminOfPlatformQuestions", "isAuthor"],
  },
  {
    icon: AdminPageUsersIcon,
    path: APPLICATION_ROUTES.ADMIN_USERS,
    requiredAccess: ["isSuperadmin", "isAdminOfPlatformUsers"],
  },
  {
    icon: AdminPageAnalyticsIcon,
    path: APPLICATION_ROUTES.ADMIN_ANALYTICS,
    requiredAccess: ["isSuperadmin", "isAdminOfPlatformAnalytics"],
  },
];

const AdminPageSidebarWrapper = styled.div`
  padding-top: 22px;
`;

const AdminPageSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 64px;
`;

const AdminPageSidebarItem = styled.div`
  display: flex;
  width: 54px;
  height: 54px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  background: var(--1, #fff);
  box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.12);
  opacity: 0.6;
  transition: 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }

  ${({ isActive }) =>
    isActive &&
    `
    width: 64px;
    height: 64px;
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    opacity: 1;
  `}
`;

const AdminPageSidebar = () => {
  const location = useLocation();
  const { accessRights } = useUserDataContext();

  return (
    <AdminPageSidebarWrapper onClick={(e) => e.stopPropagation()}>
      <AdminPageSidebarContainer>
        {sidebarItems
          .filter((item) =>
            item.requiredAccess.some((access) => accessRights[access])
          )
          .map((item, index) => {
            const Icon = item.icon;
            const isActive = location.pathname.includes(item.path);

            return (
              <Link key={index + item.path} to={item.path}>
                <AdminPageSidebarItem isActive={isActive}>
                  <Icon />
                </AdminPageSidebarItem>
              </Link>
            );
          })}
      </AdminPageSidebarContainer>
    </AdminPageSidebarWrapper>
  );
};

export default memo(AdminPageSidebar);
