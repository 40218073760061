import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import AdminPageGroupInfoIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_group_info_icon";
import { UserRolesInfoButton } from "../user_roles_styled_components";
import MinusIcon from "../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/minus_icon";
import PlusIcon from "../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import CheckLargeIcon from "../../../../../common/assets/icons/admin_page_icons/check_large_icon";
import { BASE_API_URL } from "../../../../../common/endpoints";

const CourseOrGroupPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 6px;
  width: 100%;
  min-height: 55px;
  max-height: 55px;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  background: ${({ background }) => background || ""};
  ${({ component }) =>
    (component === "modal_course" || component === "modal_group") &&
    "cursor: pointer;"}
`;

const CourseOrGroupPreviewImage = styled.div`
  width: 73px;
  height: 45px;
  background-size: cover;
  background-position: center;
  background-color: rgba(230, 230, 230);
  ${({ image }) => image && `background-image: url(${image});`}
  border-radius: 3px;
`;

const CourseOrGroupPreviewTitleContainer = styled.div`
  display: flex;
  gap: 5px;
  flex: 1;
  flex-wrap: wrap;
  user-select: none;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const UserRolesCourseOrGroupItem = ({
  component,
  item,
  selected,
  onConfirmItemDeletion,
  handleSelectItem,
}) => {
  const [itemData, setItemData] = useState({});
  const [authorsList, setAuthorsList] = useState("");
  const [background, setBackground] = useState("");

  useEffect(() => {
    let background;

    if (component === "course") {
      background = `linear-gradient(249deg, rgba(219, 50, 234, 0.3) -11.61%, rgba(61, 26, 201, 0.3) 109.78%)`;
    }
    if (component === "group") {
      background = `linear-gradient(49deg, rgba(9, 45, 198, 0.20) -5.13%, rgba(10, 182, 161, 0.20) 105.54%)`;
    }
    if (component === "modal_course" || component === "modal_group") {
      if (selected && component === "modal_course") {
        background = `linear-gradient(249deg, rgba(219, 50, 234, 0.3) -11.61%, rgba(61, 26, 201, 0.3) 109.78%)`;
      } else if (selected && component === "modal_group") {
        background = `linear-gradient(49deg, rgba(9, 45, 198, 0.20) -5.13%, rgba(10, 182, 161, 0.20) 105.54%)`;
      } else {
        background = "#CACACA";
      }
    }

    setBackground(background);
  }, [component, selected]);

  const title =
    component === "course" || component === "modal_course"
      ? itemData?.course_name
      : "Название Группы";

  const image =
    component === "course" || component === "modal_course"
      ? itemData?.course_preview_image
      : "";

  const courseAuthors =
    component === "course" || component === "modal_course"
      ? itemData?.course_authors
      : [];

  const fetchCourse = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setItemData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (item._id) {
      if (component === "course" || component === "modal_course") {
        fetchCourse(item._id);
      } else {
        // логика для загрузки инфо о группе
      }
    }
  }, [component, item]);

  const fetchAuthorsList = useCallback(async () => {
    try {
      const authors = await Promise.all(
        courseAuthors.map((author) =>
          axios.get(BASE_API_URL + "/users/" + author._id).then((response) => {
            const { user_name, user_second_name } = response.data;
            return user_name + (user_second_name && ` ${user_second_name}`);
          })
        )
      );
      setAuthorsList(authors.join(", "));
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, [courseAuthors]);

  useEffect(() => {
    if (courseAuthors?.length) {
      fetchAuthorsList();
    } else {
      setAuthorsList("");
    }
  }, [courseAuthors]);

  const onSelectItem = useCallback(
    (event) => {
      event.stopPropagation();
      if (component === "modal_course" || component === "modal_group") {
        handleSelectItem(itemData);
      }
    },
    [component, itemData]
  );

  const handleRemoveItem = useCallback(() => {
    onConfirmItemDeletion({
      _id: item._id,
      title: title,
    });
  }, [item, title]);

  return (
    <CourseOrGroupPreviewContainer
      background={background}
      component={component}
      onClick={onSelectItem}
    >
      <CourseOrGroupPreviewImage image={image} />

      <CourseOrGroupPreviewTitleContainer>
        <div>{title}</div>
        <h6>{authorsList && `(${authorsList})`}</h6>
      </CourseOrGroupPreviewTitleContainer>

      {(component === "course" || component === "group") && (
        <ButtonsContainer>
          <UserRolesInfoButton onClick={handleRemoveItem}>
            <MinusIcon />
          </UserRolesInfoButton>

          <UserRolesInfoButton>
            <AdminPageGroupInfoIcon color={"#FFFFFF"} />
          </UserRolesInfoButton>
        </ButtonsContainer>
      )}
      {(component === "modal_course" || component === "modal_group") &&
        (!selected ? <PlusIcon /> : <CheckLargeIcon />)}
    </CourseOrGroupPreviewContainer>
  );
};

export default memo(UserRolesCourseOrGroupItem);
