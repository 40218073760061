import React from "react";
import styled from "styled-components";
import PrivatBankCardImg from "../../../common/assets/images/donate_page_images/privat_card.png";
import MonobankCardImg from "../../../common/assets/images/donate_page_images/mono_card.png";
import SberBankCardImg from "../../../common/assets/images/donate_page_images/sber_card.png";
import BitcoinCardImg from "../../../common/assets/images/donate_page_images/bitcoin_card.png";
import EthereumCardImg from "../../../common/assets/images/donate_page_images/ethereum_card.png";
import Card from "./donate_page_card";
import { ToastNotification } from "../../../common/components/toast_notification";

const DonateToCardsContainer = styled.div`
  margin-top: 42px;
  padding-bottom: 120px;

  @media (max-width: 412px) {
    margin-top: 55px;
  }
`;

const DonateToCardsText = styled.h4`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

const DonateToCardsList = styled.div`
  column-count: 3;
  width: 853px;
  margin: 20px auto 0;

  @media (max-width: 920px) {
    width: 100%;
  }

  @media (max-width: 880px) {
    column-count: 2;
    width: 556px;
  }

  @media (max-width: 670px) {
    column-count: 1;
    width: 100%;
    margin-top: 35px;
  }
`;

const DONATE_CARD_LIST = [
  {
    name: "PrivatBank Card",
    number: "8763273698730329",
    image: PrivatBankCardImg,
  },
  {
    name: "Bitcoin Card",
    number: "8763273698730329",
    image: BitcoinCardImg,
  },
  {
    name: "Monobank Card",
    number: "8763273698730329",
    image: MonobankCardImg,
  },
  {
    name: "Ethereum Card",
    number: "8763273698730329",
    image: EthereumCardImg,
  },
  {
    name: "SberBank Card",
    number: "8763273698730329",
    image: SberBankCardImg,
  },
  {
    name: "Ethereum Card",
    number: "8763273698730329",
    image: EthereumCardImg,
  },
];

const DonateToCards = () => {
  return (
    <DonateToCardsContainer>
      <DonateToCardsText>
        Нажми на удобную для тебя карту, чтобы скопировать номер
      </DonateToCardsText>
      <DonateToCardsList>
        {DONATE_CARD_LIST.map((card, index) => (
          <Card card={card} key={card.name + index} />
        ))}
      </DonateToCardsList>

      <ToastNotification />
    </DonateToCardsContainer>
  );
};

export default DonateToCards;
