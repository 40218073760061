import React from "react";

const CertificateLineLargeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="165"
      height="2"
      viewBox="0 0 165 2"
      fill="none"
    >
      <path
        d="M0.55957 0.694824H164.085"
        stroke="#373962"
        strokeWidth="1.13559"
      />
    </svg>
  );
};

export default CertificateLineLargeIcon;
