import React, { memo, useCallback } from "react";
import styled from "styled-components";
import CheckIcon from "../assets/icons/admin_page_icons/check_icon";

const ColorSelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ inTextComponent }) => (inTextComponent ? 11 : 8)}px;
`;

const ColorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background: ${({ color }) => color};
  ${({ isTransparent }) => isTransparent && "border: 2px solid #cacaca;"}
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: box-shadow 0.2s;
  }
`;

const CheckedIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: #fff;
  padding: 1px 0 0 0;
  border-radius: 50%;
`;

const ColorSelect = ({
  selectedColor,
  handleSelectColor,
  colors,
  inComponent = "",
}) => {
  const onClickColor = useCallback((e, color) => {
    handleSelectColor(e, color);
    localStorage.setItem("lastColor", color);
  }, []);

  return (
    <ColorSelectContainer inTextComponent={inComponent === "TEXT"}>
      {Object.entries(colors).map(([colorName, colorValue]) => {
        return (
          <ColorContainer
            key={colorName}
            color={colorValue}
            isTransparent={colorName === "TRANSPARENT"}
            onClick={(e) => onClickColor(e, colorName)}
          >
            {selectedColor === colorName && (
              <CheckedIconContainer>
                <CheckIcon />
              </CheckedIconContainer>
            )}
          </ColorContainer>
        );
      })}
    </ColorSelectContainer>
  );
};

export default memo(ColorSelect);
