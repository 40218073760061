import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../axios_config";
import styled from "styled-components";
import CoursePageHeader from "./course_page_header";
import { BASE_API_URL } from "../../../common/endpoints";
import CourseLessons from "./course_lessons";
import AboutCourse from "./about_course";
import CourseVideoModalWindow from "./course_video_modal_window";
import AboutCourseAuthors from "./about_course_authors";
import CourseVideo from "./course_video";
import CourseCertificate from "./course_certificate";
import CourseAnnouncementMessage from "./course_announcement_message";
import CourseLevelAndLessonsInfo from "./course_level_and_lessons_info";
import { useUserDataContext } from "../../../user_data_context";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import CertificateDownloadIcon from "../../../common/assets/icons/course_certificate_icons/certificate_download_icon";
import { ToastNotification } from "../../../common/components/toast_notification";

export const LESSON_BG_COLOR = {
  FINISHED: {
    SMALL: "linear-gradient(180deg, #05baa4 0%, #288bce 100%)",
    LARGE: "linear-gradient(50deg, #05BAA4 8.65%, #2046E9 100%)",
  },
  CURRENT: "#F8D254",
  CLOSED: "#CACACA",
};

const CoursePageContainer = styled.div`
  padding-bottom: 80px;

  @media (max-width: 770px) {
    padding-bottom: 50px;
  }
`;

const CourseCertificateDownloadButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 16px auto 0;
  height: 48px;
  width: max-content;
  padding: 0 40px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  h6 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: 0.24px;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 48px;
  text-align: center;
  color: red;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const CoursePage = ({ inComponent = "" }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userData, fetchUserData, authError } = useUserDataContext();

  const [courseData, setCourseData] = useState({});
  const [openCourseVideoModal, setOpenCourseVideoModal] = useState(false);
  const [showMessageId, setShowMessageId] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState("");

  const userCourse = userData.user_activity?.find(
    (course) => course.course_id === courseData._id
  );

  const showCertificateDownloadButton =
    inComponent !== "admin_page" &&
    courseData.course_certificate?.certificate_is_enabled &&
    userCourse?.course_status === "finished";

  const fetchLessonData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + `/courses/${id}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных курса:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLessonData();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUserData();
  }, []);

  const handleClickOnLesson = useCallback(
    async (event, lessonId, lessonStatus, lessonNum) => {
      event.stopPropagation();

      if (inComponent === "admin_page") {
        navigate(
          `${APPLICATION_ROUTES.ADMIN_LESSON_PREVIEW}/${lessonId}?n=${lessonNum}`
        );
        return;
      }

      if (lessonStatus === "next") {
        try {
          const createNewUserLesson = {
            study_lesson: { _id: lessonId },
            study_user: { _id: userData._id },
            study_answers: [],
            study_course: {
              _id: courseData._id,
              course_name: courseData.course_name,
            },
          };

          await axios.post(`${BASE_API_URL}/userstudy`, createNewUserLesson);

          navigate(
            `${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`
          );
        } catch (error) {
          console.error("Ошибка создании урока пользователя:", error);
        }
      }

      if (lessonStatus === "finished" || lessonStatus === "in_process") {
        navigate(
          `${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`
        );
      }

      if (lessonStatus === "closed") {
        setShowMessageId(lessonId);
      }
    },
    [inComponent, userData, courseData]
  );

  const onClickDownloadCertificate = useCallback(
    async (event) => {
      event.stopPropagation();
      setIsDownloading(true);
      setError(null);

      try {
        const certificateData = {
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
          course_name: courseData.course_name,
          certificate_description:
            courseData.course_certificate?.certificate_description,
          certificate_type: courseData.course_certificate?.certificate_image,
        };

        const response = await axios.post(
          `${BASE_API_URL}/courses/certificate`,
          certificateData,
          { responseType: "blob" }
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "certificate.pdf");
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        setError("Ошибка при получении сертификата");
      } finally {
        setIsDownloading(false);
      }
    },
    [userData, courseData]
  );

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 4000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <CoursePageContainer>
      <CoursePageHeader
        courseData={courseData}
        inComponent={inComponent}
        userCourse={userCourse}
        authError={authError}
        handleClickOnLesson={handleClickOnLesson}
      />

      {(courseData.course_status === "open" ||
        inComponent === "admin_page") && (
        <CourseLevelAndLessonsInfo
          courseData={courseData}
          inComponent={inComponent}
          showMessageId={showMessageId}
          userCourse={userCourse}
          authError={authError}
          setShowMessageId={setShowMessageId}
          handleClickOnLesson={handleClickOnLesson}
        />
      )}

      {courseData.course_status === "announcement" && (
        <CourseAnnouncementMessage />
      )}

      {showCertificateDownloadButton && (
        <CourseCertificateDownloadButton
          onClick={onClickDownloadCertificate}
          disabled={isDownloading}
        >
          <h6>{isDownloading ? "Загрузка..." : "Скачать сертификат"}</h6>
          <CertificateDownloadIcon />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </CourseCertificateDownloadButton>
      )}

      <AboutCourse courseData={courseData} />

      {courseData.course_video_enabled && (
        <CourseVideo
          videoImage={courseData?.course_video_image}
          setOpenCourseVideoModal={setOpenCourseVideoModal}
        />
      )}

      <AboutCourseAuthors courseData={courseData} />

      <CourseLessons
        courseData={courseData}
        inComponent={inComponent}
        showMessageId={showMessageId}
        setShowMessageId={setShowMessageId}
        userCourse={userCourse}
        authError={authError}
        handleClickOnLesson={handleClickOnLesson}
      />

      {courseData.course_certificate?.certificate_is_enabled && (
        <CourseCertificate />
      )}

      {openCourseVideoModal && courseData?.course_video_url && (
        <CourseVideoModalWindow
          videoUrl={courseData?.course_video_url}
          setOpenCourseVideoModal={setOpenCourseVideoModal}
        />
      )}

      <ToastNotification />
    </CoursePageContainer>
  );
};

export default memo(CoursePage);
