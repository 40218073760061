import React from "react";

const DailyVerseDesktopArrow = ({ className }) => {
  return (
    <svg
      width="176"
      height="168"
      viewBox="0 0 176 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        id="Arrow 1"
        d="M3.00008 0.50002C1.61937 0.50002 0.500082 1.61931 0.500083 3.00002C0.500087 4.38073 1.61938 5.50002 3.00009 5.50002L3.00008 0.50002ZM90.1379 157.182C89.6858 158.486 90.3769 159.91 91.6815 160.362L112.941 167.73C114.246 168.182 115.67 167.491 116.122 166.186C116.574 164.881 115.883 163.457 114.578 163.005L95.6808 156.457L102.229 137.559C102.682 136.254 101.99 134.83 100.686 134.378C99.3812 133.926 97.9572 134.617 97.5051 135.922L90.1379 157.182ZM3.00009 5.50002C69.6399 5.49995 112.425 13.1247 138.151 24.8528C150.985 30.7041 159.437 37.5154 164.536 44.7782C169.609 52.0036 171.468 59.8228 170.882 67.9449C169.696 84.364 158.486 102.17 142.959 118.115C127.503 133.988 108.145 147.629 91.4086 155.751L93.5916 160.249C110.855 151.872 130.685 137.887 146.541 121.604C162.327 105.393 174.554 86.5109 175.869 68.305C176.532 59.1146 174.407 50.1369 168.628 41.9052C162.875 33.711 153.609 26.4051 140.225 20.3033C113.512 8.12518 69.8603 0.499954 3.00008 0.50002L3.00009 5.50002Z"
        fill="url(#paint0_linear_5099_15963)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5099_15963"
          x1="126.356"
          y1="-19.6233"
          x2="24.7255"
          y2="125.563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DailyVerseDesktopArrow;
