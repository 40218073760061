import React from "react";

const LightThemeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_8640_8404)">
        <path
          d="M6.99922 11.686C6.73538 11.686 6.52148 11.8999 6.52148 12.1638V13.5221C6.52148 13.7859 6.73538 13.9998 6.99922 13.9998C7.26312 13.9998 7.47696 13.7859 7.47696 13.5221V12.1638C7.47696 11.8999 7.26306 11.686 6.99922 11.686Z"
          fill="#F8D254"
        />
        <path
          d="M6.99922 2.31391C7.26312 2.31391 7.47696 2.10001 7.47696 1.83617V0.477737C7.47696 0.213899 7.26312 0 6.99922 0C6.73538 0 6.52148 0.213899 6.52148 0.477737V1.83617C6.52148 2.10001 6.73538 2.31391 6.99922 2.31391Z"
          fill="#F8D254"
        />
        <path
          d="M3.01101 10.3131L2.05018 11.2735C1.86355 11.46 1.86348 11.7625 2.04999 11.9491C2.14331 12.0424 2.26561 12.0891 2.38791 12.0891C2.51015 12.0891 2.63232 12.0424 2.72564 11.9492L3.68646 10.9889C3.8731 10.8024 3.87316 10.4999 3.68665 10.3133C3.50002 10.1267 3.19758 10.1267 3.01101 10.3131Z"
          fill="#F8D254"
        />
        <path
          d="M10.6505 3.82601C10.7728 3.82601 10.895 3.77938 10.9883 3.68613L11.949 2.72581C12.1356 2.53924 12.1357 2.2368 11.9492 2.05017C11.7626 1.86353 11.4602 1.86347 11.2735 2.0501L10.3128 3.01042C10.1262 3.19693 10.1262 3.49943 10.3127 3.686C10.4059 3.77938 10.5282 3.82601 10.6505 3.82601Z"
          fill="#F8D254"
        />
        <path
          d="M2.31378 6.99995C2.31378 6.73612 2.09988 6.52222 1.83604 6.52222H0.477737C0.213899 6.52222 0 6.73612 0 6.99995C0 7.26379 0.213899 7.47769 0.477737 7.47769H1.83604C2.09988 7.47769 2.31378 7.26379 2.31378 6.99995Z"
          fill="#F8D254"
        />
        <path
          d="M13.5222 6.52222H12.1633C11.8994 6.52222 11.6855 6.73612 11.6855 6.99995C11.6855 7.26379 11.8994 7.47769 12.1633 7.47769H13.5222C13.7861 7.47769 14 7.26379 14 6.99995C14 6.73618 13.7861 6.52222 13.5222 6.52222Z"
          fill="#F8D254"
        />
        <path
          d="M3.0104 3.68629C3.10366 3.77955 3.22596 3.82618 3.34819 3.82618C3.47043 3.82618 3.59273 3.77955 3.68599 3.68629C3.87256 3.49972 3.87256 3.19722 3.68599 3.01065L2.72567 2.05033C2.5391 1.86376 2.23659 1.86376 2.05009 2.05033C1.86351 2.2369 1.86351 2.53941 2.05009 2.72591L3.0104 3.68629Z"
          fill="#F8D254"
        />
        <path
          d="M10.9895 10.3133C10.8029 10.1267 10.5004 10.1267 10.3138 10.3132C10.1272 10.4998 10.1272 10.8023 10.3137 10.9888L11.2739 11.9491C11.3673 12.0425 11.4895 12.0891 11.6118 12.0891C11.734 12.0891 11.8563 12.0425 11.9496 11.9492C12.1362 11.7626 12.1362 11.4601 11.9496 11.2736L10.9895 10.3133Z"
          fill="#F8D254"
        />
        <path
          d="M6.99951 3.28149C4.94925 3.28149 3.28125 4.94963 3.28125 7.00001C3.28125 9.05033 4.94925 10.7184 6.99951 10.7184C9.04996 10.7184 10.7181 9.05033 10.7181 7.00001C10.7181 4.94963 9.0499 3.28149 6.99951 3.28149ZM6.99951 9.76293C5.4761 9.76293 4.23672 8.52348 4.23672 7.00001C4.23672 5.47647 5.4761 4.23697 6.99951 4.23697C8.52311 4.23697 9.76262 5.47647 9.76262 7.00001C9.76262 8.52348 8.52311 9.76293 6.99951 9.76293Z"
          fill="#F8D254"
        />
      </g>
      <defs>
        <clipPath id="clip0_8640_8404">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightThemeIcon;
