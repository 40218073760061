import React from "react";

const BibleChapterArrowIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_8669_6690)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.74372 0.246657C10.0854 0.581392 10.0854 1.1241 9.74372 1.45884L5.11244 5.99561L9.74372 10.5324C10.0854 10.8671 10.0854 11.4098 9.74372 11.7446C9.40201 12.0793 8.84799 12.0793 8.50628 11.7446L3.25628 6.6017C2.91457 6.26696 2.91457 5.72425 3.25628 5.38951L8.50628 0.246657C8.84799 -0.0880784 9.40201 -0.0880784 9.74372 0.246657Z"
          fill="#383838"
        />
      </g>
      <defs>
        <clipPath id="clip0_8669_6690">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(12.5) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BibleChapterArrowIcon;
