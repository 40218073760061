import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import AdminPageHomePreview from "./admin_page_home_preview";
import AdminPageHomeSettings from "./admin_page_home_settings";
import { compareObjects } from "../../../common/utils/functions/compare_objects";
import { BASE_API_URL } from "../../../common/endpoints";
import ScrollToTopButton from "../../../common/components/scroll_to_top_button";
import { useAppContext } from "../../../app_context";
import { useUserDataContext } from "../../../user_data_context";
import { useNavigate } from "react-router-dom";

const AdminPageHomeWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const AdminPageHomeContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;
`;

const AdminPageHome = () => {
  const navigate = useNavigate();
  const adminPageHomeRef = useRef(null);
  const { appColorTheme } = useAppContext();
  const { accessRights, userData } = useUserDataContext();

  const [activeSettingsBlock, setActiveSettingsBlock] = useState(""); // daily_verse_settings, article_settings
  const [articleSettingsData, setArticleSettingsData] = useState({});
  const [articleSettingsDataMemo, setArticleSettingsDataMemo] = useState({});
  const [editedArticleId, setEditedArticleId] = useState(null);
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false);
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [homePageArticles, setHomePageArticles] = useState([]);
  const [homePageDailyVerse, setHomePageDailyVerse] = useState({});
  const [loadingArticles, setLoadingArticles] = useState(false);

  const hasAccess =
    accessRights.isSuperadmin || accessRights.isAdminOfPlatformHomePage;

  useEffect(() => {
    if (userData._id && !hasAccess) {
      navigate("/bible");
    }

    if (userData._id && hasAccess) {
      localStorage.setItem("lastAdminUrl", location.pathname);
    }
  }, [accessRights, userData]);

  const fetchArticlesData = () => {
    setLoadingArticles(true);
    axios
      .get(BASE_API_URL + "/articles")
      .then((response) => {
        setHomePageArticles(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      })
      .finally(() => {
        setLoadingArticles(false);
      });
  };

  useEffect(() => {
    fetchArticlesData();
  }, []);

  const closeSettings = useCallback(() => {
    setActiveSettingsBlock("");
    setArticleSettingsData({});
    setEditedArticleId(null);
    setArticleSettingsDataMemo({});
    setOpenEditQuestionModal(false);
    setOpenDeleteQuestionModal(false);
    fetchArticlesData();
  }, []);

  const closeSettingsWithoutSave = useCallback(() => {
    if (editedArticleId === "new_article") {
      setHomePageArticles((prevState) =>
        prevState.filter((article) => article._id !== "new_article")
      );
    } else {
      setHomePageArticles((prevState) =>
        prevState.map((article) =>
          article._id === editedArticleId ? articleSettingsDataMemo : article
        )
      );
    }
    closeSettings();
  }, [editedArticleId, articleSettingsDataMemo]);

  const onCloseActiveSettings = useCallback(() => {
    if (
      (activeSettingsBlock &&
        compareObjects(articleSettingsDataMemo, articleSettingsData)) ||
      activeSettingsBlock === "daily_verse_settings"
    ) {
      closeSettingsWithoutSave();
    } else if (
      activeSettingsBlock &&
      !compareObjects(articleSettingsDataMemo, articleSettingsData)
    ) {
      setOpenEditQuestionModal(true);
    }
  }, [
    editedArticleId,
    articleSettingsDataMemo,
    articleSettingsData,
    activeSettingsBlock,
  ]);

  useEffect(() => {
    if (activeSettingsBlock === "article_settings" && editedArticleId) {
      setHomePageArticles((prevState) => {
        return prevState.map((article) => {
          if (article._id === editedArticleId) {
            return articleSettingsData;
          } else return article;
        });
      });
    }
  }, [activeSettingsBlock, articleSettingsData, editedArticleId]);

  if (!hasAccess) return;

  return (
    <AdminPageHomeWrapper>
      <AdminPageHomeContainer
        ref={adminPageHomeRef}
        onMouseDown={onCloseActiveSettings}
      >
        <AdminPageHomePreview
          activeSettingsBlock={activeSettingsBlock}
          homePageDailyVerse={homePageDailyVerse}
          homePageArticles={homePageArticles}
          loadingArticles={loadingArticles}
          articleSettingsDataMemo={articleSettingsDataMemo}
          editedArticleId={editedArticleId}
          articleSettingsData={articleSettingsData}
          setActiveSettingsBlock={setActiveSettingsBlock}
          setHomePageDailyVerse={setHomePageDailyVerse}
          setHomePageArticles={setHomePageArticles}
          setArticleSettingsData={setArticleSettingsData}
          setEditedArticleId={setEditedArticleId}
          setArticleSettingsDataMemo={setArticleSettingsDataMemo}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          onCloseActiveSettings={onCloseActiveSettings}
          appColorTheme={appColorTheme}
        />
        <AdminPageHomeSettings
          activeSettingsBlock={activeSettingsBlock}
          articleSettingsData={articleSettingsData}
          openEditQuestionModal={openEditQuestionModal}
          articleSettingsDataMemo={articleSettingsDataMemo}
          homePageDailyVerse={homePageDailyVerse}
          setHomePageDailyVerse={setHomePageDailyVerse}
          setArticleSettingsData={setArticleSettingsData}
          setArticleSettingsDataMemo={setArticleSettingsDataMemo}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
          openDeleteQuestionModal={openDeleteQuestionModal}
          closeSettings={closeSettings}
          closeSettingsWithoutSave={closeSettingsWithoutSave}
        />
      </AdminPageHomeContainer>

      <ScrollToTopButton parentRef={adminPageHomeRef} />
    </AdminPageHomeWrapper>
  );
};

export default memo(AdminPageHome);
