import React, { useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../../common/assets/icons/vertical_line";

const AskQuestionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 16px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;

  &:hover {
    font-size: 18px;
    color: #fff;
    transition: 0.2s;
    width: 53%;
    cursor: pointer;
  }
`;

const SendButton = styled(ButtonStyles)`
  color: ${({ isHoverOnSend, activeSendButton }) =>
    isHoverOnSend && activeSendButton ? "#fff" : "rgba(255, 255, 255, 0.5)"};

  ${({ activeSendButton }) =>
    !activeSendButton &&
    `
    &:hover {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      width: 49%;
      cursor: default;
    }
    `}
`;

const CancelButton = styled(ButtonStyles)`
  color: rgba(255, 255, 255, 0.5);
`;

const AskQuestionButtons = ({
  activeSendButton,
  onClickSendButton,
  userQuestionData,
  onCloseAskQuestionModalWindow,
}) => {
  const [isHoverOnSend, setIsHoverOnSend] = useState(true);

  const hadleClickSendButton = useCallback(
    (event) => {
      if (activeSendButton) {
        onClickSendButton(event, userQuestionData);
      }
    },
    [activeSendButton, userQuestionData]
  );

  const toggleHoverOnDeleteButton = useCallback(() => {
    setIsHoverOnSend((prevState) => !prevState);
  }, []);

  return (
    <AskQuestionButtonsContainer>
      <SendButton
        activeSendButton={activeSendButton}
        isHoverOnSend={isHoverOnSend}
        onClick={hadleClickSendButton}
      >
        Отправить
      </SendButton>
      <VerticalLineStyled color={"#fff"} />
      <CancelButton
        onMouseEnter={toggleHoverOnDeleteButton}
        onMouseLeave={toggleHoverOnDeleteButton}
        onClick={onCloseAskQuestionModalWindow}
      >
        Отмена
      </CancelButton>
    </AskQuestionButtonsContainer>
  );
};

export default AskQuestionButtons;
