import React, { useCallback } from "react";
import styled from "styled-components";
import { Slide, toast } from "react-toastify";
import AdminPageCopyIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_copy_icon";

const CopyQuestionContainer = styled.div`
  position: absolute;
  bottom: 11px;
  right: 11px;

  ${({ unselected }) =>
    !unselected &&
    `
   cursor: pointer;
   transition: 0.2s;

   &:hover {
     transform: scale(1.12);
  }
`}
`;

const CopyUserQuestion = ({ question, unselected }) => {
  const showNotification = () => {
    toast("Вопрос скопирован!", {
      position: "top-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = async (text) => {
    if (!navigator.clipboard) {
      alert("Копирование не поддерживается вашим браузером.");
      return;
    }

    await navigator.clipboard.writeText(text).then(
      () => showNotification(),
      (error) => {
        console.error("Ошибка копирования: ", error);
      }
    );
  };

  const handleCopyQuestion = useCallback(
    (event) => {
      if (!unselected) {
        event.stopPropagation();
        copyToClipboard(`«${question.question_text}»`);
      }
    },
    [question.question_text, unselected]
  );

  return (
    <CopyQuestionContainer
      unselected={unselected}
      onClick={handleCopyQuestion}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <AdminPageCopyIcon />
    </CopyQuestionContainer>
  );
};

export default CopyUserQuestion;
