import styled from "styled-components";

export const SettingsBlockFixedWrapper = styled.div`
  position: fixed;
  top: 120px;
  z-index: 9991;
  width: 434px;
  border-left: 1px solid #828282;
`;

export const PlusAndMinusButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const PlusOrMinusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 44px;
  border-radius: 8px;
  background: #fff;
  transition: 0.2s;
  cursor: pointer;

  &: hover {
    box-shadow: 2.462px 2.462px 5.808px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const CreateLessonPreviewContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  border: 1px solid transparent;
  border-radius: 25px;
  ${({ isEdite }) =>
    isEdite &&
    `
  padding: 30px 20px;
  border: 1px dashed #5172ea;
  background: linear-gradient(
   180deg,
   rgba(81, 114, 234, 0.1) -18.38%,
   rgba(122, 90, 238, 0.1) 108.82%
 );
  `}
  cursor: pointer;
  transition: 0.2s;
`;

export const CreateLessonSkeletWrapper = styled.div`
  width: 100%;
  margin: ${({ firstElement, lastElement }) =>
    firstElement ? "30px 0" : lastElement ? "20px 0" : "-25px 0 20px"};
  padding: 20px;
  border-radius: 25px;
  border: 1px dashed #5172ea;
  background: #dedcea;
  transition: all 1s;
`;
