import React, { memo, useCallback } from "react";
import { LESSON_COLORS_THEME } from "../../../../../../common/utils/enums";
import ColorSelect from "../../../../../../common/components/color_select";

const textBackgrounds = {
  TRANSPARENT: "transparent",
  ...LESSON_COLORS_THEME["LIGHT"].GRADIENTS,
};

const TextBackgrounds = ({ settingsBlockData, setSettingsBlockData }) => {
  const handleSelectBackground = useCallback((event, background) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      background,
    }));
  }, []);

  return (
    <div>
      <ColorSelect
        selectedColor={settingsBlockData.background}
        handleSelectColor={handleSelectBackground}
        colors={textBackgrounds}
        inComponent={"TEXT"}
      />
    </div>
  );
};

export default memo(TextBackgrounds);
