import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import LevelAndLessonInfo from "./level_and_lesson_info";
import CourseInfo from "./course_info";
import CardShadowWrapper from "../../study_page_components/card_shadow_wrapper";

const CourseCardContainer = styled.div`
  width: 507px;
  height: 360px;
  background: #ffffff;
  box-shadow: 0px 3.01681px 30.1681px rgba(0, 0, 0, 0.15);
  border-radius: 38px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3.01681px 30.1681px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
  }

  @media (max-width: 1150px) {
    width: 455px;
  }

  @media (max-width: 1024px) {
    width: 422px;
    height: 290px;
  }

  @media (max-width: 920px) {
    width: 400px;
    height: 280px;
  }

  @media (max-width: 880px) {
    width: 360px;
    height: 270px;
    border-radius: 31px;
  }

  @media (max-width: 800px) {
    width: 330px;
    height: 245px;
    border-radius: 25px;
  }

  @media (max-width: 744px) {
    width: 300px;
    height: 212px;
    border-radius: 20px;
  }

  @media (max-width: 670px) {
    width: 350px;
    height: 250px;
    border-radius: 25px;
    box-shadow: 0px -3px 9px 0px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 412px) {
    width: 325px;
    height: 238px;
    border-radius: 22px;
  }
`;

const MainInfoContainer = styled.div`
  width: 100%;
  height: 286px;
  border-radius: 37px;
  background-image: ${({ background }) => `url(${background})`};
  background-color: rgb(201 201 201);
  background-size: cover;
  background-repeat: no-repeat;
  filter: drop-shadow(0px -4.52521px 13.5756px rgba(0, 0, 0, 0.15));

  @media (max-width: 1024px) {
    height: 230px;
  }

  @media (max-width: 920px) {
    height: 220px;
  }

  @media (max-width: 880px) {
    height: 210px;
    border-radius: 31px;
  }

  @media (max-width: 800px) {
    height: 195px;
    border-radius: 25px;
  }

  @media (max-width: 744px) {
    height: 168px;
    border-radius: 20px;
  }

  @media (max-width: 670px) {
    height: 200px;
    border-radius: 25px;
  }

  @media (max-width: 412px) {
    height: 190px;
    border-radius: 22px;
  }
`;

const CourseCardItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 0 27px 30px;

  @media (max-width: 1024px) {
    padding: 0 0 20px 25px;
  }

  @media (max-width: 920px) {
    padding: 0 0 18px 22px;
  }

  @media (max-width: 880px) {
    padding: 0 0 16px 20px;
  }

  @media (max-width: 744px) {
    padding: 0 0 14px 15px;
  }

  @media (max-width: 412px) {
    padding: 0 0 15px 15px;
  }
`;

const CourseCard = ({ course, inComponent = "" }) => {
  const navigate = useNavigate();

  const onClickCourseCard = useCallback(
    (event) => {
      event.stopPropagation();

      const link =
        inComponent === "course_settings"
          ? "#"
          : "/study/courses/" + course._id;

      navigate(link);
    },
    [inComponent, course]
  );

  return (
    <CourseCardContainer onClick={onClickCourseCard}>
      <LevelAndLessonInfo blockVariant={"LARGE_SIZE"} courseData={course} />
      <MainInfoContainer background={course.course_preview_image}>
        <CardShadowWrapper>
          <CourseCardItems>
            <CourseInfo courseData={course} />
          </CourseCardItems>
        </CardShadowWrapper>
      </MainInfoContainer>
    </CourseCardContainer>
  );
};

export default memo(CourseCard);
