import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { createPortal } from "react-dom";
import HomePageStartStudy from "./home_page_components/home_page_start_study";
import HomePageDailyVerse from "./home_page_components/home_page_daily_verse";
import HomePageArticlesSection from "./home_page_components/home_page_articles_section";
import ToDonatePage from "./home_page_components/home_page_to_donate_page";
import HomePageAskQuestion from "./home_page_components/home_page_ask_question";
import UserActivationModalWindow from "./home_page_components/user_activation_modal_window";
import AfterActivationModalWindow from "./home_page_components/after_activation_modal_window";
import { useAppContext } from "../../app_context";
import BibleChapterModalWindow from "../../common/components/modal_windows/bible_chapter_modal_window";
import { useUserDataContext } from "../../user_data_context";

const HomePageContainer = styled.div`
  max-width: 1100px;
  padding-top: 40px;
  margin: 0 auto;

  @media (max-width: 744px) {
    width: 393px;
    padding-top: 0px;
  }

  @media (max-width: 440px) {
    width: 100%;
  }
`;

const TopContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  min-height: 300px;

  @media (max-width: 1124px) {
    margin: 0 20px 90px;
  }

  @media (max-width: 744px) {
    flex-direction: column;
    padding: 64px 23px 20px;
    background: #fff;
    border-radius: 0px 0px 25px 25px;
    margin: 0 0 22px;
    min-height: auto;
  }

  ${({ isAuth }) =>
    isAuth &&
    `
      min-height: max-content;

      @media (max-width: 1124px) {
         margin: 0 20px 60px;
      }
   
      @media (max-width: 744px) {
        margin: 0;
      }
   `}
`;

const HomePage = () => {
  const location = useLocation();
  const { appColorTheme } = useAppContext();
  const { userData } = useUserDataContext();

  const [userActivationToken, setUserACtivationToken] = useState("");
  const [openUserActivationModal, setOpenUserActivationModal] = useState(false);
  const [openAfterActivationModal, setOpenAfterActivationModal] =
    useState(false);
  const [inviteUserName, setInviteUserName] = useState("");
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 744);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 744);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const inviteToken = queryParams.get("invite");

    if (inviteToken) {
      setOpenUserActivationModal(true);
      setUserACtivationToken(inviteToken);
    }
  }, [location]);

  return (
    <>
      {/* <ToDonatePage /> */}
      <HomePageContainer>
        <TopContentContainer isAuth={userData._id}>
          {!userData._id ? (
            <HomePageStartStudy />
          ) : (
            !isMobile && <HomePageAskQuestion smallVariant={true} />
          )}
          <HomePageDailyVerse isAuth={userData._id} />
        </TopContentContainer>

        {(!userData._id || isMobile) && (
          <HomePageAskQuestion marginTop={true} />
        )}

        <HomePageArticlesSection
          appColorTheme={appColorTheme}
          setTextBibleModalData={setTextBibleModalData}
        />
      </HomePageContainer>

      {openUserActivationModal && (
        <UserActivationModalWindow
          setOpen={setOpenUserActivationModal}
          setOpenAfterActivationModal={setOpenAfterActivationModal}
          userActivationToken={userActivationToken}
          setInviteUserName={setInviteUserName}
        />
      )}

      {openAfterActivationModal && (
        <AfterActivationModalWindow
          inviteUserName={inviteUserName}
          setOpen={setOpenAfterActivationModal}
        />
      )}

      {textBibleModalData.bibleId &&
        createPortal(
          <BibleChapterModalWindow
            bibleModalData={textBibleModalData}
            setOpen={setTextBibleModalData}
          />,
          document.body
        )}
    </>
  );
};

export default memo(HomePage);
