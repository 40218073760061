import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  BibleIcon,
  CheckIcon,
  QuestionIcon,
  LightIcon,
  PictureIcon,
  ButtonIcon,
  TextIcon,
  YoutubeIcon,
  DialogIcon,
} from "../../../common/assets/icons/create_lesson_icons/create_lesson_sidebar_icons";
import {
  TextSettings,
  BibleVersesSettings,
  NoteAndQuestionSettings,
  QuestionSettings,
  ImageSettings,
  VideoSettings,
  LinkSettings,
  TestSettings,
  DialogueSettings,
} from "../../../pages/admin/admin_page_create_lesson/create_lesson_settings_block/settings_block_renderers";
import {
  TextLessonComponent,
  BibleLessonComponent,
  NoteLessonComponent,
  QuestionLessonComponent,
  ImageLessonComponent,
  VideoLessonComponent,
  DialogueLessonComponent,
  LinkLessonComponent,
  TestLessonComponent,
} from "../../../common/components/lesson/lesson_components/index";
import onBibleChatAvatar from "../../../common/assets/images/on_bible_chat_avatar.png";

export const createComponentId = () => {
  return uuidv4().slice(0, 23);
};

export const onBibleDialogueBotData = {
  _id: "on_bible",
  user_name: "OnBible",
  user_second_name: "",
  user_image: onBibleChatAvatar,
};

export const LESSON_COMPONENTS_MAPPING = {
  TEXT: {
    type: "TEXT",
    icon: TextIcon,
    component: (props) => <TextLessonComponent {...props} />,
    settingsComponent: (props) => <TextSettings {...props} />,
  },
  BIBLE_VERSES: {
    type: "BIBLE_VERSES",
    icon: BibleIcon,
    component: (props) => <BibleLessonComponent {...props} />,
    settingsComponent: (props) => <BibleVersesSettings {...props} />,
  },
  NOTE_AND_QUESTION: {
    type: "NOTE_AND_QUESTION",
    icon: LightIcon,
    component: (props) => <NoteLessonComponent {...props} />,
    settingsComponent: (props) => <NoteAndQuestionSettings {...props} />,
  },
  QUESTION: {
    type: "QUESTION",
    icon: QuestionIcon,
    component: (props) => <QuestionLessonComponent {...props} />,
    settingsComponent: (props) => <QuestionSettings {...props} />,
  },
  IMAGE: {
    type: "IMAGE",
    icon: PictureIcon,
    component: (props) => <ImageLessonComponent {...props} />,
    settingsComponent: (props) => <ImageSettings {...props} />,
  },
  VIDEO: {
    type: "VIDEO",
    icon: YoutubeIcon,
    component: (props) => <VideoLessonComponent {...props} />,
    settingsComponent: (props) => <VideoSettings {...props} />,
  },
  DIALOGUE: {
    type: "DIALOGUE",
    icon: DialogIcon,
    component: (props) => <DialogueLessonComponent {...props} />,
    settingsComponent: (props) => <DialogueSettings {...props} />,
  },
  LINK: {
    type: "LINK",
    icon: ButtonIcon,
    component: (props) => <LinkLessonComponent {...props} />,
    settingsComponent: (props) => <LinkSettings {...props} />,
  },
  TEST: {
    type: "TEST",
    icon: CheckIcon,
    component: (props) => <TestLessonComponent {...props} />,
    settingsComponent: (props) => <TestSettings {...props} />,
  },
};

export const componentsIconsArray = () => {
  const lessonCotentMapInArray = Object.values(LESSON_COMPONENTS_MAPPING);

  return lessonCotentMapInArray.map((item) => {
    return {
      icon: item.icon,
      type: item.type,
    };
  });
};
