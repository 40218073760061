import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ImageDropzone from "../../../../../common/components/image_dropzone";
import ColorSelect from "../../../../../common/components/color_select";
import DisplaySelect from "./display_select";
import Checkbox from "../../../../../common/components/checkbox";
import ArticleTitleInput from "./article_title_input";
import ArticleTextInput from "./article_text_input";
import ArticleSettingsButtons from "./article_settings_buttons";
import { LESSON_COLORS_THEME } from "../../../../../common/utils/enums";

const ArticleSettingsWrapper = styled.div`
  overflow: auto;
  max-height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const ArticleSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 26px 24px;
`;

const ArticleSettings = ({
  articleSettingsData,
  articleSettingsDataMemo,
  openEditQuestionModal,
  openDeleteQuestionModal,
  setOpenDeleteQuestionModal,
  setArticleSettingsDataMemo,
  setArticleSettingsData,
  setOpenEditQuestionModal,
  closeSettings,
  closeSettingsWithoutSave,
}) => {
  const containerRef = useRef(null);
  const [emptyInput, setEmptyInput] = useState(false);

  const isCurrentImage =
    articleSettingsDataMemo?.article_image_url ===
    articleSettingsData?.article_image_url;

  const handlePinArticle = useCallback((event) => {
    event.stopPropagation();
    setArticleSettingsData((prevState) => ({
      ...prevState,
      article_is_pinned: !prevState.article_is_pinned,
    }));
  }, []);

  const hadleArticleSettingsImage = useCallback((imageUrl) => {
    setArticleSettingsData((prevState) => ({
      ...prevState,
      article_image_url: imageUrl,
    }));
  }, []);

  const handleSelectBackground = useCallback((event, background) => {
    event.stopPropagation();
    setArticleSettingsData((prevState) => ({
      ...prevState,
      article_background_color: background,
    }));
  }, []);

  useEffect(() => {
    if (emptyInput && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [emptyInput]);

  return (
    <ArticleSettingsWrapper
      ref={containerRef}
      onClick={(e) => e.stopPropagation()}
    >
      <ArticleSettingsContainer>
        <ArticleTitleInput
          articleSettingsData={articleSettingsData}
          setArticleSettingsData={setArticleSettingsData}
          emptyInput={emptyInput}
        />

        <ArticleTextInput
          articleSettingsData={articleSettingsData}
          setArticleSettingsData={setArticleSettingsData}
          emptyInput={emptyInput}
        />

        <ImageDropzone
          imageUrl={articleSettingsData.article_image_url}
          isCurrentImage={isCurrentImage}
          handleImageSettings={hadleArticleSettingsImage}
          weightLimit={3}
        />

        <ColorSelect
          selectedColor={articleSettingsData.article_background_color}
          handleSelectColor={handleSelectBackground}
          colors={LESSON_COLORS_THEME["LIGHT"].GRADIENTS}
        />

        <DisplaySelect
          displayOption={articleSettingsData.article_is_visible}
          setArticleSettingsData={setArticleSettingsData}
        />

        <Checkbox
          onClickCheckbox={handlePinArticle}
          checked={articleSettingsData.article_is_pinned}
          color={"#1E1E1E"}
          fontSize={"14px"}
          component={"admin_page_home_article_settings"}
        >
          Закрепить публикацию
        </Checkbox>

        <ArticleSettingsButtons
          articleSettingsData={articleSettingsData}
          articleSettingsDataMemo={articleSettingsDataMemo}
          setEmptyInput={setEmptyInput}
          openEditQuestionModal={openEditQuestionModal}
          setArticleSettingsDataMemo={setArticleSettingsDataMemo}
          openDeleteQuestionModal={openDeleteQuestionModal}
          setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          closeSettingsWithoutSave={closeSettingsWithoutSave}
          closeSettings={closeSettings}
        />
      </ArticleSettingsContainer>
    </ArticleSettingsWrapper>
  );
};

export default memo(ArticleSettings);
