import React, { memo, useCallback, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import axios from "../../axios_config";
import { BASE_API_URL } from "../../common/endpoints";
import { createPortal } from "react-dom";
import FinishLessonButtonModal from "./user_lesson_modal_windows/finish_lesson_button_modal";

const FinishLessonButtonWrapper = styled(motion.div)`
  width: max-content;
  margin: 0 auto;
`;

const FinishLessonButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: max-content;
  padding: 0 36px;
  margin: 0 auto 120px;
  border-radius: 15px;
  background: ${({ background }) => background};
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 130%;
  user-select: none;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media (max-width: 720px) {
    font-size: 20px;
    border-radius: 12px;
  }

  @media (max-width: 412px) {
    margin-bottom: 100px;
  }
`;

const validateLessonData = (lessonData) => {
  return !lessonData.some((item) => {
    switch (item.type) {
      case "TEST":
        return !item?.data.some((option) => option.user_answer === true);

      case "QUESTION":
        if (item.answer_type === "input") {
          return !item.answer_input;
        } else if (item.answer_type === "options") {
          return !item.selected_answer;
        }
        return false;

      default:
        return false;
    }
  });
};

const FinishLessonButton = ({
  courseMainGradient,
  userLessonInfo,
  userAnswersData,
  courseData,
  lessonIsFinished,
  filteredComponents,
  setLessonIsFinished,
  setAfterFinishLessonModal,
  fetchUserLessonData,
}) => {
  const [hasUnfilledItems, setHasUnfilledItems] = useState(false);

  const onClickFinishLesson = useCallback(async () => {
    const userLessonAnswers = !lessonIsFinished ? userAnswersData : [];

    const { _id, study_lesson_id, study_user_id } = userLessonInfo;

    const newUserAnswersData = {
      study_answers: userLessonAnswers,
      study_lesson: study_lesson_id,
      study_user: study_user_id,
      study_course: {
        _id: courseData._id,
        course_name: courseData.course_name,
      },
    };

    try {
      await axios.patch(`${BASE_API_URL}/userstudy/${_id}`, newUserAnswersData);
    } catch (error) {
      console.error("Ошибка обновления урока пользователя:", error);
    } finally {
      localStorage.removeItem(userLessonInfo._id);
      setLessonIsFinished((prevState) => !prevState);

      if (!lessonIsFinished) {
        setAfterFinishLessonModal(true);
      }

      if (lessonIsFinished) {
        fetchUserLessonData();
      }
    }
  }, [userLessonInfo, userAnswersData, lessonIsFinished, courseData]);

  const onClickFinishButton = useCallback(() => {
    if (!filteredComponents?.length) return;

    if (validateLessonData(filteredComponents)) {
      onClickFinishLesson();
    } else {
      setHasUnfilledItems(true);
    }
  }, [
    filteredComponents,
    lessonIsFinished,
    userLessonInfo,
    userAnswersData,
    lessonIsFinished,
    courseData,
  ]);

  return (
    <FinishLessonButtonWrapper
      whileTap={{
        scale: 0.9,
        transition: { type: "spring", stiffness: 500, damping: 15 },
      }}
    >
      <FinishLessonButtonContainer
        background={courseMainGradient}
        onClick={onClickFinishButton}
      >
        {!lessonIsFinished ? "Завершить урок" : "Пройти повторно"}
      </FinishLessonButtonContainer>

      {hasUnfilledItems &&
        createPortal(
          <FinishLessonButtonModal
            setOpen={setHasUnfilledItems}
            lessonIsFinished={lessonIsFinished}
            onClickFinishLesson={onClickFinishLesson}
          />,
          document.body
        )}
    </FinishLessonButtonWrapper>
  );
};

export default memo(FinishLessonButton);
