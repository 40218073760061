import React from "react";

const LightShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_8640_8341)">
        <path
          d="M9.08868 3.93552e-05C9.85527 1.64e-05 10.5792 0.303788 11.1377 0.862303C11.7226 1.44716 12.0281 2.21345 11.998 3.02005C11.9669 3.85309 11.5905 4.65331 10.9096 5.33422L9.68366 6.56517C9.50099 6.74862 9.2042 6.74923 9.02076 6.56653C8.83734 6.38386 8.8367 6.08705 9.0194 5.90363L10.246 4.67197C11.24 3.67796 11.3338 2.38424 10.4748 1.52521C9.61574 0.666108 8.32202 0.759928 7.32874 1.7533L5.64389 3.43815C5.13321 3.9488 4.85159 4.53191 4.82949 5.12436C4.80921 5.6674 5.01743 6.18586 5.41577 6.58421C5.54925 6.71766 5.69693 6.83091 5.85473 6.92079C6.07969 7.04892 6.15818 7.33517 6.03005 7.56009C5.90189 7.78505 5.61565 7.86352 5.39074 7.73541C5.16016 7.60406 4.94555 7.43977 4.75284 7.24709C4.16801 6.66225 3.86253 5.89597 3.89262 5.08937C3.92372 4.25633 4.30006 3.45608 4.98096 2.7752L6.66581 1.09035C7.34674 0.409445 8.14694 0.0331329 8.97998 0.00200775C9.01626 0.000719331 9.05256 3.93583e-05 9.08868 3.93552e-05ZM5.9699 4.43994C5.84177 4.66489 5.92029 4.95113 6.14522 5.07924C6.30302 5.16912 6.45072 5.28237 6.58418 5.4158C7.44321 6.27486 7.34941 7.56858 6.35608 8.56191L4.67124 10.2467C3.67789 11.24 2.38419 11.3339 1.52518 10.4748C0.66615 9.61579 0.759947 8.32207 1.75395 7.32806L2.98058 6.0964C3.16327 5.91298 3.16266 5.61617 2.97922 5.4335C2.79579 5.2508 2.49901 5.25146 2.31631 5.43486L1.09034 6.66584C0.40944 7.34674 0.0331274 8.14697 0.00200245 8.97998C-0.0280907 9.78658 0.277393 10.5529 0.862228 11.1377C1.44709 11.7226 2.21377 12.028 3.01997 11.998C3.85301 11.9669 4.65324 11.5905 5.33412 10.9096L7.01899 9.22481C7.69989 8.54391 8.0762 7.74368 8.10733 6.91067C8.13742 6.10407 7.83194 5.33776 7.2471 4.7529C7.05443 4.56024 6.83981 4.39597 6.60921 4.2646C6.53599 4.22291 6.4563 4.20308 6.37765 4.20308C6.21469 4.2031 6.05634 4.28823 5.9699 4.43994Z"
          fill="#725FED"
        />
      </g>
      <defs>
        <clipPath id="clip0_8640_8341">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(12 12) rotate(180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightShareIcon;
