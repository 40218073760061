import React from "react";

const CertificateLineSmallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97"
      height="2"
      viewBox="0 0 97 2"
      fill="none"
    >
      <path
        d="M0.183594 0.944336H96.1237"
        stroke="#373962"
        strokeWidth="0.666251"
      />
    </svg>
  );
};

export default CertificateLineSmallIcon;
