import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { SearchIcon } from "../../../../../common/assets/icons";
import UsersListModalWindowItems from "./users_list_modal_window_items";
import ModalWindowMainButtons from "../modal_window_main_buttons";

const UsersListModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const UsersListModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 390px;
  height: 450px;
  border-radius: 15px;
  background: #fff;
`;

const SearchUsersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px 22px 0;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #cacaca;

  & > input {
    font-size: 16px;
    color: #1e1e1e;
    flex: 1;
    line-height: 24px;
  }
`;

const ButtonsContainer = styled.div`
  padding: 6px 12px 10px;
  width: 100%;
`;

const UsersListModalWindow = ({
  usersListByRole = [],
  openUsersListModal,
  setOpen,
  setUserRolesSettingsData,
}) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [searchUserInput, setSearchUserInput] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const title =
    openUsersListModal === "mentors"
      ? "Найти наставника"
      : openUsersListModal === "students"
      ? "Найти ученика"
      : "Найти подопечного";

  const filterUsers = (searchInput) => {
    const filteredUsers = usersListByRole.filter(
      (user) =>
        user.user_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        user.user_second_name.toLowerCase().includes(searchInput.toLowerCase())
    );

    setFilteredUsers(filteredUsers);
  };

  useEffect(() => {
    filterUsers(searchUserInput);
  }, [searchUserInput]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen("");
  }, []);

  const handleSearchUserInput = useCallback((event) => {
    setSearchUserInput(event.target.value);
  }, []);

  const handleSelectButton = useCallback(
    (user) => {
      if (selectedUser._id) {
        if (openUsersListModal === "mentors") {
          setUserRolesSettingsData((prevState) => ({
            ...prevState,
            user_mentor: { _id: selectedUser._id },
          }));
        } else if (openUsersListModal === "students") {
          setUserRolesSettingsData((prevState) => ({
            ...prevState,
            user_access: {
              ...prevState.user_access,
              mentor_role: {
                ...prevState.user_access.mentor_role,
                user_disciples: [
                  { _id: selectedUser._id },
                  ...prevState.user_access.mentor_role.user_disciples,
                ],
              },
            },
          }));
        } else if (openUsersListModal === "wards") {
          setUserRolesSettingsData((prevState) => ({
            ...prevState,
            user_access: {
              ...prevState.user_access,
              leader_role: {
                ...prevState.user_access.leader_role,
                user_wards: [
                  { _id: selectedUser._id },
                  ...prevState.user_access.leader_role.user_wards,
                ],
              },
            },
          }));
        }
        setOpen("");
      }
    },
    [selectedUser, openUsersListModal]
  );

  return (
    <UsersListModalWindowWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <UsersListModalWindowContainer
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <SearchUsersContainer>
          <SearchIcon />
          <input
            placeholder={title}
            type="text"
            value={searchUserInput}
            onChange={handleSearchUserInput}
          />
        </SearchUsersContainer>

        <UsersListModalWindowItems
          filteredUsers={filteredUsers}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />

        <ButtonsContainer>
          <ModalWindowMainButtons
            handleClose={handleClose}
            onClickConfirmButton={handleSelectButton}
            isDisableConfirmButton={!selectedUser._id}
          />
        </ButtonsContainer>
      </UsersListModalWindowContainer>
    </UsersListModalWindowWrapper>
  );
};

export default memo(UsersListModalWindow);
