import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import UserInformationSection, {
  UserInformationItem,
  UserInformationItemContainer,
} from "./user_information_section";
import UserInfoActionsSection from "./user_info_actions_section";
import UserInfoModalWindowButtons from "./user_info_modal_window_buttons";
import { useUserDataContext } from "../../../../user_data_context";
import UserActivitySection from "../../../../pages/admin/admin_page_users/admin_users_modal_windows/admin_users_info_modal_window/user_activity_section";

const UserInfoModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const ScrollWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 40px;
`;

const UserInfoModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 480px;
  min-height: 200px;
  height: max-content;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
`;

export const UserInfoSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.24px;
`;

const UserInfoModalWindow = ({ selectedUserInfo, setSelectedUserInfo }) => {
  const scrollWrapperRef = useRef(null);
  const { userData } = useUserDataContext();

  const [showDetails, setShowDetails] = useState(false);

  const userHasAccess =
    userData?.user_role === "superadmin" ||
    userData?.user_access?.is_admin_of_platform;

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setSelectedUserInfo({});
  }, []);

  useEffect(() => {
    if (selectedUserInfo._id && scrollWrapperRef.current) {
      scrollWrapperRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [selectedUserInfo, scrollWrapperRef]);

  return (
    <UserInfoModalWindowWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <ScrollWrapper ref={scrollWrapperRef}>
        <UserInfoModalWindowContainer
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <UserInformationSection selectedUserInfo={selectedUserInfo} />

          <UserInfoModalWindowButtons
            userHasAccess={userHasAccess}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />

          {userHasAccess && showDetails && (
            <>
              <UserInformationItemContainer>
                <p>Дополнительная информация</p>
                <UserInformationItem>
                  {selectedUserInfo.user_notes}
                </UserInformationItem>
              </UserInformationItemContainer>
              <UserActivitySection selectedUserInfo={selectedUserInfo} />
              <UserInfoActionsSection
                selectedUserInfo={selectedUserInfo}
                setSelectedUserInfo={setSelectedUserInfo}
              />
            </>
          )}
        </UserInfoModalWindowContainer>
      </ScrollWrapper>
    </UserInfoModalWindowWrapper>
  );
};

export default memo(UserInfoModalWindow);
