import React, { memo } from "react";
import styled from "styled-components";
import DailyVerseSettings from "./daily_verse_settings";
import ArticleSettings from "./article_settings";

const AdminPageHomeSettingsWrapper = styled.div`
  position: relative;
  z-index: 9999;
  width: 430px;
`;

const AdminPageHomeSettingsContainer = styled.div`
  position: fixed;
  width: 430px;
  ${({ borderLeft }) =>
    borderLeft &&
    `
     border-left: 1px solid #828282;
     height: calc(100vh - 80px);
  `}
`;

const AdminPageHomeSettings = ({
  activeSettingsBlock,
  openEditQuestionModal,
  articleSettingsData,
  articleSettingsDataMemo,
  homePageDailyVerse,
  setArticleSettingsData,
  setOpenEditQuestionModal,
  setArticleSettingsDataMemo,
  setHomePageDailyVerse,
  closeSettings,
  closeSettingsWithoutSave,
  openDeleteQuestionModal,
  setOpenDeleteQuestionModal,
}) => {
  return (
    <AdminPageHomeSettingsWrapper>
      <AdminPageHomeSettingsContainer
        borderLeft={!!activeSettingsBlock}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {activeSettingsBlock === "daily_verse_settings" && (
          <DailyVerseSettings
            homePageDailyVerse={homePageDailyVerse}
            setHomePageDailyVerse={setHomePageDailyVerse}
          />
        )}

        {activeSettingsBlock === "article_settings" && (
          <ArticleSettings
            articleSettingsData={articleSettingsData}
            setArticleSettingsData={setArticleSettingsData}
            articleSettingsDataMemo={articleSettingsDataMemo}
            openEditQuestionModal={openEditQuestionModal}
            setOpenEditQuestionModal={setOpenEditQuestionModal}
            setArticleSettingsDataMemo={setArticleSettingsDataMemo}
            closeSettings={closeSettings}
            closeSettingsWithoutSave={closeSettingsWithoutSave}
            openDeleteQuestionModal={openDeleteQuestionModal}
            setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
          />
        )}
      </AdminPageHomeSettingsContainer>
    </AdminPageHomeSettingsWrapper>
  );
};

export default memo(AdminPageHomeSettings);
