import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../../axios_config";
import AskQuestionArrowIcon from "../../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import { BASE_API_URL } from "../../../../../../common/endpoints";
import { onBibleDialogueBotData } from "../../../../../../common/components/lesson/lesson_utils";

const DialogueSettingsBotContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  min-height: 36px;
  overflow: hidden;
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const DialogueSettingsBotHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 36px;
  padding: 0 14px;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #828282;
  font-size: 16px;
  border-bottom: ${({ showOptions }) =>
    showOptions ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const DialogueSettingsBotArrowIconContainer = styled.div`
  padding-top: 3px;
  ${({ showOptions }) => showOptions && "transform: rotate(180deg);"}
`;

const DialogueSettingsBotOptionsContainer = styled.div`
  padding: 4px;
`;

const DialogueSettingsBotOptionItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;

const DialogueSettingsBot = ({
  lessonData,
  settingsBlockData,
  setSettingsBlockData,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [courseData, setCourseData] = useState({});
  const [courseAuthors, setCourseAuthors] = useState([]);
  const [selectedBot, setSelectedBot] = useState({});

  const selectedBotName = selectedBot?.user_name
    ? `${selectedBot?.user_name} ${selectedBot?.user_second_name}`
    : "";

  useEffect(() => {
    if (!settingsBlockData.dialog_bot?._id && courseAuthors.length) {
      setSettingsBlockData((prevState) => ({
        ...prevState,
        dialog_bot: { _id: courseAuthors?.[courseAuthors?.length - 2]?._id },
      }));
    }
  }, [settingsBlockData.dialog_bot?._id, courseAuthors]);

  useEffect(() => {
    if (settingsBlockData.dialog_bot?._id && courseAuthors.length) {
      const selected = courseAuthors.find(
        (author) => author._id === settingsBlockData.dialog_bot._id
      );

      setSelectedBot(selected);
    }
  }, [settingsBlockData.dialog_bot?._id, courseAuthors]);

  const fetchCourse = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/courses/${lessonData.lesson_course._id}`
      );

      setCourseData(response.data);

      if (!response.data.course_authors.length) {
        setSettingsBlockData((prevState) => ({
          ...prevState,
          dialog_bot: { _id: "on_bible" },
        }));
      }
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, [lessonData]);

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchUserInfo = useCallback(async (userId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error("Ошибка при запросе:", error);
      throw error;
    }
  }, []);

  const fetchAuthors = async () => {
    if (courseData?.course_authors?.length) {
      try {
        const authors = await Promise.all(
          courseData.course_authors.map((user) => fetchUserInfo(user._id))
        );
        setCourseAuthors([...authors, onBibleDialogueBotData]);
      } catch (error) {
        console.error("Ошибка при загрузке авторов:", error);
      }
    } else {
      setCourseAuthors([onBibleDialogueBotData]);
    }
  };

  useEffect(() => {
    fetchAuthors();
  }, [courseData]);

  const handleShowOptions = useCallback(() => {
    setShowOptions((prevState) => !prevState);
  }, []);

  const handleSelectOptions = useCallback((botId) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      dialog_bot: { _id: botId },
    }));

    setShowOptions(false);
  }, []);

  return (
    <DialogueSettingsBotContainer>
      <DialogueSettingsBotHeader
        showOptions={showOptions && courseAuthors.length > 1}
        onClick={handleShowOptions}
      >
        <p>{selectedBotName}</p>

        {courseAuthors.length > 1 && (
          <DialogueSettingsBotArrowIconContainer showOptions={showOptions}>
            <AskQuestionArrowIcon />
          </DialogueSettingsBotArrowIconContainer>
        )}
      </DialogueSettingsBotHeader>

      {showOptions && courseAuthors.length > 1 && (
        <DialogueSettingsBotOptionsContainer>
          {courseAuthors.map((bot, index) => (
            <DialogueSettingsBotOptionItem
              key={index}
              onClick={() => handleSelectOptions(bot._id)}
            >
              {bot.user_name} {bot.user_second_name}
            </DialogueSettingsBotOptionItem>
          ))}
        </DialogueSettingsBotOptionsContainer>
      )}
    </DialogueSettingsBotContainer>
  );
};

export default memo(DialogueSettingsBot);
