import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import {
  ErrorMessage,
  InputStyles,
  RequireIcon,
  UserActivationModalWindowInput,
} from "./";
import {
  HidePasswordIcon,
  ShowPasswordIcon,
} from "../../../../common/assets/icons";

const UserActivationPasswordContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

const PasswordIconContainer = styled.div`
  position: absolute;
  top: 13px;
  right: 15px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.12);
  }
`;

const UserActivationPassword = ({
  inputValues,
  confirmPasswordInput,
  handleInputChange,
  handleConfirmPasswordInput,
  errorMessage,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisible = useCallback((event) => {
    event.stopPropagation();
    setShowPassword((prevState) => !prevState);
  }, []);

  const toggleConfirmPasswordVisible = useCallback((event) => {
    event.stopPropagation();
    setShowConfirmPassword((prevState) => !prevState);
  }, []);

  return (
    <UserActivationPasswordContainer>
      {errorMessage === "incorrect_password" && (
        <ErrorMessage>
          Пароль минимум 8 символов (латинские буквы и цифры)
        </ErrorMessage>
      )}

      {errorMessage === "passwords_not_equal" && (
        <ErrorMessage>Пароли не совпадают</ErrorMessage>
      )}

      <UserActivationModalWindowInput>
        <InputStyles
          type={showPassword ? "text" : "password"}
          placeholder="Придумайте пароль"
          name="user_password"
          autoComplete="off"
          value={inputValues?.user_password}
          onChange={handleInputChange}
        />
        <PasswordIconContainer onClick={togglePasswordVisible}>
          {!showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
        </PasswordIconContainer>
        <RequireIcon>*</RequireIcon>
      </UserActivationModalWindowInput>

      <UserActivationModalWindowInput>
        <InputStyles
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Повторите пароль"
          autoComplete="off"
          value={confirmPasswordInput}
          onChange={handleConfirmPasswordInput}
        />
        <PasswordIconContainer onClick={toggleConfirmPasswordVisible}>
          {!showConfirmPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
        </PasswordIconContainer>
        <RequireIcon>*</RequireIcon>
      </UserActivationModalWindowInput>
    </UserActivationPasswordContainer>
  );
};

export default memo(UserActivationPassword);
