import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { APPLICATION_ROUTES } from "../../common/utils/routes";
import { useUserDataContext } from "../../user_data_context";

const UserProfilePageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 30px;
  color: #1e1e1e;
  font-size: 18px;
  text-align: center;

  h5 {
    font-size: 20px;
  }
`;

const LogoutButtonStyles = styled.button`
  width: max-content;
  padding: 0 20px;
  height: 55px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  border-radius: 10px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  user-select: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const UserProfilePage = () => {
  const navigate = useNavigate();
  const { fetchUserData, userData } = useUserDataContext();

  const onClickLogoutButton = () => {
    localStorage.removeItem("access_token");
    fetchUserData();
    navigate(APPLICATION_ROUTES.HOME);
  };

  return (
    <UserProfilePageContainer>
      <h5>
        Профиль пользователя:{" "}
        <b>
          {userData.user_name} {userData.user_second_name || ""}
        </b>
      </h5>

      <p>(Страница в разработке...)</p>
      <LogoutButtonStyles onClick={onClickLogoutButton}>
        Выйти с аккаунта
      </LogoutButtonStyles>
    </UserProfilePageContainer>
  );
};

export default memo(UserProfilePage);
