import React, { memo, useEffect, useState } from "react";
import {
  ButtonGroup,
  SettingsButtonsContainer,
  ToolbarButton,
} from "../editor_styled_components";
import TextSettingsButtonsHeader from "./text_settings_buttons_header";
import styled from "styled-components";
import { LESSON_COLORS_THEME } from "../../../utils/enums";
import ColoredTextIcon from "../../../assets/icons/create_lesson_icons/create_lesson_settings_icons/colored_text_icon";
import UncoloredTextIcon from "../../../assets/icons/create_lesson_icons/create_lesson_settings_icons/uncolored_text_icon";

const ColorsSettingsContainer = styled(SettingsButtonsContainer)`
  width: 314px;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ToolbarButtonStyled = styled(ToolbarButton)`
  padding: 9px 3px 7px 0;
`;

const TextColorButtons = ({
  currentStyle,
  toggleInlineStyle,
  openTextSettings,
  setOpenTextSettings,
  withBackground,
}) => {
  const [activeColor, setActiveColor] = useState("#1E1E1E");

  const colorStyles = LESSON_COLORS_THEME.LIGHT.COLORS;

  useEffect(() => {
    for (const [style, color] of Object.entries(colorStyles)) {
      if (currentStyle.has(`TEXT_${style}`)) {
        setActiveColor(color);
        return;
      }
    }
    setActiveColor("#1E1E1E");
  }, [currentStyle]);

  const handleToggleInlineStyle = (style) => {
    toggleInlineStyle(`TEXT_${style}`);
    setActiveColor(colorStyles[style]);
  };

  return (
    <ButtonGroup>
      <TextSettingsButtonsHeader
        isOpen={openTextSettings === "text_color"}
        setOpen={setOpenTextSettings}
        buttonsGroup={"text_color"}
        disabled={withBackground}
      >
        {activeColor !== "#FFF" ? (
          <ColoredTextIcon color={activeColor} />
        ) : (
          <UncoloredTextIcon />
        )}
      </TextSettingsButtonsHeader>

      {openTextSettings === "text_color" && !withBackground && (
        <ColorsSettingsContainer>
          {Object.entries(colorStyles).map(([style, color]) => {
            return (
              <ToolbarButtonStyled
                key={style}
                active={activeColor === color}
                onClick={() => handleToggleInlineStyle(style)}
              >
                {color !== "#FFF" ? (
                  <ColoredTextIcon color={color} />
                ) : (
                  <UncoloredTextIcon />
                )}
              </ToolbarButtonStyled>
            );
          })}
        </ColorsSettingsContainer>
      )}
    </ButtonGroup>
  );
};

export default memo(TextColorButtons);
