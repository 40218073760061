import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "../../../axios_config";
import CreateLessonHeader from "./create_lesson_header";
import CreateLessonSidebar from "./create_lesson_sidebar";
import CreateLessonSettingsBlock from "./create_lesson_settings_block";
import CreateLessonPreview from "./create_lesson_preview_block";
import { BASE_API_URL } from "../../../common/endpoints";
import { compareObjects } from "../../../common/utils/functions/compare_objects";
import { useUserDataContext } from "../../../user_data_context";
import { LESSON_COLORS_THEME } from "../../../common/utils/enums";

const CreateLessonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 40px;
  overflow: hidden;
  height: 88vh;
`;

const CreateLessonBody = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: auto;
  max-height: max-content;

  ::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;
`;

const CreateLesson = () => {
  const { id } = useParams();
  const createLessonBodyRef = useRef(null);
  const navigate = useNavigate();
  const { userData, authError } = useUserDataContext();

  const [lessonData, setLessonData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [activeSettingsBlock, setActiveSettingsBlock] = useState("");
  const [settingsBlockData, setSettingsBlockData] = useState({});
  const [settingsBlockMemoData, setSettingsBlockMemoData] = useState({});
  const [insertBlockIndex, setInsertBlockIndex] = useState(null);
  const [editBlockIndex, setEditBlockIndex] = useState(null);
  const [openEditeComponentModal, setOpenEditeComponentModal] = useState(false);

  const courseMainGradient =
    LESSON_COLORS_THEME["LIGHT"]?.GRADIENTS?.[courseData.course_main_color];
  const courseMainColor =
    LESSON_COLORS_THEME["LIGHT"]?.COLORS?.[courseData.course_main_color];

  const userId = userData._id;
  const creatorId = courseData.course_creator?._id;
  const authors = courseData.course_authors?.map((author) => author._id);
  const admins = courseData.course_admins?.map((admin) => admin._id);
  const isSuperAdmin = userData.user_role === "superadmin";

  const hasAccess =
    creatorId === userId ||
    authors?.includes(userId) ||
    admins?.includes(userId) ||
    isSuperAdmin;

  useEffect(() => {
    if (courseData?._id) {
      if (!hasAccess) {
        navigate("/bible");
      }
    }

    if (authError) {
      navigate("/bible");
    }
  }, [courseData, userData, lessonData, authError]);

  const fetchCourse = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (lessonData.lesson_course?._id) {
      const courseId = lessonData.lesson_course._id;
      fetchCourse(courseId);
    }
  }, [lessonData.lesson_course?._id]);

  const fetchLessonData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + `/lessons/${id}`);
      setLessonData(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных урока:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLessonData();
    }
  }, [id]);

  const resetAllActiveBlocks = useCallback(() => {
    setEditBlockIndex(null);
    setInsertBlockIndex(null);
    setSettingsBlockData({});
    setSettingsBlockMemoData({});
    setActiveSettingsBlock("");
    setOpenEditeComponentModal(false);
    fetchLessonData();
  }, []);

  const getResetCondition = useCallback(() => {
    switch (activeSettingsBlock) {
      case "TEXT":
        return (
          compareObjects(
            settingsBlockData.contentState?.blocks,
            settingsBlockMemoData.contentState?.blocks
          ) ||
          (!settingsBlockData.contentState?.blocks?.[0]?.text &&
            !settingsBlockMemoData.contentState?.blocks?.[0]?.text)
        );
      case "NOTE_AND_QUESTION":
        return (
          (compareObjects(
            settingsBlockData.note?.blocks,
            settingsBlockMemoData.note?.blocks
          ) ||
            (!settingsBlockData.note?.blocks?.[0]?.text &&
              !settingsBlockMemoData.note?.blocks?.[0]?.text)) &&
          settingsBlockData.question === settingsBlockMemoData.question
        );
      case "DIALOGUE":
        return (
          compareObjects(
            settingsBlockData.data?.first?.text?.blocks,
            settingsBlockMemoData.data?.first?.text?.blocks
          ) ||
          (!settingsBlockData.data?.first?.text?.blocks?.[0]?.text &&
            !settingsBlockMemoData.data?.first?.text?.blocks?.[0]?.text)
        );
      default:
        return compareObjects(settingsBlockMemoData, settingsBlockData);
    }
  }, [activeSettingsBlock, settingsBlockData, settingsBlockMemoData]);

  const onCloseActiveSettings = useCallback(() => {
    const shouldResetAllBlocks = getResetCondition();

    if (shouldResetAllBlocks) {
      resetAllActiveBlocks();
    } else {
      setOpenEditeComponentModal(true);
    }
  }, [settingsBlockData, settingsBlockMemoData, activeSettingsBlock]);

  const handleActiveSettingsBlock = useCallback(
    (event, blockType) => {
      event.stopPropagation();

      const isTextBlockEqual = () => {
        const currentBlocks = settingsBlockData.data?.first?.text?.blocks;
        const memoBlocks = settingsBlockMemoData.data?.first?.text?.blocks;
        return (
          compareObjects(currentBlocks, memoBlocks) ||
          (!currentBlocks?.[0]?.text && !memoBlocks?.[0]?.text)
        );
      };

      const isDialogueBlockEqual = () => {
        const currentBlocks = settingsBlockData?.contentState?.blocks;
        const memoBlocks = settingsBlockMemoData?.contentState?.blocks;
        return (
          (!currentBlocks?.[0]?.text && !memoBlocks?.[0]?.text) ||
          compareObjects(currentBlocks, memoBlocks)
        );
      };

      const isDefaultBlockEqual = () =>
        compareObjects(settingsBlockMemoData, settingsBlockData);

      const shouldOpenModal = () => {
        if (activeSettingsBlock === "TEXT") return !isTextBlockEqual();
        if (activeSettingsBlock === "DIALOGUE") return !isDialogueBlockEqual();
        return !isDefaultBlockEqual();
      };

      if (shouldOpenModal()) {
        setOpenEditeComponentModal(true);
      } else {
        setActiveSettingsBlock(blockType);
        setEditBlockIndex(null);
        setInsertBlockIndex(null);
      }
    },
    [settingsBlockData, settingsBlockMemoData, activeSettingsBlock]
  );

  if (!hasAccess) return;

  return (
    <div onMouseDown={onCloseActiveSettings}>
      <CreateLessonHeader lessonData={lessonData} />

      <CreateLessonContainer>
        <CreateLessonSidebar
          handleActiveSettingsBlock={handleActiveSettingsBlock}
          createLessonBodyRef={createLessonBodyRef}
        />

        <CreateLessonBody ref={createLessonBodyRef}>
          <CreateLessonPreview
            lessonData={lessonData}
            settingsBlockData={settingsBlockData}
            settingsBlockMemoData={settingsBlockMemoData}
            activeSettingsBlock={activeSettingsBlock}
            insertBlockIndex={insertBlockIndex}
            editBlockIndex={editBlockIndex}
            setInsertBlockIndex={setInsertBlockIndex}
            setSettingsBlockData={setSettingsBlockData}
            setSettingsBlockMemoData={setSettingsBlockMemoData}
            setEditBlockIndex={setEditBlockIndex}
            setActiveSettingsBlock={setActiveSettingsBlock}
            setOpenEditeComponentModal={setOpenEditeComponentModal}
            resetAllActiveBlocks={resetAllActiveBlocks}
            fetchLessonData={fetchLessonData}
            courseMainColor={courseMainColor}
            courseMainGradient={courseMainGradient}
          />

          <CreateLessonSettingsBlock
            lessonData={lessonData}
            settingsBlockData={settingsBlockData}
            settingsBlockMemoData={settingsBlockMemoData}
            editBlockIndex={editBlockIndex}
            activeSettingsBlock={activeSettingsBlock}
            insertBlockIndex={insertBlockIndex}
            openEditeComponentModal={openEditeComponentModal}
            setSettingsBlockData={setSettingsBlockData}
            setSettingsBlockMemoData={setSettingsBlockMemoData}
            setActiveSettingsBlock={setActiveSettingsBlock}
            setOpenEditeComponentModal={setOpenEditeComponentModal}
            resetAllActiveBlocks={resetAllActiveBlocks}
          />
        </CreateLessonBody>
      </CreateLessonContainer>
    </div>
  );
};

export default memo(CreateLesson);
