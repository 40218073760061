import React, { memo } from "react";
import styled from "styled-components";
import LessonBibleVerse from "./lesson_bible_verse";

const BibleLessonComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  border-radius: 25px;
  ${({ isGroup }) =>
    isGroup &&
    `padding: 18px 0px 18px 20px;
  border-left: 3px solid #f8d254;`}
`;

const BibleLessonComponent = ({
  componentData,
  inComponent = "",
  setTextBibleModalData = () => {},
  colorTheme = "LIGHT",
}) => {
  return (
    <BibleLessonComponentContainer isGroup={componentData.is_group}>
      {componentData?.data?.map((verse, index) => {
        return (
          <LessonBibleVerse
            key={verse.verseAddress + index + 222}
            verse={verse}
            color={componentData.color}
            setTextBibleModalData={setTextBibleModalData}
            inComponent={inComponent}
            colorTheme={colorTheme}
          />
        );
      })}
    </BibleLessonComponentContainer>
  );
};

export default memo(BibleLessonComponent);
