import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import AskQuestionArrowIcon from "../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";

const SANCTION_PERIODS = [
  { days_count: 1, name: "1 день" },
  { days_count: 2, name: "2 дня" },
  { days_count: 3, name: "3 дня" },
  { days_count: 7, name: "7 дней" },
  { days_count: 14, name: "14 дней" },
  { days_count: 30, name: "30 дней" },
  { days_count: 9999, name: "Навсегда" },
];

const SanctionPeriodWrapper = styled.div`
  color: #1e1e1e;
  font-size: 10px;
  line-height: normal;
`;

const SanctionPeriodContainer = styled.div`
  margin-top: 8px;
  background: #fff;
  border-radius: 8px;
  min-height: 30px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #5172ea;
  transition: 0.2s;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
  }
`;

const SanctionPeriodHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 0 14px;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #828282;
  font-size: 16px;
  border-bottom: ${({ showOptions }) =>
    showOptions ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const SanctionPeriodArrowIcon = styled.div`
  padding-top: 3px;
  ${({ showOptions }) => showOptions && "transform: rotate(180deg);"}
`;

const SanctionPeriodOptions = styled.div`
  padding: 4px;
`;

const SanctionPeriodOptionItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;

const SelectSanctionPeriod = ({
  selectedPeriod,
  setSelectedPeriod,
  sanctionType,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const sanctionName =
    sanctionType === "write_to_mentor"
      ? "Общаться с наставником"
      : sanctionType === "write_to_chat"
      ? "Писать в чаты"
      : "Общаться с поддержкой";

  const selectedPeriodName = SANCTION_PERIODS.find(
    (period) => period.days_count === selectedPeriod
  ).name;

  const handleShowOptions = useCallback(() => {
    setShowOptions((prevState) => !prevState);
  }, []);

  const handleSelectOption = useCallback((period) => {
    setSelectedPeriod(period);
    setShowOptions(false);
  }, []);

  return (
    <SanctionPeriodWrapper>
      Длительность ограничения «{sanctionName}»*
      <SanctionPeriodContainer>
        <SanctionPeriodHeader
          showOptions={showOptions}
          onClick={handleShowOptions}
        >
          {selectedPeriodName}
          <SanctionPeriodArrowIcon showOptions={showOptions}>
            <AskQuestionArrowIcon />
          </SanctionPeriodArrowIcon>
        </SanctionPeriodHeader>

        {showOptions && (
          <SanctionPeriodOptions>
            {SANCTION_PERIODS.map((period) => {
              return (
                <SanctionPeriodOptionItem
                  key={period.name}
                  onClick={() => handleSelectOption(period.days_count)}
                >
                  {period.name}
                </SanctionPeriodOptionItem>
              );
            })}
          </SanctionPeriodOptions>
        )}
      </SanctionPeriodContainer>
    </SanctionPeriodWrapper>
  );
};

export default memo(SelectSanctionPeriod);
