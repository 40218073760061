import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Article from "../../../../common/components/article";
import Masonry from "react-responsive-masonry";
import SpinnerLoader from "../../../../common/components/loaders/spinner_loader";
import { compareObjects } from "../../../../common/utils/functions/compare_objects";

const HomePreviewArticlesContainer = styled.div``;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  alingn-items: center;
  padding-top: 40px;
`;

const HomePreviewAricles = ({
  homePageArticles,
  articleSettingsData,
  editedArticleId,
  articleSettingsDataMemo,
  loadingArticles,
  setActiveSettingsBlock,
  setArticleSettingsData,
  setEditedArticleId,
  setArticleSettingsDataMemo,
  setOpenEditQuestionModal,
  setTextBibleModalData,
  appColorTheme,
}) => {
  const [sortedArticles, setSortedArticles] = useState([]);

  useEffect(() => {
    if (homePageArticles.length) {
      const sortedArticles = [...homePageArticles]
        .sort((a, b) => {
          return (
            new Date(b.article_created_at) - new Date(a.article_created_at)
          );
        })
        .sort((a, b) => {
          return b.article_is_pinned - a.article_is_pinned;
        });

      setSortedArticles(sortedArticles);
    }
  }, [homePageArticles]);

  const onClickArticle = useCallback(
    (event, article) => {
      event.stopPropagation();
      if (compareObjects(articleSettingsDataMemo, articleSettingsData)) {
        if (articleSettingsData._id === article._id) {
          setActiveSettingsBlock("");
          setArticleSettingsData({});
          setEditedArticleId(null);
          setArticleSettingsDataMemo({});
          setOpenEditQuestionModal(false);
        } else {
          setActiveSettingsBlock("article_settings");
          setArticleSettingsData(article);
          setEditedArticleId(article._id);
          setArticleSettingsDataMemo(article);
        }
      } else {
        setOpenEditQuestionModal(true);
      }
    },
    [articleSettingsDataMemo, articleSettingsData]
  );

  return (
    <HomePreviewArticlesContainer>
      {loadingArticles ? (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      ) : (
        <Masonry columnsCount={2} gutter="10px">
          {sortedArticles.map((article) => {
            return (
              <Article
                key={article._id}
                article={article}
                inComponent={"admin_page"}
                editedArticleId={editedArticleId}
                onClick={onClickArticle}
                setTextBibleModalData={setTextBibleModalData}
                appColorTheme={appColorTheme}
              />
            );
          })}
        </Masonry>
      )}
    </HomePreviewArticlesContainer>
  );
};

export default memo(HomePreviewAricles);
