import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import QuestionsFilterByStatus from "./questions_filter_by_status";
import QuestionsFilterByRecipient from "./questions_filter_by_recipient";
import QuestionsFilterByBible from "./questions_filter_by_bible";
import QuestionsSelectedFilters from "./questions_selected_filters";
import QuestionsFilterBySearchWords from "./questions_filter_by_search_words";
import QuestionsFilterByDate from "./questions_filter_by_date";
import { useUserDataContext } from "../../../../../user_data_context";

const QuestionsFiltersContainer = styled.div`
  width: 635px;
`;

const QuestionsFiltersSettings = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  margin-bottom: 8px;
`;

const FiltersSettingsWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

const QuestionsFiltersWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const QuestionsQuestionsFilter = ({
  questionsFilterSettings,
  setQuestionsFilterSettings,
}) => {
  const { accessRights } = useUserDataContext();
  const [extendedOption, setExtendedOption] = useState(""); // status, recipient, date, bible

  const hadleExtendOptions = useCallback(
    (event, optionName) => {
      event.stopPropagation();

      if (extendedOption === optionName) {
        setExtendedOption("");
      } else {
        setExtendedOption(optionName);
      }
    },
    [extendedOption]
  );

  const removeSelectedFilterOption = useCallback((option, itemIndex) => {
    setQuestionsFilterSettings((prevState) => {
      const isArrayFilter = [
        "question_bible",
        "search_words",
        "question_date_range",
      ].includes(option);

      return {
        ...prevState,
        [option]: isArrayFilter
          ? prevState[option].filter((_, index) => index !== itemIndex)
          : "",
      };
    });
  }, []);

  return (
    <QuestionsFiltersContainer>
      <QuestionsFiltersSettings onMouseDown={(e) => e.stopPropagation()}>
        <FiltersSettingsWrapper>
          <QuestionsFilterByStatus
            extendedOption={extendedOption}
            hadleExtendOptions={hadleExtendOptions}
            setQuestionsFilterSettings={setQuestionsFilterSettings}
          />

          {(accessRights.isAdminOfPlatformQuestions ||
            accessRights.isSuperadmin) && (
            <QuestionsFilterByRecipient
              extendedOption={extendedOption}
              hadleExtendOptions={hadleExtendOptions}
              setQuestionsFilterSettings={setQuestionsFilterSettings}
            />
          )}

          <QuestionsFilterByDate
            extendedOption={extendedOption}
            hadleExtendOptions={hadleExtendOptions}
            setQuestionsFilterSettings={setQuestionsFilterSettings}
          />

          <QuestionsFilterByBible
            extendedOption={extendedOption}
            hadleExtendOptions={hadleExtendOptions}
            setQuestionsFilterSettings={setQuestionsFilterSettings}
          />
        </FiltersSettingsWrapper>
        <QuestionsFilterBySearchWords
          setQuestionsFilterSettings={setQuestionsFilterSettings}
        />

        {!!extendedOption && (
          <QuestionsFiltersWrapper onClick={() => setExtendedOption("")} />
        )}
      </QuestionsFiltersSettings>

      <QuestionsSelectedFilters
        questionsFilterSettings={questionsFilterSettings}
        removeSelectedFilterOption={removeSelectedFilterOption}
        accessRights={accessRights}
      />
    </QuestionsFiltersContainer>
  );
};

export default memo(QuestionsQuestionsFilter);
