import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { APPLICATION_ROUTES } from "../../common/utils/routes";
import { AuthPageProvider, useAuthPageContext } from "./auth_page_context";
import AuthCodePage from "./auth_code_page";
import { useUserDataContext } from "../../user_data_context";

const AuthPageMessage = styled.div`
  width: 510px;
  padding-top: 66px;
  margin: 0 auto 60px;
  color: #725fed;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 129%;
  user-select: none;

  span {
    display: block;
  }

  @media (max-width: 510px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 480px) {
    span {
      display: inline;
    }
  }
`;

const AuthPageButtons = styled.div`
  width: 402px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 10px;

  @media (max-width: 420px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const AuthPageButton = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 44px;
  border-radius: 10px;
  border: 2px solid #5172ea;
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
  transition: 0.2s;

  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  letter-spacing: 0.24px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &.active {
    opacity: 1;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const AuthFormContainer = styled.div`
  width: 402px;
  height: max-content;
  margin: 32px auto 0;

  @media (max-width: 420px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
`;

export const pulseAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

export const Dot = styled.span`
  margin: 0 2px;
  padding-bottom: 4px;
  animation: ${pulseAnimation} 1s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const AuthPageHOC = (WrappedComponent) => (props) => {
  return (
    <AuthPageProvider>
      <WrappedComponent {...props} />
    </AuthPageProvider>
  );
};

const AuthPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useUserDataContext();
  const { showAuthCodePage, setRedirectPath } = useAuthPageContext();

  const [message, setMessage] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setRedirectPath(searchParams.get("redirect") || APPLICATION_ROUTES.HOME);
  }, []);

  useEffect(() => {
    if (location.pathname === APPLICATION_ROUTES.AUTH) {
      navigate(APPLICATION_ROUTES.LOGIN, { replace: true });
    }

    if (location.pathname === APPLICATION_ROUTES.LOGIN) {
      setMessage("login");
    } else if (location.pathname === APPLICATION_ROUTES.REGISTRATION) {
      setMessage("registration");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userData._id) {
      navigate(APPLICATION_ROUTES.PROFILE);
    }
  }, [userData._id]);

  return (
    <div style={{ paddingBottom: "50px" }}>
      {!showAuthCodePage ? (
        <>
          {message === "login" ? (
            <AuthPageMessage>
              Войдите, чтобы получить доступ <span /> ко всем функциям OnBible
            </AuthPageMessage>
          ) : (
            <AuthPageMessage>
              Зарегистрируйтесь, чтобы получить доступ ко всем функциям OnBible
            </AuthPageMessage>
          )}

          <AuthPageButtons>
            <AuthPageButton to={APPLICATION_ROUTES.LOGIN}>Вход</AuthPageButton>
            <AuthPageButton to={APPLICATION_ROUTES.REGISTRATION}>
              Регистрация
            </AuthPageButton>
          </AuthPageButtons>

          <AuthFormContainer>
            <Outlet />
          </AuthFormContainer>
        </>
      ) : (
        <AuthCodePage />
      )}
    </div>
  );
};

export default AuthPageHOC(AuthPage);
