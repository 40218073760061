import React, { useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../common/assets/icons/vertical_line";

const FinishLessonButtonModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const FinishLessonButtonModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 475px;
  padding: 36px 40px 40px;
  border-radius: 25px;
  background: #fff;
  user-select: none;
  text-align: center;

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 0;
  }

  & > h5 {
    color: #000;
    font-size: 28px;
    line-height: 130%;
    font-weight: 600;
  }

  & > h6 {
    color: #000;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
    width: 51%;
  }
`;

const FinishButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnFinish }) =>
    isHoverOnFinish ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
  margin-top: 4px;
`;

const FinishLessonButtonModal = ({
  setOpen,
  lessonIsFinished,
  onClickFinishLesson,
}) => {
  const [isHoverOnFinish, setIsHoverOnFinish] = useState(false);

  const toggleHoverOnFinishButton = useCallback(() => {
    setIsHoverOnFinish((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setOpen(false);
  }, []);

  const onFinishLesson = useCallback((e) => {
    e.stopPropagation();
    onClickFinishLesson();
    setOpen(false);
  }, []);

  return (
    <FinishLessonButtonModalWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <FinishLessonButtonModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h5>
          {!lessonIsFinished
            ? "В уроке остались незаполненные элементы!"
            : "Предыдущие ответы в уроке будут удалены!"}
        </h5>

        <h6>
          {!lessonIsFinished
            ? "Хотите продолжить заполнение?"
            : "Хотите пройти повторно?"}
        </h6>
        <ModalButtonsContainer>
          <FinishButton
            onMouseEnter={toggleHoverOnFinishButton}
            onMouseLeave={toggleHoverOnFinishButton}
            onClick={onFinishLesson}
          >
            {!lessonIsFinished ? "Завершить" : "Пройти повторно"}
          </FinishButton>
          <VerticalLineStyled color={"#5172EA"} />
          <CancelButton isHoverOnFinish={isHoverOnFinish} onClick={handleClose}>
            {!lessonIsFinished ? "Заполнить" : "Отмена"}
          </CancelButton>
        </ModalButtonsContainer>
      </FinishLessonButtonModalContainer>
    </FinishLessonButtonModalWrapper>
  );
};

export default FinishLessonButtonModal;
