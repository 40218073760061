import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ColorSelect from "../../../../../common/components/color_select";
import CreateLessonSaveButton from "../../create_lesson_components/create_lesson_save_button";
import { SettingsBlockFixedWrapper } from "../../create_lesson_components/create_lesson_styled_components";
import { LESSON_COLORS_THEME } from "../../../../../common/utils/enums";
import { createComponentId } from "../../../../../common/components/lesson/lesson_utils";

const LinkSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 219px;
  gap: 30px;
  padding: 26px 24px;
`;

const LinkSettingsUrlInput = styled.input`
  padding: 0 15px;
  height: 40px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  :focus,
  :hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const LinkSettingsTextInput = styled.textarea`
  resize: none;
  max-height: 85px;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  :focus,
  :hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const LinkSettingsBackground = styled.div``;

const DEFAULT_SETTINGS_DATA_LINK = {
  type: "LINK",
  link_url: "",
  link_text: "",
  background: "",
  id: "",
};

const LinkSettings = ({
  settingsBlockData,
  editBlockIndex,
  setSettingsBlockData,
  setSettingsBlockMemoData,
  onClickSaveButton,
}) => {
  const textInputRef = useRef(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const linkUrlInput = settingsBlockData.link_url;
  const linkTextInput = settingsBlockData.link_text;
  const linkBackground = settingsBlockData.background;

  useEffect(() => {
    if (editBlockIndex === null) {
      const lastColor = localStorage.getItem("lastColor");

      const defaultLinkData = {
        ...DEFAULT_SETTINGS_DATA_LINK,
        background:
          lastColor && lastColor !== "TRANSPARENT" ? lastColor : "BLUE",
        id: createComponentId(),
      };

      setSettingsBlockData(defaultLinkData);
      setSettingsBlockMemoData(defaultLinkData);
    }
  }, [editBlockIndex]);

  useEffect(() => {
    if (textInputRef.current && linkTextInput) {
      textInputRef.current.style.height = "auto";
      textInputRef.current.style.height =
        textInputRef.current.scrollHeight + "px";
    }
  }, [textInputRef, linkTextInput]);

  useEffect(() => {
    if (linkTextInput && linkUrlInput) {
      setDisabledSaveButton(false);
    } else setDisabledSaveButton(true);
  }, [linkTextInput, linkUrlInput]);

  const handleLinkInput = useCallback((event, param) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      [param]: event.target.value,
    }));
  }, []);

  const handleSelectBackground = useCallback((event, background) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      background,
    }));
  }, []);

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <LinkSettingsContainer>
        <LinkSettingsUrlInput
          placeholder="Вставьте ссылку"
          type="url"
          value={linkUrlInput || ""}
          onChange={(e) => handleLinkInput(e, "link_url")}
        />

        <LinkSettingsTextInput
          ref={textInputRef}
          rows={1}
          placeholder="Напишите текст"
          type="text"
          value={linkTextInput || ""}
          onChange={(e) => handleLinkInput(e, "link_text")}
        />

        <LinkSettingsBackground>
          <ColorSelect
            selectedColor={linkBackground}
            handleSelectColor={handleSelectBackground}
            colors={LESSON_COLORS_THEME["LIGHT"].GRADIENTS}
          />
        </LinkSettingsBackground>

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={disabledSaveButton}
        />
      </LinkSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(LinkSettings);
