import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { useUserDataContext } from "../../../user_data_context";
import { useNavigate } from "react-router-dom";

const AdminPageGroupsWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const AdminPageGroupsContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;

  padding: 40px;
`;

const AdminPageGroups = () => {
  const navigate = useNavigate();

  const { userData, accessRights } = useUserDataContext();

  const hasAccess =
    accessRights.isSuperadmin ||
    accessRights.isAdminOfPlatformGroups ||
    accessRights.isAdminOfGroups ||
    accessRights.isAuthor;

  useEffect(() => {
    if (userData._id && !hasAccess) {
      navigate("/bible");
    }

    if (userData._id && hasAccess) {
      localStorage.setItem("lastAdminUrl", location.pathname);
    }
  }, [accessRights, userData]);

  if (!hasAccess) return;

  return (
    <AdminPageGroupsWrapper>
      <AdminPageGroupsContainer>GROUPS ADMIN</AdminPageGroupsContainer>
    </AdminPageGroupsWrapper>
  );
};

export default memo(AdminPageGroups);
