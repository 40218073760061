import React from "react";
import styled from "styled-components";

const LoginButtonStyles = styled.button`
  width: 100%;
  height: 55px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  border-radius: 10px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  user-select: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.6;
      cursor: default;

         &:hover {
         box-shadow: none;
      }
  `}
`;

const LoginButton = ({ onClick, children, disabled = false }) => (
  <LoginButtonStyles disabled={disabled} onClick={onClick}>
    {children}
  </LoginButtonStyles>
);

export default LoginButton;
