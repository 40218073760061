import React from "react";

const DarkThemeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_8640_8344)">
        <path
          d="M10.9453 1.40625C11.1395 1.40625 11.2969 1.24885 11.2969 1.05469C11.2969 0.860525 11.1395 0.703125 10.9453 0.703125C10.7511 0.703125 10.5938 0.860525 10.5938 1.05469C10.5938 1.24885 10.7511 1.40625 10.9453 1.40625Z"
          fill="#F8D254"
        />
        <path
          d="M6.02344 12C8.64841 12 10.88 10.2841 11.6817 7.91353C11.7318 7.76543 11.678 7.60207 11.5498 7.51261C11.4216 7.42315 11.2498 7.42908 11.128 7.52719C10.37 8.13804 9.45588 8.46094 8.48438 8.46094C6.14522 8.46094 4.24219 6.56841 4.24219 4.24219C4.24219 2.8038 5.00824 1.42755 6.24141 0.650555C6.37366 0.567211 6.435 0.40657 6.39194 0.256289C6.34971 0.108891 6.21701 0.00621094 6.06443 0.00171094C6.05609 0.00107812 6.04125 0 6.02344 0C2.7217 0 0 2.6943 0 6C0 9.3045 2.72034 12 6.02344 12ZM4.93509 0.814523C4.03472 1.74862 3.53906 2.9798 3.53906 4.24219C3.53906 6.95611 5.75752 9.16406 8.48438 9.16406C9.19835 9.16406 9.90333 9.00614 10.5467 8.70893C9.60717 10.2914 7.90188 11.2969 6.02344 11.2969C3.08981 11.2969 0.703125 8.92071 0.703125 6C0.703125 3.45045 2.52152 1.31587 4.93509 0.814523Z"
          fill="#F8D254"
        />
        <path
          d="M6.72656 2.8125C7.29825 2.8125 7.78125 3.30621 7.78125 3.89062C7.78125 4.08478 7.93866 4.24219 8.13281 4.24219C8.32697 4.24219 8.48438 4.08478 8.48438 3.89062C8.48438 3.30621 8.96735 2.8125 9.53906 2.8125C9.73322 2.8125 9.89062 2.65509 9.89062 2.46094C9.89062 2.26678 9.73322 2.10938 9.53906 2.10938C8.95751 2.10938 8.48438 1.63624 8.48438 1.05469C8.48438 0.860531 8.32697 0.703125 8.13281 0.703125C7.93866 0.703125 7.78125 0.860531 7.78125 1.05469C7.78125 1.63624 7.30812 2.10938 6.72656 2.10938C6.53241 2.10938 6.375 2.26678 6.375 2.46094C6.375 2.65509 6.53241 2.8125 6.72656 2.8125ZM8.13281 2.10816C8.23432 2.2433 8.35481 2.36341 8.4903 2.46448C8.35477 2.56781 8.23566 2.68873 8.13281 2.82729C8.02999 2.68875 7.91091 2.56786 7.77532 2.46448C7.91084 2.36341 8.0313 2.2433 8.13281 2.10816Z"
          fill="#F8D254"
        />
        <path
          d="M11.6484 4.24219C11.8426 4.24219 12 4.08479 12 3.89062C12 3.69646 11.8426 3.53906 11.6484 3.53906C11.4543 3.53906 11.2969 3.69646 11.2969 3.89062C11.2969 4.08479 11.4543 4.24219 11.6484 4.24219Z"
          fill="#F8D254"
        />
      </g>
      <defs>
        <clipPath id="clip0_8640_8344">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DarkThemeIcon;
