import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import BibleNavigationPanel from "./bible_navigation_panel";
import BibleBook from "./bible_page_book";
import BiblePageSidebar from "./bible_page_sidebar";
import BibleSearch from "./bible_page_search";
import BibleCompare from "./bible_page_compare";
import { ToastNotification } from "../../common/components/toast_notification";
import { BIBLE_PAGE_DEFAULT_URL } from "./bible_page_data";
import { BiblePageProvider } from "./bible_page_context";

const BiblePageWrapper = styled.div`
  position: relative;
`;

const BiblePageContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  height: calc(100vh - 80px - 30px);
  margin-top: 30px;
  padding: 0 30px;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: calc(100vh - 98px);
    margin-top: 0px;
    padding: 30px 30px 12px 30px;
  }
`;

const BiblePageHOC = (WrappedComponent) => (props) => {
  return (
    <BiblePageProvider>
      <WrappedComponent {...props} />
    </BiblePageProvider>
  );
};

const BiblePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bibleId = searchParams.get("bibleId");

  const [bibleTranslations, setBibleTranslations] = useState(null);
  const [currentTranslationFirst, setCurrentTranslationFirst] = useState(null);
  const [currentTranslationSecond, setCurrentTranslationSecond] =
    useState(null);
  const [activeMainSection, setActiveMainSection] = useState("bible_chapter"); // bible_chapter, bible_search, bible_compare
  const [selectedVerses, setSelectedVerses] = useState({});
  const [bibleSearchInput, setBibleSearchInput] = useState("");
  const [bibleSearchPlace, setBibleSearchPlace] = useState(""); // "" (whole bible), ot, nt
  const [bibleSearchResults, setBibleSearchResults] = useState({});
  const [selectedSearchVerse, setSelectedSearchVerse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const favoriteTranslations = JSON.parse(
      localStorage.getItem("favoriteTranslations") || "[]"
    );

    if (favoriteTranslations.length && bibleTranslations?.length) {
      setBibleTranslations((prevTranslations) =>
        prevTranslations.map((translation) => ({
          ...translation,
          isFavorite: favoriteTranslations.includes(translation.bible_code),
        }))
      );
    }
  }, [bibleTranslations?.length]);

  const lastUrl = localStorage.getItem("lastBibleUrl");

  useEffect(() => {
    if (lastUrl && !bibleId) {
      navigate(lastUrl);
      setActiveMainSection("bible_chapter");
      setBibleSearchResults({});
      setBibleSearchInput("");
    }
  }, [lastUrl, bibleId]);

  useEffect(() => {
    if (bibleId) {
      localStorage.setItem("lastBibleUrl", location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (!bibleId && !lastUrl) {
      navigate(BIBLE_PAGE_DEFAULT_URL);
      setActiveMainSection("bible_chapter");
      setBibleSearchResults({});
      setBibleSearchInput("");
    }
  }, [bibleId]);

  useEffect(() => {
    if (searchParams.get("2bibleId")) {
      const secondTranslate = bibleTranslations?.filter((translation) => {
        return translation.bible_code === searchParams.get("2bibleId");
      })[0];
      setCurrentTranslationSecond(secondTranslate);
    } else {
      setCurrentTranslationSecond(null);
    }
  }, [bibleTranslations, searchParams.get("2bibleId")]);

  return (
    <BiblePageWrapper>
      {(activeMainSection === "bible_chapter" ||
        activeMainSection === "bible_search") && (
        <BiblePageSidebar
          bibleTranslations={bibleTranslations}
          activeMainSection={activeMainSection}
          selectedVerses={selectedVerses}
          selectedSearchVerse={selectedSearchVerse}
          setActiveMainSection={setActiveMainSection}
          setSelectedVerses={setSelectedVerses}
        />
      )}

      <BiblePageContainer>
        {activeMainSection === "bible_chapter" && (
          <BibleBook
            selectedVerses={selectedVerses}
            setSelectedVerses={setSelectedVerses}
            currentTranslationFirst={currentTranslationFirst}
            currentTranslationSecond={currentTranslationSecond}
          />
        )}

        {activeMainSection === "bible_search" && (
          <BibleSearch
            isLoading={isLoading}
            bibleSearchResults={bibleSearchResults}
            selectedSearchVerse={selectedSearchVerse}
            currentTranslation={currentTranslationFirst}
            setBibleSearchResults={setBibleSearchResults}
            setBibleSearchInput={setBibleSearchInput}
            setSelectedSearchVerse={setSelectedSearchVerse}
            setActiveMainSection={setActiveMainSection}
          />
        )}

        {activeMainSection === "bible_compare" && (
          <BibleCompare
            selectedVerses={selectedVerses}
            bibleTranslations={bibleTranslations}
            isLoading={isLoading}
            setActiveMainSection={setActiveMainSection}
            setBibleTranslations={setBibleTranslations}
            setIsLoading={setIsLoading}
          />
        )}

        {activeMainSection !== "bible_compare" && (
          <BibleNavigationPanel
            activeMainSection={activeMainSection}
            currentTranslationFirst={currentTranslationFirst}
            currentTranslationSecond={currentTranslationSecond}
            bibleSearchPlace={bibleSearchPlace}
            bibleTranslations={bibleTranslations}
            bibleSearchInput={bibleSearchInput}
            setBibleTranslations={setBibleTranslations}
            setCurrentTranslationFirst={setCurrentTranslationFirst}
            setCurrentTranslationSecond={setCurrentTranslationSecond}
            setActiveMainSection={setActiveMainSection}
            setBibleSearchPlace={setBibleSearchPlace}
            setBibleSearchResults={setBibleSearchResults}
            setBibleSearchInput={setBibleSearchInput}
            setIsLoading={setIsLoading}
          />
        )}
      </BiblePageContainer>
      <ToastNotification />
    </BiblePageWrapper>
  );
};

export default BiblePageHOC(BiblePage);
