import React from "react";

const DarkNotAnimatedModeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.30371 1.99991C1.30371 1.61527 1.61552 1.30347 2.00015 1.30347H12.0002C12.3848 1.30347 12.6966 1.61527 12.6966 1.99991C12.6966 2.38454 12.3848 2.69635 12.0002 2.69635H2.00015C1.61552 2.69635 1.30371 2.38454 1.30371 1.99991Z"
        fill="white"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.30371 4.99991C1.30371 4.61527 1.61552 4.30347 2.00015 4.30347L12.0002 4.30347C12.3848 4.30347 12.6966 4.61527 12.6966 4.99991C12.6966 5.38454 12.3848 5.69635 12.0002 5.69635L2.00015 5.69635C1.61552 5.69635 1.30371 5.38454 1.30371 4.99991Z"
        fill="white"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.30371 7.99991C1.30371 7.61527 1.61552 7.30347 2.00015 7.30347H12.0002C12.3848 7.30347 12.6966 7.61527 12.6966 7.99991C12.6966 8.38454 12.3848 8.69635 12.0002 8.69635H2.00015C1.61552 8.69635 1.30371 8.38454 1.30371 7.99991Z"
        fill="white"
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.30371 10.9999C1.30371 10.6153 1.61552 10.3035 2.00015 10.3035H12.0002C12.3848 10.3035 12.6966 10.6153 12.6966 10.9999C12.6966 11.3845 12.3848 11.6964 12.0002 11.6964H2.00015C1.61552 11.6964 1.30371 11.3845 1.30371 10.9999Z"
        fill="white"
      />
    </svg>
  );
};

export default DarkNotAnimatedModeIcon;
