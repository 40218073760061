import React from "react";

const CertificateCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_7794_14446)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.53861 4.53756C4.80048 4.27569 5.22505 4.27569 5.48692 4.53756L11.809 10.8596C12.0709 11.1215 12.0709 11.5461 11.809 11.8079C11.5471 12.0698 11.1225 12.0698 10.8607 11.8079L4.53861 5.48587C4.27674 5.22401 4.27674 4.79943 4.53861 4.53756Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.53861 11.8081C4.27674 11.5463 4.27674 11.1217 4.53861 10.8598L10.8607 4.53776C11.1225 4.27589 11.5471 4.27589 11.809 4.53776C12.0709 4.79963 12.0709 5.2242 11.809 5.48607L5.48692 11.8081C5.22505 12.07 4.80048 12.07 4.53861 11.8081Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7794_14446">
          <rect
            width="10.7289"
            height="10.7289"
            fill="white"
            transform="translate(0.586914 8.17285) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CertificateCloseIcon;
