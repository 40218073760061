import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import VerticalLine from "../../../../assets/icons/vertical_line";
import { LESSON_COLORS_THEME } from "../../../../utils/enums";

const FramedBibleVerseText = styled.div`
  min-height: 41px;
  padding: 12px 15px;
  border-radius: 15px;
  border: 1px solid;
  border-color: ${({ color }) => color};
  font-size: 14px;
  line-height: 20px;
  flex: 1;
  ${({ colorTheme }) => colorTheme === "DARK" && "color: #fff;"}

  .bold {
    font-weight: bold;
  }
`;

const FramedBibleVerseAddress = styled.div`
  position: absolute;
  bottom: 0;
  right: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 21px;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid;
  font-size: 14px;
  border-color: ${({ color }) => color};
  background: ${({ colorTheme }) =>
    colorTheme === "LIGHT" ? "#eaeaea" : "#232323"};
  color: ${({ colorTheme }) => colorTheme === "DARK" && "#fff"};

  & > p {
    padding: 2px 0;
    line-height: 100%;
    margin-top: 1px;
  }

  & > span {
    color: ${({ color }) => color};
    line-height: 100%;
    margin-top: 1px;
  }
`;

const VerticalLineStyled = styled(VerticalLine)`
  margin-top: 3px;
`;

const FramedBibleVerseContainer = styled.div`
  position: relative;
  padding-bottom: 11px;
  color: #1e1e1e;
  width: 100%;

  ${({ inComponent }) =>
    (inComponent === "preview_page" || inComponent === "user_lesson") &&
    `  &:hover {
    ${FramedBibleVerseText}, ${FramedBibleVerseAddress} {
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      transition: 0.2s;
      cursor: pointer;
    }
  }`}

  @media (max-width: 420px) {
    font-size: 12px;
  }
`;

const formatBoldTextAndNewLineForNumbers = (text) => {
  if (text) {
    const boldRegex = /\*\*(.*?)\*\*/g;
    let formattedText = text.replace(boldRegex, `<span class="bold">$1</span>`);

    const numberRegex = /(\d+\.?)/g;
    let firstMatch = true;
    formattedText = formattedText.replace(numberRegex, (match) => {
      if (firstMatch) {
        firstMatch = false;
        return match;
      }
      return "<br/>" + match;
    });

    return formattedText;
  } else return "";
};

const LessonBibleVerse = ({
  verse,
  color,
  inComponent,
  setTextBibleModalData,
  colorTheme,
}) => {
  const [verseAddress, setVerseAddress] = useState("");

  const formattedText = formatBoldTextAndNewLineForNumbers(verse.verseText);
  const sanitizedHtml = DOMPurify.sanitize(formattedText);

  const translationAbbrev = verse?.versesParams?.bibleId?.toUpperCase() || "";
  const borderColor =
    colorTheme === "DARK" && color === "WHITE"
      ? "rgba(255, 255, 255, 0.60)"
      : colorTheme === "DARK" && color === "BLACK"
      ? "rgba(255, 255, 255, 0.10)"
      : LESSON_COLORS_THEME[colorTheme]?.COLORS?.[color];

  useEffect(() => {
    if (verse.verseAddress) {
      setVerseAddress(verse.verseAddress);
    }
  }, [verse]);

  const onClickBibleVerses = useCallback(
    (event) => {
      if (inComponent === "preview_page" || inComponent === "user_lesson") {
        event.stopPropagation();

        const { bibleId, bookId, chapterId } = verse.versesParams;
        const selectedVerses = verse.versesData
          ?.map((verse) => verse.verse_num)
          .join(",");

        setTextBibleModalData({ bibleId, bookId, chapterId, selectedVerses });
      }
    },
    [verse, inComponent]
  );

  return (
    <FramedBibleVerseContainer
      inComponent={inComponent}
      onClick={onClickBibleVerses}
    >
      <FramedBibleVerseText
        color={borderColor}
        colorTheme={colorTheme}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />

      <FramedBibleVerseAddress color={borderColor} colorTheme={colorTheme}>
        <span>{translationAbbrev}</span>
        <VerticalLineStyled height={"12px"} color={borderColor} />
        <p>{verseAddress}</p>
      </FramedBibleVerseAddress>
    </FramedBibleVerseContainer>
  );
};

export default memo(LessonBibleVerse);
