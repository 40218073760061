import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import SelectedCheckboxLarge from "./selected_checkbox_large";
import UnselectedCheckboxLarge from "./unselected_checkbox_large";
import SelectedCheckboxSmall from "./selected_checkbox_small";
import UnselectedCheckboxSmall from "./unselected_checkbox_small";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ color }) => color || "#1e1e1e"};
  font-size: ${({ fontSize }) => fontSize || "12px"};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || "normal"};
  line-height: 120%;
  user-select: none;
`;

const CheckboxIconsContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    `
  cursor: not-allowed;
  `}
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
`;

const Checkbox = ({
  onClickCheckbox,
  children,
  checked = false,
  color,
  fontSize,
  letterSpacing,
  component,
  disabled,
}) => {
  const handleCheckbox = useCallback(
    (event) => {
      if (!disabled) {
        onClickCheckbox(event);
      }
    },
    [disabled, onClickCheckbox]
  );

  return (
    <CheckboxContainer
      color={color}
      fontSize={fontSize}
      letterSpacing={letterSpacing}
    >
      <CheckboxIconsContainer disabled={disabled} onClick={handleCheckbox}>
        <HiddenCheckbox checked={checked} readOnly aria-hidden="true" />
        {checked ? (
          component === "bible_search" || component === "bible_modal_search" ? (
            <SelectedCheckboxSmall alt="Checked" />
          ) : (
            <SelectedCheckboxLarge alt="Checked" />
          )
        ) : component === "bible_search" ||
          component === "bible_modal_search" ? (
          <UnselectedCheckboxSmall alt="Unchecked" />
        ) : (
          <UnselectedCheckboxLarge alt="Unchecked" />
        )}
      </CheckboxIconsContainer>
      {children}
    </CheckboxContainer>
  );
};

export default memo(Checkbox);
