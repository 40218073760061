import React, { memo } from "react";
import styled from "styled-components";
import certificateExampleImage from "../../../common/assets/images/course_certificate_images/course_certificate_example.png";

const CourseCertificateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 60px auto;
  max-width: 740px;

  & > h5 {
    color: #1e1e1e;
    text-align: center;
    font-size: 42px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.8px;

    @media (max-width: 670px) {
      font-size: 36px;
    }

    @media (max-width: 513px) {
      font-size: 30px;
    }

    @media (max-width: 412px) {
      font-size: 24px;
    }
  }
`;

const CertificateContainer = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 770 / 539;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const CourseCertificate = () => {
  return (
    <CourseCertificateContainer>
      <h5>Получи сертификат после прохождения курса</h5>
      <CertificateContainer image={certificateExampleImage} />
    </CourseCertificateContainer>
  );
};

export default memo(CourseCertificate);
