import React, { useState } from "react";
import styled from "styled-components";
import {
  HidePasswordIcon,
  ShowPasswordIcon,
} from "../../../common/assets/icons";
import AuthTextInput from "./auth_text_input";

const AuthPasswordInputContainer = styled.div`
  position: relative;
`;

const PasswordVisibleToggler = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.2s;
`;

const AuthPasswordInput = ({ leftHandMessage, ...props }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisible = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <AuthPasswordInputContainer>
      <AuthTextInput
        type={isPasswordVisible ? "text" : "password"}
        isPassword={true}
        leftHandMessage={leftHandMessage}
        {...props}
      />
      <PasswordVisibleToggler onClick={togglePasswordVisible}>
        {!isPasswordVisible ? <HidePasswordIcon /> : <ShowPasswordIcon />}
      </PasswordVisibleToggler>
    </AuthPasswordInputContainer>
  );
};

export default AuthPasswordInput;
