import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import {
  getInlineStyles,
  replacePlaceholderWithStudentName,
  replaceSpacesWithNbsp,
  entityStyleFn,
} from "../../text_editor/text_editor_functions";
import LeftArrowIcon from "../../../assets/icons/left_arrow_icon";
import { useUserDataContext } from "../../../../user_data_context";
import { LESSON_COLORS_THEME } from "../../../utils/enums";
import { TextEditorViewComponent } from "../../text_editor/editor_styled_components";
import { insertUserNameInText } from "../../../utils/functions/admin_page_functions";

const NoteComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: max-content;
  border-radius: 25px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 10px 18px;
  min-height: 40px;
  min-width: 76%;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 15px;
  background: #f8d254;
  cursor: pointer;

  ${({ inComponent }) =>
    inComponent !== "create_lesson" &&
    `
    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      transition: 0.2s;
    }
  `}
`;

const QuestionText = styled.div`
  max-width: 93%;
  letter-spacing: 0.16px;
  line-height: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 100%;
  padding-right: 2px;
  transform: rotate(${({ openNote }) => (openNote ? "90deg" : "-90deg")});
`;

const ArrowStyled = styled(LeftArrowIcon)`
  width: 17px;
  height: 17px;
`;

const NoteContainer = styled.div`
  width: 100%;
  min-height: 52px;
  padding: 12px 24px;
  border-radius: 15px;
  border: 2px solid #f8d254;
  background: ${({ noteBackground }) => noteBackground};
  line-height: 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: ${({ color }) => color};
`;

const NoteLessonComponent = ({
  componentData,
  setTextEditorComponentHtml = () => {},
  colorTheme = "LIGHT",
  inComponent = "",
  courseMainColor,
  courseMainGradient,
}) => {
  const { userData } = useUserDataContext();

  const [html, setHtml] = useState("");
  const [openNote, setOpenNote] = useState(false);

  const sanitizedHtml = DOMPurify.sanitize(html);

  const currentUserName = userData.user_name || "Пользователь";

  const noteBackground = LESSON_COLORS_THEME[colorTheme]?.GRADIENTS?.["WHITE"];

  useEffect(() => {
    if (inComponent === "create_lesson") {
      setOpenNote(true);
    } else if (componentData.with_question) {
      setOpenNote(false);
    }
  }, [inComponent, componentData]);

  const handleOpenNote = useCallback(() => {
    if (inComponent !== "create_lesson") {
      setOpenNote((prevState) => !prevState);
    }
  }, [inComponent]);

  useEffect(() => {
    if (componentData.note?.blocks?.length) {
      try {
        let note = convertFromRaw(componentData.note);

        let htmlContent = stateToHTML(note, {
          entityStyleFn,
          inlineStyles: getInlineStyles(colorTheme),
        });

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );
        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert note from raw:", error);
      }
    }
  }, [componentData.note, currentUserName, colorTheme]);

  useEffect(() => {
    setTextEditorComponentHtml({});
  }, [html]);

  return (
    <NoteComponentContainer>
      {typeof componentData.note === "string" && (
        <div
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          style={{ border: "2px solid red", padding: "10px 20px" }}
        >
          {componentData.note}
        </div>
      )}

      {componentData.with_question && (
        <QuestionContainer inComponent={inComponent} onClick={handleOpenNote}>
          <QuestionText>
            {insertUserNameInText(componentData.question, currentUserName)}
          </QuestionText>

          <ArrowContainer openNote={openNote}>
            <ArrowStyled color={"#383838"} />
          </ArrowContainer>
        </QuestionContainer>
      )}

      {(!componentData.with_question || openNote) && html && (
        <NoteContainer
          noteBackground={noteBackground}
          color={colorTheme === "LIGHT" ? "#1e1e1e" : "#fff"}
        >
          <TextEditorViewComponent
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            courseMainColor={courseMainColor}
            courseMainGradient={courseMainGradient}
          />
        </NoteContainer>
      )}
    </NoteComponentContainer>
  );
};

export default memo(NoteLessonComponent);
