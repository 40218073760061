import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { APPLICATION_ROUTES } from "../utils/routes";

export function useAuthRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToAuth = useCallback(
    (redirectUrl) => {
      const redirectPath = redirectUrl
        ? encodeURIComponent(redirectUrl)
        : encodeURIComponent(location.pathname + location.search);
      navigate(`${APPLICATION_ROUTES.AUTH}?redirect=${redirectPath}`, {
        replace: true,
      });
    },
    [location.pathname, location.search, navigate]
  );

  return redirectToAuth;
}
