import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import Checkbox from "../../../../../common/components/checkbox";
import AskQuestionArrowIcon from "../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import Toggle from "../../../../../common/components/toggle";
import { useUserDataContext } from "../../../../../user_data_context";
import {
  AdminPageSettingsHeader,
  AdminPageSettingsHeaderArrow,
} from "../../../admin_page_components/admin_page_styled_components";

const PlatformAdminExtendedSettings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  padding: 10px;

  & > h5 {
    color: #828282;
    font-size: 10px;
    line-height: 10px;
  }
`;

const PlatformAdmin = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  setUsersList,
}) => {
  const { userData } = useUserDataContext();
  const [extendedUserIsPlatformAdmin, setExtendedUserIsPlatformAdmin] =
    useState(false);

  const isSuperAdmin = userData.user_role === "superadmin";

  const userIsPlatformAdmin =
    userRolesSettingsData.user_access.is_admin_of_platform;
  const usersAccess = userRolesSettingsData.user_access.admin_of_platform.users;
  const coursesAccess =
    userRolesSettingsData.user_access.admin_of_platform.courses;
  const groupsAccess =
    userRolesSettingsData.user_access.admin_of_platform.groups;
  const questionsAccess =
    userRolesSettingsData.user_access.admin_of_platform.questions;
  const analyticsAccess =
    userRolesSettingsData.user_access.admin_of_platform.analytics;
  const homePageAccess =
    userRolesSettingsData.user_access.admin_of_platform.homepage;

  useEffect(() => {
    if (!userIsPlatformAdmin) {
      setExtendedUserIsPlatformAdmin(false);
    }
  }, [userIsPlatformAdmin]);

  const handleUserIsPlatformAdmin = useCallback(
    (event) => {
      event.stopPropagation();
      if (isSuperAdmin) {
        setUserRolesSettingsData((prevState) => ({
          ...prevState,
          user_access: {
            ...prevState.user_access,
            is_admin_of_platform: !prevState.user_access?.is_admin_of_platform,
          },
        }));

        setUsersList((prevState) =>
          prevState.map((user) =>
            user._id === userRolesSettingsData._id || user._id === "new_invite"
              ? {
                  ...user,
                  user_access: {
                    ...user.user_access,
                    is_admin_of_platform:
                      !user.user_access?.is_admin_of_platform,
                  },
                }
              : user
          )
        );
      }
    },
    [userRolesSettingsData, isSuperAdmin]
  );

  const handleSelectToggle = useCallback((event, option) => {
    event.stopPropagation();
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_access: {
        ...prevState.user_access,
        admin_of_platform: {
          ...prevState.user_access.admin_of_platform,
          [option]: !prevState.user_access.admin_of_platform[option],
        },
      },
    }));
  }, []);

  const handleExtendUserIsPlatformAdmin = useCallback(() => {
    if (userIsPlatformAdmin && isSuperAdmin) {
      setExtendedUserIsPlatformAdmin((prevState) => !prevState);
    }
  }, [userIsPlatformAdmin, isSuperAdmin]);

  return (
    <div>
      <AdminPageSettingsHeader
        clickable={userIsPlatformAdmin && isSuperAdmin}
        onClick={handleExtendUserIsPlatformAdmin}
      >
        <Checkbox
          checked={userIsPlatformAdmin}
          onClickCheckbox={handleUserIsPlatformAdmin}
          disabled={!isSuperAdmin}
          color={"#1e1e1e"}
          fontSize={"14px"}
        >
          Админ платформы
        </Checkbox>

        {userIsPlatformAdmin && isSuperAdmin && (
          <AdminPageSettingsHeaderArrow
            extendedSection={extendedUserIsPlatformAdmin}
          >
            <AskQuestionArrowIcon />
          </AdminPageSettingsHeaderArrow>
        )}
      </AdminPageSettingsHeader>

      {extendedUserIsPlatformAdmin && (
        <PlatformAdminExtendedSettings>
          <h5>Управление:</h5>
          <Toggle
            title={"Пользователи"}
            selected={usersAccess}
            handleSelectToggle={(e) => handleSelectToggle(e, "users")}
          />
          <Toggle
            title={"Курсы"}
            selected={coursesAccess}
            handleSelectToggle={(e) => handleSelectToggle(e, "courses")}
          />
          <Toggle
            title={"Группы"}
            selected={groupsAccess}
            handleSelectToggle={(e) => handleSelectToggle(e, "groups")}
          />
          <Toggle
            title={"Вопросы"}
            selected={questionsAccess}
            handleSelectToggle={(e) => handleSelectToggle(e, "questions")}
          />
          <Toggle
            title={"Аналитика"}
            selected={analyticsAccess}
            handleSelectToggle={(e) => handleSelectToggle(e, "analytics")}
          />
          <Toggle
            title={"Главная страница"}
            selected={homePageAccess}
            handleSelectToggle={(e) => handleSelectToggle(e, "homepage")}
          />
        </PlatformAdminExtendedSettings>
      )}
    </div>
  );
};

export default memo(PlatformAdmin);
