import React, { memo } from "react";
import styled from "styled-components";

const CreateLessonSaveButtonContainer = styled.button`
  display: flex;
  min-height: 45px;
  max-height: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  ${({ disabled }) => disabled && "opacity: 0.4;"}
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: 0.2s;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const CreateLessonSaveButton = ({
  settingsBlockData,
  onClickSaveButton,
  disabled,
}) => {
  return (
    <>
      {settingsBlockData?.type && (
        <CreateLessonSaveButtonContainer
          onClick={onClickSaveButton}
          disabled={disabled}
        >
          Сохранить
        </CreateLessonSaveButtonContainer>
      )}
    </>
  );
};

export default memo(CreateLessonSaveButton);
