import React, { createContext, useContext, useState } from "react";

const BiblePageContext = createContext();

export const useBiblePageState = () => {
  const [currentTranslationData, setCurrentTranslationData] = useState([]);

  return {
    currentTranslationData,
    setCurrentTranslationData,
  };
};

export const BiblePageProvider = ({ children }) => {
  const { currentTranslationData, setCurrentTranslationData } =
    useBiblePageState();

  return (
    <BiblePageContext.Provider
      value={{
        currentTranslationData,
        setCurrentTranslationData,
      }}
    >
      {children}
    </BiblePageContext.Provider>
  );
};

export const useBiblePageContext = () => {
  return useContext(BiblePageContext);
};
