import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CreateLessonAddNewBlock from "./create_lesson_add_new_block";
import { compareObjects } from "../../../../common/utils/functions/compare_objects";
import CreateLessonPreviewComponent from "./create_lesson_preview_component";
import CreateLessonSkeletComponent from "./create_lesson_skelet_component";
import BibleChapterModalWindow from "../../../../common/components/modal_windows/bible_chapter_modal_window";
import { handleBibleVerseClick } from "../../../../common/components/text_editor/text_editor_functions";

const CreateLessonPreviewWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const CreateLessonPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 630px;
  padding-bottom: 80px;
  margin: 0 auto;
`;

const CreateLessonPreview = ({
  lessonData,
  activeSettingsBlock,
  settingsBlockData,
  settingsBlockMemoData,
  editBlockIndex,
  insertBlockIndex,
  setSettingsBlockData,
  setSettingsBlockMemoData,
  setActiveSettingsBlock,
  setInsertBlockIndex,
  setOpenEditeComponentModal,
  setEditBlockIndex,
  resetAllActiveBlocks,
  fetchLessonData,
  courseMainColor,
  courseMainGradient,
}) => {
  const previewContainerRef = useRef(null);
  const skeletonRef = useRef(null);

  const [lessonPreviewArray, setLessonPreviewArray] = useState([]);
  const [activeAddNewBlockIndex, setActiveAddNewBlockIndex] = useState(null);
  const [textEditorComponentHtml, setTextEditorComponentHtml] = useState(null);
  const [textBibleModalData, setTextBibleModalData] = useState({});

  useEffect(() => {
    if (skeletonRef?.current && activeSettingsBlock && settingsBlockData.type) {
      skeletonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [skeletonRef, activeSettingsBlock, settingsBlockData.type]);

  const onClickPreviewBlock = useCallback(
    (event, previewInfo, currentIndexInLessonArray) => {
      event.stopPropagation();

      if (compareObjects(settingsBlockMemoData, settingsBlockData)) {
        if (editBlockIndex !== null) {
          if (currentIndexInLessonArray === editBlockIndex) {
            resetAllActiveBlocks();
          } else {
            setSettingsBlockData(previewInfo);
            setSettingsBlockMemoData(previewInfo);
            setEditBlockIndex(currentIndexInLessonArray);
            setInsertBlockIndex(null);
            setActiveSettingsBlock(previewInfo.type);
          }
        } else {
          setSettingsBlockData(previewInfo);
          setSettingsBlockMemoData(previewInfo);
          setEditBlockIndex(currentIndexInLessonArray);
          setInsertBlockIndex(null);
          setActiveSettingsBlock(previewInfo.type);
        }
      } else {
        setOpenEditeComponentModal(true);
      }
    },
    [editBlockIndex, settingsBlockData, settingsBlockMemoData]
  );

  const insertPlusButton = useCallback((lesson) => {
    const PLUS_BUTTON = { type: "plus-button" };
    const result = [PLUS_BUTTON];

    lesson.forEach((current, index) => {
      result.push(current);

      if (
        index < lesson.length - 1 &&
        lesson[index + 1].type !== "plus-button"
      ) {
        result.push(PLUS_BUTTON);
      }
    });

    if (lesson[lesson.length - 1].type !== "plus-button") {
      result.push(PLUS_BUTTON);
    }

    return result;
  }, []);

  useEffect(() => {
    if (lessonData.lesson_data?.length) {
      setLessonPreviewArray(insertPlusButton(lessonData.lesson_data));
    } else {
      setLessonPreviewArray([]);
    }
  }, [lessonData]);

  useEffect(() => {
    const container = previewContainerRef.current;
    const bibleVerseElements = container?.querySelectorAll(".bible-verse");
    const handleClick = (event) =>
      handleBibleVerseClick(event, setTextBibleModalData);

    bibleVerseElements?.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      bibleVerseElements?.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [textEditorComponentHtml]);

  return (
    <CreateLessonPreviewWrapper>
      <CreateLessonPreviewContainer ref={previewContainerRef}>
        {lessonPreviewArray.map((preview, index) => {
          if (preview.type !== "plus-button") {
            return (
              <CreateLessonPreviewComponent
                key={index + preview.type}
                previewInfo={preview}
                lessonData={lessonData}
                editBlockIndex={editBlockIndex}
                settingsBlockData={settingsBlockData}
                setEditBlockIndex={setEditBlockIndex}
                setTextEditorComponentHtml={setTextEditorComponentHtml}
                resetAllActiveBlocks={resetAllActiveBlocks}
                fetchLessonData={fetchLessonData}
                onClickPreviewBlock={onClickPreviewBlock}
                courseMainColor={courseMainColor}
                courseMainGradient={courseMainGradient}
              />
            );
          } else
            return (
              <CreateLessonAddNewBlock
                key={index + preview.type}
                index={index}
                lessonPreviewArray={lessonPreviewArray}
                lessonData={lessonData}
                settingsBlockData={settingsBlockData}
                settingsBlockMemoData={settingsBlockMemoData}
                activeSettingsBlock={activeSettingsBlock}
                insertBlockIndex={insertBlockIndex}
                activeAddNewBlockIndex={activeAddNewBlockIndex}
                setActiveSettingsBlock={setActiveSettingsBlock}
                setInsertBlockIndex={setInsertBlockIndex}
                setActiveAddNewBlockIndex={setActiveAddNewBlockIndex}
                setOpenEditeComponentModal={setOpenEditeComponentModal}
                setEditBlockIndex={setEditBlockIndex}
                setSettingsBlockMemoData={setSettingsBlockMemoData}
                setSettingsBlockData={setSettingsBlockData}
                courseMainColor={courseMainColor}
                courseMainGradient={courseMainGradient}
              />
            );
        })}

        {insertBlockIndex === null &&
          editBlockIndex === null &&
          activeSettingsBlock && (
            <div ref={skeletonRef}>
              {settingsBlockData.type && (
                <CreateLessonSkeletComponent
                  firstElement={!lessonData.lesson_data?.length}
                  lastElement={lessonData.lesson_data?.length}
                  settingsBlockData={settingsBlockData}
                  courseMainColor={courseMainColor}
                  courseMainGradient={courseMainGradient}
                />
              )}
            </div>
          )}
      </CreateLessonPreviewContainer>

      {textBibleModalData.bibleId && (
        <BibleChapterModalWindow
          bibleModalData={textBibleModalData}
          setOpen={setTextBibleModalData}
        />
      )}
    </CreateLessonPreviewWrapper>
  );
};

export default memo(CreateLessonPreview);
