import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import AdminPageInfoIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_info_icon";
import { compareObjects } from "../../../../common/utils/functions/compare_objects";
import UserImage from "../../../../common/components/user_image";

const UserPreviewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 55px;
  padding: 7px 11px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  opacity: ${({ unselected }) => (unselected ? "0.5" : "1")};

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const UserPreviewInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserNameAndEmailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-right: 30px;
`;

const UserName = styled.div`
  color: #1e1e1e;
  font-size: 15px;
  font-weight: 600;
  line-height: 135%;
`;

const UserEmail = styled.div`
  color: #828282;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  height: content-fit;
`;

const UserRoles = styled.div`
  color: #828282;
  font-size: 14px;
  line-height: 135%;
  user-select: none;
`;

const UserInfoButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  ${({ unselected }) =>
    !unselected &&
    `
   &:hover {
      transition: 0.2s;
      transform: scale(1.12);
   }
  `}
`;

const UserPreview = ({
  userInfo,
  userRolesSettingsData,
  userRolesSettingsMemoData,
  setActiveSettingsBlock,
  setUserRolesSettingsData,
  setUserRolesSettingsMemoData,
  setSelectedUserInfo,
  setOpenEditUserModal,
}) => {
  const [userRoles, setUserRoles] = useState([]);

  const userName =
    userInfo._id !== "new_invite"
      ? userInfo.user_name
        ? `${userInfo.user_name} ${userInfo.user_second_name}`
        : userInfo.user_email
      : "Новое приглашение";

  const userEmail =
    userInfo._id !== "new_invite" && userInfo.user_type === "invite"
      ? "Приглашение отправлено"
      : userInfo.user_email;

  const unselected =
    userRolesSettingsData._id && userRolesSettingsData._id !== userInfo._id;

  const isSuperAdmin = userInfo.user_role === "superadmin";
  const isAdminOfPlatform = userInfo.user_access?.is_admin_of_platform;
  const isAdminOfCourses = userInfo.user_access?.is_admin_of_courses;
  const isAdminOfGroups = userInfo.user_access?.is_admin_of_groups;
  const isAuthorOfCourses = userInfo.user_access?.is_author;
  const isLeader = userInfo.user_access?.is_leader;
  const isMentor = userInfo.user_access?.is_mentor;
  const isOperatorOfSupport = userInfo.user_access?.is_operator_of_support;

  useEffect(() => {
    const roles = [];
    isSuperAdmin && roles.push("Супер Админ");
    isAdminOfPlatform && roles.push("Админ платформы");
    isAdminOfCourses && roles.push("Админ курсов");
    isAdminOfGroups && roles.push("Админ групп");
    isAuthorOfCourses && roles.push("Автор");
    isLeader && roles.push("Лидер");
    isMentor && roles.push("Наставник");
    isOperatorOfSupport && roles.push("Оператор поддержки");

    setUserRoles(roles);
  }, [userInfo]);

  const onClickUserPreview = useCallback(
    (event) => {
      event.stopPropagation();

      if (compareObjects(userRolesSettingsData, userRolesSettingsMemoData)) {
        if (userInfo._id === userRolesSettingsData._id) {
          setUserRolesSettingsData({});
          setUserRolesSettingsMemoData({});
          setActiveSettingsBlock("");
          setOpenEditUserModal(false);
        } else {
          setActiveSettingsBlock("user_roles");
          setUserRolesSettingsData(userInfo);
          setUserRolesSettingsMemoData(userInfo);
        }
      } else {
        setOpenEditUserModal(true);
      }
    },
    [userInfo, userRolesSettingsData, userRolesSettingsMemoData]
  );

  const openUserInfo = useCallback(
    (event) => {
      event.stopPropagation();
      setSelectedUserInfo({ data: userInfo });
    },
    [userInfo]
  );

  return (
    <UserPreviewContainer
      unselected={unselected}
      onClick={onClickUserPreview}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <UserImage userData={userInfo} size={"40px"} />

      <UserPreviewInfoContainer>
        <UserNameAndEmailContainer>
          <UserName>{userName}</UserName>
          {userInfo.user_email && <UserEmail>{userEmail}</UserEmail>}
        </UserNameAndEmailContainer>
        <UserRoles>{userRoles.join(", ")}</UserRoles>
      </UserPreviewInfoContainer>

      {userInfo._id !== "new_invite" && (
        <UserInfoButton unselected={unselected} onClick={openUserInfo}>
          <AdminPageInfoIcon />
        </UserInfoButton>
      )}
    </UserPreviewContainer>
  );
};

export default memo(UserPreview);
