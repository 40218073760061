import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import ChatsList from "./chats_page_components/chats_list";
import ChatContent from "./chats_page_components/chat_content";
import avatarExample from "../../common/assets/images/avatar_example.png";
import { MESSAGE_TYPE } from "./chats_page_components/chat_content/chat_messages/";
import { CHATS, MESSAGES } from "./fake_chats_data";
import {
  ErrorIcon,
  ReadIcon,
  SendIcon,
} from "../../common/assets/icons/chat_page_icons/your_message_status_icons";
import StartBlockOfChats from "./chats_page_components/start_block_of_chats";
import { useAppContext } from "../../app_context";
import { useUserDataContext } from "../../user_data_context";
import { useAuthRedirect } from "../../common/hooks/use_auth_redirect";

export const YOUR_MESSAGE_STATUS = {
  SENT: "SENT",
  READ: "READ",
  ERROR: "ERROR",
};

export const YOUR_MESSAGE_STATUS_RENDERERS = {
  SENT: SendIcon,
  READ: ReadIcon,
  ERROR: ErrorIcon,
};

const ChatsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ChatsBody = styled.div`
  display: flex;
  height: 100%;
  width: 1280px;
  margin: 0 auto;
  background: #eaeaea;
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;

  @media (max-width: 1280px) {
    width: 100%;
    border: none;
  }
`;

const ChatsPage = () => {
  const { selectedChatId, setSelectedChatId } = useAppContext();
  const { authError } = useUserDataContext();
  const redirectToAuth = useAuthRedirect();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showChatMessages, setShowChatMessages] = useState(true);
  const [showChatsList, setShowChatsList] = useState(true);

  useEffect(() => {
    if (authError) {
      redirectToAuth();
    }
  }, [authError]);

  const handleResize = () => {
    setWindowWidth(document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedChatId && windowWidth >= 744) {
      setShowChatsList(true);
      setShowChatMessages(true);
    }

    if (windowWidth <= 744) {
      if (selectedChatId) {
        setShowChatsList(false);
        setShowChatMessages(true);
      } else {
        setShowChatsList(true);
        setShowChatMessages(false);
      }
    }
  }, [windowWidth, selectedChatId]);

  const chatMessages = MESSAGES.filter(
    (message) => message.chatId === selectedChatId
  );

  const selectedChatInfo = CHATS.find((chat) => chat.id === selectedChatId);

  useEffect(() => {
    //!                                     Get FAKE CHATS arrays
    for (let i = 0; i < 10; i++) {
      MESSAGES.push({
        authorName: "Имя Автора",
        text: `${
          i + 1
        }. Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condim ennn tum scelerisque`,
        messageType: MESSAGE_TYPE.USER,
        avatarUrl: avatarExample,
        createdAt: 1696421312004 + i,
        yourMessageStatus: null,
        replyInMessage: null,
        biblicalVerses: [],
        emoji: [],
        id: 120 + i,
        chatId: 2,
      });
    }
    for (let i = 0; i < 5; i++) {
      MESSAGES.push({
        authorName: "Имя Автора",
        text: `${
          i + 1
        }. Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimentum. Lorem ipsum dolor sit amet consectetur.`,
        messageType: MESSAGE_TYPE.USER,
        avatarUrl: avatarExample,
        createdAt: 1696421312004 + i,
        yourMessageStatus: null,
        replyInMessage: null,
        biblicalVerses: [],
        emoji: [],
        id: 110 + i,
        chatId: 3,
      });
    }
  }, []);

  if (authError) return;

  return (
    <ChatsPageContainer>
      <ChatsBody>
        {showChatsList && (
          <ChatsList
            chats={CHATS}
            selectedChatId={selectedChatId}
            onChatClick={setSelectedChatId}
          />
        )}

        {selectedChatId && showChatMessages ? (
          <ChatContent
            messages={chatMessages}
            selectedChatId={selectedChatId}
            setSelectedChatId={setSelectedChatId}
            chatInfo={selectedChatInfo}
            windowWidth={windowWidth}
          />
        ) : (
          windowWidth > 744 && <StartBlockOfChats />
        )}
      </ChatsBody>
    </ChatsPageContainer>
  );
};

export default memo(ChatsPage);
