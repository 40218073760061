import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { SearchIcon } from "../../../../common/assets/icons";
import RemoveIcon from "../../../../common/assets/icons/remove_icon";

const currentDate = new Date().toISOString();

const INVITE_DEFAULT_DATA = {
  user_created_at: currentDate,
  user_name: "",
  user_second_name: "",
  user_email: "",
  user_password: "",
  user_phone: "",
  user_country: "",
  user_city: "",
  user_religion: "",
  user_image: "",
  user_mentor: {},
  user_notes: "",
  user_type: "invite",
  user_lang: "",
  user_permissions: {
    write_to_mentor: {
      disabled_days: "",
      disabled_start_date: "",
      isEnabled: true,
    },
    write_to_chat: {
      disabled_days: "",
      disabled_start_date: "",
      isEnabled: true,
    },
    write_to_support: {
      disabled_days: "",
      disabled_start_date: "",
      isEnabled: true,
    },
    amount_of_warnings: 0,
    isBanned: false,
  },
  user_access: {
    is_mentor: false,
    is_leader: false,
    is_admin_of_platform: false,
    is_admin_of_courses: false,
    is_admin_of_groups: false,
    is_operator_of_support: false,
    is_author: false,
    mentor_role: {
      max_disciples_amount: 10,
      user_disciples: [],
    },
    leader_role: {
      max_wards_amount: 50,
      user_wards: [],
      user_groups: [],
    },
    admin_of_platform: {
      users: true,
      courses: true,
      groups: true,
      questions: true,
      analytics: true,
      homepage: true,
    },
    admin_of_courses: [],
    admin_of_groups: [],
  },
  user_publicity: {
    author_info: "",
    courses: [],
    groups: [],
  },
  user_activity: [],
  user_actions: [],
};

const UsersListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

const CreateUserInviteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  min-height: 40px;
  max-height: 40px;
  padding: 0 15px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  color: #fff;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  user-select: none;

  ${({ disabled }) =>
    !disabled &&
    `
  &:hover {
   box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.15);
   transition: 0.2s;
 }
`}
`;

const UsersListSearchUsersInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 9px 0 12px;
  width: 230px;
  height: 40px;
  background: #fff;
  transition: 0.2s;

  :hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }

  & > input {
    width: 87%;
    font-size: 14px;
  }
`;

const UsersListSearchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 70%;
  background: #fff;
  padding: 0 4px;

  :hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`;

const UsersListHeader = ({
  activeSettingsBlock,
  setActiveSettingsBlock,
  setUserRolesSettingsData,
  setUserRolesSettingsMemoData,
  setSearchWords,
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [showedInputIcon, setShowedInputIcon] = useState("search_icon"); //search_icon", remove_icon

  const handleSearchInputValue = useCallback((event) => {
    setSearchInputValue(event.target.value);
  }, []);

  const onCreateInviteButton = useCallback(
    (event) => {
      event.stopPropagation();
      if (!activeSettingsBlock) {
        setActiveSettingsBlock("create_invite");
        setUserRolesSettingsData(INVITE_DEFAULT_DATA);
        setUserRolesSettingsMemoData(INVITE_DEFAULT_DATA);
      } else {
        setActiveSettingsBlock("");
        setUserRolesSettingsData({});
        setUserRolesSettingsMemoData({});
      }
    },
    [activeSettingsBlock]
  );

  const filterUsersBySearch = useCallback(() => {
    if (searchInputValue) {
      setSearchWords(searchInputValue);
      setShowedInputIcon("remove_icon");
    }
  }, [searchInputValue]);

  const clearSearchInput = useCallback(() => {
    setSearchWords("");
    setSearchInputValue("");
    setShowedInputIcon("search_icon");
  }, [searchInputValue]);

  useEffect(() => {
    setShowedInputIcon("search_icon");
  }, [searchInputValue]);

  useEffect(() => {
    if (!searchInputValue) {
      setSearchWords("");
    }
  }, [searchInputValue]);

  return (
    <UsersListHeaderContainer>
      <CreateUserInviteButton
        disabled={activeSettingsBlock === "user_roles"}
        onClick={onCreateInviteButton}
        onMouseDown={(e) => e.stopPropagation()}
      >
        Создать приглашение
      </CreateUserInviteButton>

      <UsersListSearchUsersInput onMouseDown={(e) => e.stopPropagation()}>
        <input
          placeholder="Поиск пользователей..."
          value={searchInputValue}
          onChange={handleSearchInputValue}
          onKeyDown={(event) => event.key === "Enter" && filterUsersBySearch()}
        />
        {showedInputIcon === "search_icon" ? (
          <UsersListSearchButton onClick={filterUsersBySearch}>
            <SearchIcon />
          </UsersListSearchButton>
        ) : (
          <UsersListSearchButton onClick={clearSearchInput}>
            <RemoveIcon color="#383838" />
          </UsersListSearchButton>
        )}
      </UsersListSearchUsersInput>
    </UsersListHeaderContainer>
  );
};

export default memo(UsersListHeader);
