import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  BIBLE_BOOK_NAMES_DATA,
  USER_LANGUAGE,
} from "../../pages/bible/bible_page_data";
import {
  bibleVersesWithoutTags,
  highlightWords,
} from "../utils/functions/bible_page_functions";

const FramedBibleVerseContainer = styled.div`
  position: relative;
  padding-bottom: 11px;
  color: #1e1e1e;
  width: 100%;
  ${({ inComponent }) => inComponent === "chat_input" && "width: 95%;"}

  @media (max-width: 420px) {
    font-size: 12px;
  }
`;

const FramedBibleVerseText = styled.div`
  width: 100%;
  min-height: 41px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #5172ea;
  font-size: ${({ fontSize }) => fontSize || "14px"};
  line-height: ${({ lineHeight }) => lineHeight || "20px"};
  flex: 1;
  ${({ isSelected }) => isSelected && "background: #cacaca"};
  color: ${({ color }) => color || "1e1e1e"};

  ${({ inComponent, isSelected }) =>
    inComponent === "bible_search" &&
    `
    &:hover {
     cursor: pointer;
     background:
       ${isSelected ? "#cacaca" : "rgb(223 223 223)"};
     transition: 0.2s;
   }
`};

  ${({ inComponent }) =>
    inComponent === "chat_input" &&
    `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
   `}
`;

const FramedBibleVerseAddress = styled.div`
  position: absolute;
  bottom: 0;
  right: 18px;
  min-height: 19px;
  min-width: 70px;
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #5172ea;
  font-size: 14px;
  line-height: 100%;
  color: ${({ color }) => color || "1e1e1e"};
  background: ${({ backgroundColor }) => backgroundColor || "#fff"};
  ${({ inComponent }) =>
    (inComponent === "bible_compare" ||
      inComponent === "bible_search" ||
      inComponent === "bible_modal_search") &&
    `
  cursor: pointer;

  &: hover {
   box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
   transition: 0.2s;
 }
  `};
`;

const FramedBibleVerse = ({
  inComponent,
  verse,
  background,
  color,
  bibleSearchWord,
  fontSize,
  lineHeight,
  isSelected,
  currentTranslation,
  onBibleCompareAddress,
  onBibleSearchAddress,
  onBibleSearchVerse,
}) => {
  const [verseText, setVerseText] = useState("");
  const [verseAddress, setVerseAddress] = useState("");

  useEffect(() => {
    const getVerseAddress = () => {
      if (inComponent === "bible_compare") {
        return verse?.verses?.verseAddress || "";
      }

      if (inComponent === "home_page" || inComponent === "chat_input") {
        return verse.verseAddress;
      }

      const bookName =
        BIBLE_BOOK_NAMES_DATA[
          inComponent === "chat_page" ||
          inComponent === "admin_page_home_preview"
            ? USER_LANGUAGE
            : currentTranslation?.bible_locale
        ]?.[verse.book_code]?.name;

      return (
        (bookName || verse.book_code_orig) +
        " " +
        verse.chapter_num +
        ":" +
        verse.verse_num
      );
    };

    setVerseAddress(getVerseAddress());
  }, [inComponent, verse, currentTranslation]);

  useEffect(() => {
    const textByComponent = {
      bible_compare: verse?.verses?.verseText
        ? bibleVersesWithoutTags(verse.verses.verseText)
        : "- Стих не найден -",
      "chats_page-messages": bibleVersesWithoutTags(`«${verse.verse_text}»`),
      admin_page_home_preview: bibleVersesWithoutTags(`«${verse.verse_text}»`),
      chat_input: verse.verseText,
      home_page: verse.verseText,
      default: bibleVersesWithoutTags(`«${verse.verse_text?.[0]}»`),
    };

    setVerseText(textByComponent[inComponent] || textByComponent.default);
  }, [inComponent, verse]);

  const onClickText = useCallback(
    (event) => {
      if (inComponent === "bible_search") {
        event.stopPropagation();
        onBibleSearchVerse(verse);
      }
    },
    [verse, inComponent]
  );

  const onClickAddress = useCallback(
    (event) => {
      event.stopPropagation();
      if (inComponent === "bible_compare" && verse?.bible_code) {
        onBibleCompareAddress(verse.bible_code);
      }
      if (
        inComponent === "bible_search" ||
        inComponent === "bible_modal_search"
      ) {
        onBibleSearchAddress(verse);
      }
    },
    [verse, inComponent]
  );

  return (
    <FramedBibleVerseContainer inComponent={inComponent}>
      {verseText && (
        <FramedBibleVerseText
          inComponent={inComponent}
          fontSize={fontSize}
          color={color}
          lineHeight={lineHeight}
          isSelected={isSelected}
          onClick={onClickText}
        >
          {inComponent === "bible_search" ||
          inComponent === "bible_modal_search"
            ? highlightWords(verseText, bibleSearchWord)
            : verseText}
        </FramedBibleVerseText>
      )}

      {verseAddress && (
        <FramedBibleVerseAddress
          backgroundColor={background}
          color={color}
          inComponent={inComponent}
          onClick={onClickAddress}
        >
          {verseAddress}
        </FramedBibleVerseAddress>
      )}
    </FramedBibleVerseContainer>
  );
};

export default memo(FramedBibleVerse);
