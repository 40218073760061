import React, { memo, useCallback } from "react";
import ColorSelect from "../../../../common/components/color_select";
import { LESSON_COLORS_THEME } from "../../../../common/utils/enums";
import styled from "styled-components";

const CourseMainColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > h3 {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
  }
`;

const CourseMainColor = ({ courseSettingsData, setCourseSettingsData }) => {
  const handleSelectCourseMainColor = useCallback((event, color) => {
    event.stopPropagation();
    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_main_color: color,
    }));
  }, []);

  return (
    <CourseMainColorContainer>
      <h3>Цвет курса</h3>
      <ColorSelect
        selectedColor={courseSettingsData.course_main_color}
        handleSelectColor={handleSelectCourseMainColor}
        colors={LESSON_COLORS_THEME["LIGHT"].GRADIENTS}
      />
    </CourseMainColorContainer>
  );
};

export default memo(CourseMainColor);
