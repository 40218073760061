import React from "react";

const ShowPasswordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4513 5.31503C13.9684 3.82434 11.9541 2.5 9.5 2.5C7.04594 2.5 5.03162 3.82434 3.54869 5.31503C2.81804 6.04951 2.25347 6.78874 1.87217 7.34476C1.83332 7.40142 1.79647 7.45602 1.76164 7.50837C1.79785 7.56211 1.83621 7.61825 1.8767 7.67655C2.26374 8.23389 2.83533 8.97472 3.57137 9.71077C5.06782 11.2072 7.08246 12.5286 9.5 12.5286C11.9175 12.5286 13.9322 11.2072 15.4286 9.71077C16.1647 8.97472 16.7363 8.23389 17.1233 7.67655C17.1638 7.61825 17.2021 7.56211 17.2384 7.50836C17.2035 7.45602 17.1667 7.40141 17.1278 7.34476C16.7465 6.78874 16.182 6.04951 15.4513 5.31503ZM0 7.51429C0 7.51429 3.52857 14.0286 9.5 14.0286C15.4714 14.0286 19 7.51429 19 7.51429C19 7.51429 15.5393 1 9.5 1C3.46071 1 0 7.51429 0 7.51429ZM9.43213 10.6354C11.1186 10.6354 12.4857 9.26827 12.4857 7.58183C12.4857 5.89539 11.1186 4.52826 9.43213 4.52826C7.74569 4.52826 6.37856 5.89539 6.37856 7.58183C6.37856 9.26827 7.74569 10.6354 9.43213 10.6354Z"
      fill="#725FED"
    />
  </svg>
);

export default ShowPasswordIcon;
