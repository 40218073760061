import React from "react";
import styled from "styled-components";

const PageContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1100px;

  @media (max-width: 1150px) {
    max-width: 950px;
  }

  @media (max-width: 1024px) {
    max-width: 884px;
  }

  @media (max-width: 920px) {
    max-width: 840px;
  }

  @media (max-width: 880px) {
    max-width: 760px;
  }

  @media (max-width: 800px) {
    max-width: 700px;
  }

  @media (max-width: 744px) {
    max-width: 644px;
  }

  @media (max-width: 670px) {
    max-width: 350px;
  }

  @media (max-width: 412px) {
    max-width: 325px;
  }
`;

const LayoutComponent = ({ children }) => {
  return <PageContentWrapper>{children}</PageContentWrapper>;
};

export default LayoutComponent;
